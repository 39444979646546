<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">Selection Limit</div>
    <div class="modal-body modal-padding">
      <p>
        You may select up to
        <span id="select-limit-p">{{ limit }}</span> records at once.
      </p>
    </div>
    <div class="modal-footer">
      <div style="text-align: right">
        <button
          class="modal-button-style"
          data-dismiss="modal"
          (click)="close()"
        >
          Okay
        </button>
      </div>
    </div>
  </div>
</div>
