<div class="modal-content error-modal-content" style="max-width: 320px">
  <div class="modal-body">
    <h4 class="head-modal" style="border-bottom: solid 1px #555">
      Password reset
    </h4>
    <p>A new password has been sent to:</p>
    <p class="email">{{ email }}</p>
  </div>
  <div class="button" style="text-align: right">
    <button
      (click)="close()"
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      style="margin-bottom: 10px"
    >
      OK
    </button>
  </div>
</div>
<div></div>
