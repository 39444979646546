<div class="modal-content error-modal-content" style="max-width: 420px">
  <div class="modal-body">
    <h4 class="head-modal" style="border-bottom: solid 1px #555">
      Password reset
    </h4>
    <p>
      Please enter the email address for your account below. You will be sent a
      reset password via email. Once logged in, you may change the reset
      password to one of your own choosing.
    </p>
    <form id="forgot-form-id" [formGroup]="requiredForm" (ngSubmit)="onSubmit()" oninvalid="true">
      <div class="form-group" style="width: 240px">
        <input
          id="user_email"
          type="email"
          required="required"
          formControlName="email"
          class="form-control login-mail"
          placeholder="E-Mail Address"
          name="user_email"
          style="margin-bottom: 0px"
        />
        <p style="color: green; margin-left: -4px !important; text-align: center;" *ngIf="success">
          {{successMsg}}
        </p>
        <p style="color: red; margin-left: -4px !important; text-align: center;" *ngIf="error">
          <img src="/assets/img/test-fail-icon.png" /> {{errorMsg}}
        </p>
      </div>
      <div class="buttons" style="text-align: right">
        <button id="forgot-submit" type="submit" class="btn gernal-btn">
          OK
        </button>
      </div>
    </form>
  </div>
</div>

<!-- <div class="content">
  <div class="moldal-body">
    <h4 class="head-modal" mat-dialog-title style="border-bottom: solid 1px #555;">Password reset</h4>
    <mat-dialog-content>
      <p>
        Please enter the email address for your account below. You will be sent
        a reset password via email. Once logged in, you may change the reset
        password to one of your own choosing.
      </p>
      <div class="center">
        <input
          id="user_email"
          [(ngModel)]="email"
          type="email"
          required="required"
          class="form-control login-mail"
          placeholder="E-Mail Address"
          name="user_email"
          style="margin-bottom: 0px"
        />
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button class="gernal-btn "
        mat-button
        [disabled]="!email"
        [mat-dialog-close]="{ email: email }"
      >
        Ok
      </button>
    </mat-dialog-actions>
  </div>
</div> -->
