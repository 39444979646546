import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MessageModalComponent } from './components/modals/message-modal/message-modal.component';
import { ResetPasswordModalComponent } from './components/modals/reset-password-modal/reset-password-modal.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { PaymentComponent } from './components/payment/payment.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FilterPipe } from './pipe/filter.pipe';
import { SanitizeHtmlPipe } from './pipe/sanitize-html.pipe';
import { ResetPasswordSuccessModalComponent } from './components/modals/reset-password-modal/reset-password-success-modal/reset-password-success-modal.component';
import { SubscribErrorModalComponent } from './components/modals/subscrib-error-modal/subscrib-error-modal.component';
import { CollaboratorNewAccountComponent } from './components/modals/collaborator-new-account/collaborator-new-account.component';
import { LiveShareModalComponent } from './components/modals/live-share-modal/live-share-modal.component';
import { LiveShareExpiredModalComponent } from './components/modals/live-share-expired-modal/live-share-expired-modal.component';
import { LiveShareExpiredModalWvComponent } from './components/modals/live-share-expired-modal-wv/live-share-expired-modal-wv.component';
import { LiveShareExpiredModalFdpComponent } from './components/modals/live-share-expired-modal-fdp/live-share-expired-modal-fdp.component';
import { LiveShareExpiredModalCustomComponent } from './components/modals/live-share-expired-modal-custom/live-share-expired-modal-custom.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import { TabsComponent } from './components/tabs/tabs.component';
import { GlobalModHeaderComponent } from './components/global-mod-header/global-mod-header.component';
import { LayersButtonComponent } from './components/layers-button/layers-button.component';
import { MapsButtonComponent } from './components/maps-button/maps-button.component';
import { LayersTabComponent } from './components/layers-tab/layers-tab.component';

@NgModule({
  declarations: [
    ResetPasswordModalComponent,
    MessageModalComponent,
    HeaderComponent,
    FooterComponent,
    PaymentComponent,
    FilterPipe,
    SanitizeHtmlPipe,
    ResetPasswordSuccessModalComponent,
    SubscribErrorModalComponent,
    CollaboratorNewAccountComponent,
    LiveShareModalComponent,
    LiveShareExpiredModalComponent,
    LiveShareExpiredModalWvComponent,
    LiveShareExpiredModalFdpComponent,
    LiveShareExpiredModalCustomComponent,
    LoaderComponent,
    TabsComponent,
    GlobalModHeaderComponent,
    LayersButtonComponent,
    MapsButtonComponent,
    LayersTabComponent,
  ],
  exports: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    TabsComponent,
    PaymentComponent,
    GlobalModHeaderComponent,
    MatExpansionModule,
    FilterPipe,
    SanitizeHtmlPipe,
    ReactiveFormsModule,
    NgxSpinnerModule,
    LayersButtonComponent,
    MapsButtonComponent,
    LayersTabComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatSidenavModule,
    MatToolbarModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTooltipModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    MatIconModule,
    MatTabsModule
  ],
})
export class SharedModule {}
