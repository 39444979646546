<app-global-mod-header></app-global-mod-header>
<app-layers-tab></app-layers-tab>
<div class="container">
  <div class="row page-title" style="margin-right: 0px">
    <div class="col-md-7 mt-3 p-0" >
      <h3>
        <a routerLink="/global">Global Modification</a> » <a routerLink="/layers">Layers</a> » <a>{{ layerName }}</a> »
        <a routerLink="/pdfTable">PDF Table</a>
      </h3>
    </div>
    <div class="col-md-5">
      <ul class="actions navbar-right">
        <li role="presentation">
          <a routerLink="/pdfTable" class="mat-tab-label-active">PDF table</a>
        </li>
        <li role="presentation">
          <a routerLink="/labels">Labels</a>
        </li>
        <li role="presentation">
          <a routerLink="/showFields">Show Fields</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="content">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div id="field1" class="label_name_div_margin">
          <p id="apn_text" class="label_names_margin">APN:</p>
          <select id="id_apn" name="apn" [(ngModel)]="pdfTableData.apn">
            <option value="(Choose field)" disabled="disabled">
              (Choose field)
            </option>

            <option value="acres" name="Acres">Acres</option>

            <option value="apn_12" name="Apn 12">Apn 12</option>

            <option value="apn_full" name="Apn Full" selected="selected">
              Apn Full
            </option>

            <option value="apn_geom_raw" name="Apn Geom Raw">
              Apn Geom Raw
            </option>

            <option value="assdyear" name="Assdyear">Assdyear</option>

            <option value="coverage" name="Coverage">Coverage</option>

            <option value="currentd_1" name="Currentd 1">Currentd 1</option>

            <option value="currentdoc" name="Currentdoc">Currentdoc</option>

            <option
              value="currentsalebuyer1fullname"
              name="Currentsalebuyer1Fullname"
            >
              Currentsalebuyer1Fullname
            </option>

            <option
              value="currentsalebuyer2fullname"
              name="Currentsalebuyer2Fullname"
            >
              Currentsalebuyer2Fullname
            </option>

            <option
              value="currentsalecontractdate"
              name="Currentsalecontractdate"
            >
              Currentsalecontractdate
            </option>

            <option
              value="currentsaledocumenttype"
              name="Currentsaledocumenttype"
            >
              Currentsaledocumenttype
            </option>

            <option
              value="currentsaleseller1fullname"
              name="Currentsaleseller1Fullname"
            >
              Currentsaleseller1Fullname
            </option>

            <option
              value="currentsaleseller2fullname"
              name="Currentsaleseller2Fullname"
            >
              Currentsaleseller2Fullname
            </option>

            <option value="currentsalesprice" name="Currentsalesprice">
              Currentsalesprice
            </option>

            <option value="currentsalespricecode" name="Currentsalespricecode">
              Currentsalespricecode
            </option>

            <option value="description" name="Description">Description</option>

            <option value="effectiveyearbuilt" name="Effectiveyearbuilt">
              Effectiveyearbuilt
            </option>

            <option value="geom" name="Geom">Geom</option>

            <option value="gid" name="Gid">Gid</option>

            <option value="landuse1" name="Landuse1">Landuse1</option>

            <option value="landvalue" name="Landvalue">Landvalue</option>

            <option value="legaldescription" name="Legaldescription">
              Legaldescription
            </option>

            <option value="lotsizedepthfeet" name="Lotsizedepthfeet">
              Lotsizedepthfeet
            </option>

            <option value="lotsizefrontagefeet" name="Lotsizefrontagefeet">
              Lotsizefrontagefeet
            </option>

            <option value="mail_full" name="Mail Full">Mail Full</option>

            <option value="mail_p1" name="Mail P1">Mail P1</option>

            <option value="mail_p2" name="Mail P2">Mail P2</option>

            <option value="mail_qual" name="Mail Qual">Mail Qual</option>

            <option
              value="mailingforeignaddressind"
              name="Mailingforeignaddressind"
            >
              Mailingforeignaddressind
            </option>

            <option value="markettotalvalue" name="Markettotalvalue">
              Markettotalvalue
            </option>

            <option
              value="marketvalueimprovement"
              name="Marketvalueimprovement"
            >
              Marketvalueimprovement
            </option>

            <option value="marketvalueland" name="Marketvalueland">
              Marketvalueland
            </option>

            <option value="marketyear" name="Marketyear">Marketyear</option>

            <option value="municipality" name="Municipality">
              Municipality
            </option>

            <option value="owner1corpind" name="Owner1Corpind">
              Owner1Corpind
            </option>

            <option value="owner1firstname" name="Owner1Firstname">
              Owner1Firstname
            </option>

            <option value="owner1lastname" name="Owner1Lastname">
              Owner1Lastname
            </option>

            <option value="owner1middlename" name="Owner1Middlename">
              Owner1Middlename
            </option>

            <option value="owner1ownershiprights" name="Owner1Ownershiprights">
              Owner1Ownershiprights
            </option>

            <option value="owner1suffix" name="Owner1Suffix">
              Owner1Suffix
            </option>

            <option value="owner2corpind" name="Owner2Corpind">
              Owner2Corpind
            </option>

            <option value="owner2firstname" name="Owner2Firstname">
              Owner2Firstname
            </option>

            <option value="owner2lastname" name="Owner2Lastname">
              Owner2Lastname
            </option>

            <option value="owner2middlename" name="Owner2Middlename">
              Owner2Middlename
            </option>

            <option value="owner2suffix" name="Owner2Suffix">
              Owner2Suffix
            </option>

            <option value="owner_full" name="Owner Full">Owner Full</option>

            <option value="owner_p1" name="Owner P1">Owner P1</option>

            <option value="owner_p2" name="Owner P2">Owner P2</option>

            <option value="owneroccupied" name="Owneroccupied">
              Owneroccupied
            </option>

            <option value="preplan" name="Preplan">Preplan</option>

            <option
              value="prevsalebuyer1fullname"
              name="Prevsalebuyer1Fullname"
            >
              Prevsalebuyer1Fullname
            </option>

            <option
              value="prevsalebuyer2fullname"
              name="Prevsalebuyer2Fullname"
            >
              Prevsalebuyer2Fullname
            </option>

            <option value="prevsalecontractdate" name="Prevsalecontractdate">
              Prevsalecontractdate
            </option>

            <option value="prevsaledocumenttype" name="Prevsaledocumenttype">
              Prevsaledocumenttype
            </option>

            <option value="prevsalerecordingdate" name="Prevsalerecordingdate">
              Prevsalerecordingdate
            </option>

            <option
              value="prevsaleseller1fullname"
              name="Prevsaleseller1Fullname"
            >
              Prevsaleseller1Fullname
            </option>

            <option
              value="prevsaleseller2fullname"
              name="Prevsaleseller2Fullname"
            >
              Prevsaleseller2Fullname
            </option>

            <option value="prevsalesprice" name="Prevsalesprice">
              Prevsalesprice
            </option>

            <option value="prevsalespricecode" name="Prevsalespricecode">
              Prevsalespricecode
            </option>

            <option value="prop_class_id" name="Prop Class Id">
              Prop Class Id
            </option>

            <option value="propertyclassid" name="Propertyclassid">
              Propertyclassid
            </option>

            <option value="propertyid" name="Propertyid">Propertyid</option>

            <option value="roofcovercode" name="Roofcovercode">
              Roofcovercode
            </option>

            <option value="rooftypecode" name="Rooftypecode">
              Rooftypecode
            </option>

            <option value="situs_city" name="Situs City">Situs City</option>

            <option value="situs_full" name="Situs Full">Situs Full</option>

            <option value="situs_geostatus" name="Situs Geostatus">
              Situs Geostatus
            </option>

            <option value="situs_lat" name="Situs Lat">Situs Lat</option>

            <option value="situs_long" name="Situs Long">Situs Long</option>

            <option value="situs_p1" name="Situs P1">Situs P1</option>

            <option value="situs_p2" name="Situs P2">Situs P2</option>

            <option value="situs_qual" name="Situs Qual">Situs Qual</option>

            <option value="situs_zip5" name="Situs Zip5">Situs Zip5</option>

            <option value="situscensusblock" name="Situscensusblock">
              Situscensusblock
            </option>

            <option value="situscensustract" name="Situscensustract">
              Situscensustract
            </option>

            <option value="situsgeostatuscode" name="Situsgeostatuscode">
              Situsgeostatuscode
            </option>

            <option value="size" name="Size">Size</option>

            <option value="square_feet" name="Square Feet">Square Feet</option>

            <option value="structurev" name="Structurev">Structurev</option>

            <option value="sumbuildingsqft" name="Sumbuildingsqft">
              Sumbuildingsqft
            </option>

            <option value="sumgrossareasqft" name="Sumgrossareasqft">
              Sumgrossareasqft
            </option>

            <option value="taxamt" name="Taxamt">Taxamt</option>

            <option value="taxdeliquentyear" name="Taxdeliquentyear">
              Taxdeliquentyear
            </option>

            <option value="taxratecodearea" name="Taxratecodearea">
              Taxratecodearea
            </option>

            <option value="taxyear" name="Taxyear">Taxyear</option>

            <option value="totalvalue" name="Totalvalue">Totalvalue</option>

            <option value="usecode" name="Usecode">Usecode</option>
          </select>
        </div>
        <div id="field2" class="label_name_div_margin">
          <p id="OwName" class="label_names_margin">Owner Name</p>
          <select
            id="id_owner_name"
            name="owner_name"
            [(ngModel)]="pdfTableData.owner_name"
          >
            <option value=" (Choose field)" disabled="disabled">
              (Choose field)
            </option>

            <option value="acres" name="Acres">Acres</option>

            <option value="apn_12" name="Apn 12">Apn 12</option>

            <option value="apn_full" name="Apn Full">Apn Full</option>

            <option value="apn_geom_raw" name="Apn Geom Raw">
              Apn Geom Raw
            </option>

            <option value="assdyear" name="Assdyear">Assdyear</option>

            <option value="coverage" name="Coverage">Coverage</option>

            <option value="currentd_1" name="Currentd 1">Currentd 1</option>

            <option value="currentdoc" name="Currentdoc">Currentdoc</option>

            <option
              value="currentsalebuyer1fullname"
              name="Currentsalebuyer1Fullname"
            >
              Currentsalebuyer1Fullname
            </option>

            <option
              value="currentsalebuyer2fullname"
              name="Currentsalebuyer2Fullname"
            >
              Currentsalebuyer2Fullname
            </option>

            <option
              value="currentsalecontractdate"
              name="Currentsalecontractdate"
            >
              Currentsalecontractdate
            </option>

            <option
              value="currentsaledocumenttype"
              name="Currentsaledocumenttype"
            >
              Currentsaledocumenttype
            </option>

            <option
              value="currentsaleseller1fullname"
              name="Currentsaleseller1Fullname"
            >
              Currentsaleseller1Fullname
            </option>

            <option
              value="currentsaleseller2fullname"
              name="Currentsaleseller2Fullname"
            >
              Currentsaleseller2Fullname
            </option>

            <option value="currentsalesprice" name="Currentsalesprice">
              Currentsalesprice
            </option>

            <option value="currentsalespricecode" name="Currentsalespricecode">
              Currentsalespricecode
            </option>

            <option value="description" name="Description">Description</option>

            <option value="effectiveyearbuilt" name="Effectiveyearbuilt">
              Effectiveyearbuilt
            </option>

            <option value="geom" name="Geom">Geom</option>

            <option value="gid" name="Gid">Gid</option>

            <option value="landuse1" name="Landuse1">Landuse1</option>

            <option value="landvalue" name="Landvalue">Landvalue</option>

            <option value="legaldescription" name="Legaldescription">
              Legaldescription
            </option>

            <option value="lotsizedepthfeet" name="Lotsizedepthfeet">
              Lotsizedepthfeet
            </option>

            <option value="lotsizefrontagefeet" name="Lotsizefrontagefeet">
              Lotsizefrontagefeet
            </option>

            <option value="mail_full" name="Mail Full">Mail Full</option>

            <option value="mail_p1" name="Mail P1">Mail P1</option>

            <option value="mail_p2" name="Mail P2">Mail P2</option>

            <option value="mail_qual" name="Mail Qual">Mail Qual</option>

            <option
              value="mailingforeignaddressind"
              name="Mailingforeignaddressind"
            >
              Mailingforeignaddressind
            </option>

            <option value="markettotalvalue" name="Markettotalvalue">
              Markettotalvalue
            </option>

            <option
              value="marketvalueimprovement"
              name="Marketvalueimprovement"
            >
              Marketvalueimprovement
            </option>

            <option value="marketvalueland" name="Marketvalueland">
              Marketvalueland
            </option>

            <option value="marketyear" name="Marketyear">Marketyear</option>

            <option value="municipality" name="Municipality">
              Municipality
            </option>

            <option value="owner1corpind" name="Owner1Corpind">
              Owner1Corpind
            </option>

            <option value="owner1firstname" name="Owner1Firstname">
              Owner1Firstname
            </option>

            <option value="owner1lastname" name="Owner1Lastname">
              Owner1Lastname
            </option>

            <option value="owner1middlename" name="Owner1Middlename">
              Owner1Middlename
            </option>

            <option value="owner1ownershiprights" name="Owner1Ownershiprights">
              Owner1Ownershiprights
            </option>

            <option value="owner1suffix" name="Owner1Suffix">
              Owner1Suffix
            </option>

            <option value="owner2corpind" name="Owner2Corpind">
              Owner2Corpind
            </option>

            <option value="owner2firstname" name="Owner2Firstname">
              Owner2Firstname
            </option>

            <option value="owner2lastname" name="Owner2Lastname">
              Owner2Lastname
            </option>

            <option value="owner2middlename" name="Owner2Middlename">
              Owner2Middlename
            </option>

            <option value="owner2suffix" name="Owner2Suffix">
              Owner2Suffix
            </option>

            <option value="owner_full" name="Owner Full">Owner Full</option>

            <option value="owner_p1" name="Owner P1">Owner P1</option>

            <option value="owner_p2" name="Owner P2">Owner P2</option>

            <option value="owneroccupied" name="Owneroccupied">
              Owneroccupied
            </option>

            <option value="preplan" name="Preplan">Preplan</option>

            <option
              value="prevsalebuyer1fullname"
              name="Prevsalebuyer1Fullname"
            >
              Prevsalebuyer1Fullname
            </option>

            <option
              value="prevsalebuyer2fullname"
              name="Prevsalebuyer2Fullname"
            >
              Prevsalebuyer2Fullname
            </option>

            <option value="prevsalecontractdate" name="Prevsalecontractdate">
              Prevsalecontractdate
            </option>

            <option value="prevsaledocumenttype" name="Prevsaledocumenttype">
              Prevsaledocumenttype
            </option>

            <option value="prevsalerecordingdate" name="Prevsalerecordingdate">
              Prevsalerecordingdate
            </option>

            <option
              value="prevsaleseller1fullname"
              name="Prevsaleseller1Fullname"
            >
              Prevsaleseller1Fullname
            </option>

            <option
              value="prevsaleseller2fullname"
              name="Prevsaleseller2Fullname"
            >
              Prevsaleseller2Fullname
            </option>

            <option value="prevsalesprice" name="Prevsalesprice">
              Prevsalesprice
            </option>

            <option value="prevsalespricecode" name="Prevsalespricecode">
              Prevsalespricecode
            </option>

            <option value="prop_class_id" name="Prop Class Id">
              Prop Class Id
            </option>

            <option value="propertyclassid" name="Propertyclassid">
              Propertyclassid
            </option>

            <option value="propertyid" name="Propertyid">Propertyid</option>

            <option value="roofcovercode" name="Roofcovercode">
              Roofcovercode
            </option>

            <option value="rooftypecode" name="Rooftypecode">
              Rooftypecode
            </option>

            <option value="situs_city" name="Situs City">Situs City</option>

            <option value="situs_full" name="Situs Full">Situs Full</option>

            <option value="situs_geostatus" name="Situs Geostatus">
              Situs Geostatus
            </option>

            <option value="situs_lat" name="Situs Lat">Situs Lat</option>

            <option value="situs_long" name="Situs Long">Situs Long</option>

            <option value="situs_p1" name="Situs P1">Situs P1</option>

            <option value="situs_p2" name="Situs P2">Situs P2</option>

            <option value="situs_qual" name="Situs Qual">Situs Qual</option>

            <option value="situs_zip5" name="Situs Zip5">Situs Zip5</option>

            <option value="situscensusblock" name="Situscensusblock">
              Situscensusblock
            </option>

            <option value="situscensustract" name="Situscensustract">
              Situscensustract
            </option>

            <option value="situsgeostatuscode" name="Situsgeostatuscode">
              Situsgeostatuscode
            </option>

            <option value="size" name="Size">Size</option>

            <option value="square_feet" name="Square Feet">Square Feet</option>

            <option value="structurev" name="Structurev">Structurev</option>

            <option value="sumbuildingsqft" name="Sumbuildingsqft">
              Sumbuildingsqft
            </option>

            <option value="sumgrossareasqft" name="Sumgrossareasqft">
              Sumgrossareasqft
            </option>

            <option value="taxamt" name="Taxamt">Taxamt</option>

            <option value="taxdeliquentyear" name="Taxdeliquentyear">
              Taxdeliquentyear
            </option>

            <option value="taxratecodearea" name="Taxratecodearea">
              Taxratecodearea
            </option>

            <option value="taxyear" name="Taxyear">Taxyear</option>

            <option value="totalvalue" name="Totalvalue">Totalvalue</option>

            <option value="usecode" name="Usecode">Usecode</option>

            <option value="utilization" name="Utilization">Utilization</option>

            <option value="yearbuilt" name="Yearbuilt">Yearbuilt</option>

            <option value="zoning" name="Zoning">Zoning</option>
          </select>
        </div>
        <div id="field3" class="label_name_div_margin">
          <p id="addr1" class="label_names_margin">Full Situs Address</p>
          <select
            id="id_address1"
            name="address1"
            [(ngModel)]="pdfTableData.address1"
          >
            <option value="(Choose field)" disabled="disabled">
              (Choose field)
            </option>

            <option value="acres" name="Acres">Acres</option>

            <option value="apn_12" name="Apn 12">Apn 12</option>

            <option value="apn_full" name="Apn Full">Apn Full</option>

            <option value="apn_geom_raw" name="Apn Geom Raw">
              Apn Geom Raw
            </option>

            <option value="assdyear" name="Assdyear">Assdyear</option>

            <option value="coverage" name="Coverage">Coverage</option>

            <option value="currentd_1" name="Currentd 1">Currentd 1</option>

            <option value="currentdoc" name="Currentdoc">Currentdoc</option>

            <option
              value="currentsalebuyer1fullname"
              name="Currentsalebuyer1Fullname"
            >
              Currentsalebuyer1Fullname
            </option>

            <option
              value="currentsalebuyer2fullname"
              name="Currentsalebuyer2Fullname"
            >
              Currentsalebuyer2Fullname
            </option>

            <option
              value="currentsalecontractdate"
              name="Currentsalecontractdate"
            >
              Currentsalecontractdate
            </option>

            <option
              value="currentsaledocumenttype"
              name="Currentsaledocumenttype"
            >
              Currentsaledocumenttype
            </option>

            <option
              value="currentsaleseller1fullname"
              name="Currentsaleseller1Fullname"
            >
              Currentsaleseller1Fullname
            </option>

            <option
              value="currentsaleseller2fullname"
              name="Currentsaleseller2Fullname"
            >
              Currentsaleseller2Fullname
            </option>

            <option value="currentsalesprice" name="Currentsalesprice">
              Currentsalesprice
            </option>

            <option value="currentsalespricecode" name="Currentsalespricecode">
              Currentsalespricecode
            </option>

            <option value="description" name="Description">Description</option>

            <option value="effectiveyearbuilt" name="Effectiveyearbuilt">
              Effectiveyearbuilt
            </option>

            <option value="geom" name="Geom">Geom</option>

            <option value="gid" name="Gid">Gid</option>

            <option value="landuse1" name="Landuse1">Landuse1</option>

            <option value="landvalue" name="Landvalue">Landvalue</option>

            <option value="legaldescription" name="Legaldescription">
              Legaldescription
            </option>

            <option value="lotsizedepthfeet" name="Lotsizedepthfeet">
              Lotsizedepthfeet
            </option>

            <option value="lotsizefrontagefeet" name="Lotsizefrontagefeet">
              Lotsizefrontagefeet
            </option>

            <option value="mail_full" name="Mail Full">Mail Full</option>

            <option value="mail_p1" name="Mail P1">Mail P1</option>

            <option value="mail_p2" name="Mail P2">Mail P2</option>

            <option value="mail_qual" name="Mail Qual">Mail Qual</option>

            <option
              value="mailingforeignaddressind"
              name="Mailingforeignaddressind"
            >
              Mailingforeignaddressind
            </option>

            <option value="markettotalvalue" name="Markettotalvalue">
              Markettotalvalue
            </option>

            <option
              value="marketvalueimprovement"
              name="Marketvalueimprovement"
            >
              Marketvalueimprovement
            </option>

            <option value="marketvalueland" name="Marketvalueland">
              Marketvalueland
            </option>

            <option value="marketyear" name="Marketyear">Marketyear</option>

            <option value="municipality" name="Municipality">
              Municipality
            </option>

            <option value="owner1corpind" name="Owner1Corpind">
              Owner1Corpind
            </option>

            <option value="owner1firstname" name="Owner1Firstname">
              Owner1Firstname
            </option>

            <option value="owner1lastname" name="Owner1Lastname">
              Owner1Lastname
            </option>

            <option value="owner1middlename" name="Owner1Middlename">
              Owner1Middlename
            </option>

            <option value="owner1ownershiprights" name="Owner1Ownershiprights">
              Owner1Ownershiprights
            </option>

            <option value="owner1suffix" name="Owner1Suffix">
              Owner1Suffix
            </option>

            <option value="owner2corpind" name="Owner2Corpind">
              Owner2Corpind
            </option>

            <option value="owner2firstname" name="Owner2Firstname">
              Owner2Firstname
            </option>

            <option value="owner2lastname" name="Owner2Lastname">
              Owner2Lastname
            </option>

            <option value="owner2middlename" name="Owner2Middlename">
              Owner2Middlename
            </option>

            <option value="owner2suffix" name="Owner2Suffix">
              Owner2Suffix
            </option>

            <option value="owner_full" name="Owner Full">Owner Full</option>

            <option value="owner_p1" name="Owner P1">Owner P1</option>

            <option value="owner_p2" name="Owner P2">Owner P2</option>

            <option value="owneroccupied" name="Owneroccupied">
              Owneroccupied
            </option>

            <option value="preplan" name="Preplan">Preplan</option>

            <option
              value="prevsalebuyer1fullname"
              name="Prevsalebuyer1Fullname"
            >
              Prevsalebuyer1Fullname
            </option>

            <option
              value="prevsalebuyer2fullname"
              name="Prevsalebuyer2Fullname"
            >
              Prevsalebuyer2Fullname
            </option>

            <option value="prevsalecontractdate" name="Prevsalecontractdate">
              Prevsalecontractdate
            </option>

            <option value="prevsaledocumenttype" name="Prevsaledocumenttype">
              Prevsaledocumenttype
            </option>

            <option value="prevsalerecordingdate" name="Prevsalerecordingdate">
              Prevsalerecordingdate
            </option>

            <option
              value="prevsaleseller1fullname"
              name="Prevsaleseller1Fullname"
            >
              Prevsaleseller1Fullname
            </option>

            <option
              value="prevsaleseller2fullname"
              name="Prevsaleseller2Fullname"
            >
              Prevsaleseller2Fullname
            </option>

            <option value="prevsalesprice" name="Prevsalesprice">
              Prevsalesprice
            </option>

            <option value="prevsalespricecode" name="Prevsalespricecode">
              Prevsalespricecode
            </option>

            <option value="prop_class_id" name="Prop Class Id">
              Prop Class Id
            </option>

            <option value="propertyclassid" name="Propertyclassid">
              Propertyclassid
            </option>

            <option value="propertyid" name="Propertyid">Propertyid</option>

            <option value="roofcovercode" name="Roofcovercode">
              Roofcovercode
            </option>

            <option value="rooftypecode" name="Rooftypecode">
              Rooftypecode
            </option>

            <option value="situs_city" name="Situs City">Situs City</option>

            <option value="situs_full" name="Situs Full">Situs Full</option>

            <option value="situs_geostatus" name="Situs Geostatus">
              Situs Geostatus
            </option>

            <option value="situs_lat" name="Situs Lat">Situs Lat</option>

            <option value="situs_long" name="Situs Long">Situs Long</option>

            <option value="situs_p1" name="Situs P1">Situs P1</option>

            <option value="situs_p2" name="Situs P2">Situs P2</option>

            <option value="situs_qual" name="Situs Qual">Situs Qual</option>

            <option value="situs_zip5" name="Situs Zip5">Situs Zip5</option>

            <option value="situscensusblock" name="Situscensusblock">
              Situscensusblock
            </option>

            <option value="situscensustract" name="Situscensustract">
              Situscensustract
            </option>

            <option value="situsgeostatuscode" name="Situsgeostatuscode">
              Situsgeostatuscode
            </option>

            <option value="size" name="Size">Size</option>

            <option value="square_feet" name="Square Feet">Square Feet</option>

            <option value="structurev" name="Structurev">Structurev</option>

            <option value="sumbuildingsqft" name="Sumbuildingsqft">
              Sumbuildingsqft
            </option>

            <option value="sumgrossareasqft" name="Sumgrossareasqft">
              Sumgrossareasqft
            </option>

            <option value="taxamt" name="Taxamt">Taxamt</option>

            <option value="taxdeliquentyear" name="Taxdeliquentyear">
              Taxdeliquentyear
            </option>

            <option value="taxratecodearea" name="Taxratecodearea">
              Taxratecodearea
            </option>

            <option value="taxyear" name="Taxyear">Taxyear</option>

            <option value="totalvalue" name="Totalvalue">Totalvalue</option>

            <option value="usecode" name="Usecode">Usecode</option>

            <option value="utilization" name="Utilization">Utilization</option>

            <option value="yearbuilt" name="Yearbuilt">Yearbuilt</option>

            <option value="zoning" name="Zoning">Zoning</option>
          </select>
        </div>
        <div id="field4" class="label_name_div_margin">
          <p id="addr2" class="label_names_margin">Full Mailing Address</p>
          <select
            id="id_address2"
            name="address2"
            [(ngModel)]="pdfTableData.address2"
          >
            <option value="(Choose field)" disabled="disabled">
              (Choose field)
            </option>

            <option value="acres" name="Acres">Acres</option>

            <option value="apn_12" name="Apn 12">Apn 12</option>

            <option value="apn_full" name="Apn Full">Apn Full</option>

            <option value="apn_geom_raw" name="Apn Geom Raw">
              Apn Geom Raw
            </option>

            <option value="assdyear" name="Assdyear">Assdyear</option>

            <option value="coverage" name="Coverage">Coverage</option>

            <option value="currentd_1" name="Currentd 1">Currentd 1</option>

            <option value="currentdoc" name="Currentdoc">Currentdoc</option>

            <option
              value="currentsalebuyer1fullname"
              name="Currentsalebuyer1Fullname"
            >
              Currentsalebuyer1Fullname
            </option>

            <option
              value="currentsalebuyer2fullname"
              name="Currentsalebuyer2Fullname"
            >
              Currentsalebuyer2Fullname
            </option>

            <option
              value="currentsalecontractdate"
              name="Currentsalecontractdate"
            >
              Currentsalecontractdate
            </option>

            <option
              value="currentsaledocumenttype"
              name="Currentsaledocumenttype"
            >
              Currentsaledocumenttype
            </option>

            <option
              value="currentsaleseller1fullname"
              name="Currentsaleseller1Fullname"
            >
              Currentsaleseller1Fullname
            </option>

            <option
              value="currentsaleseller2fullname"
              name="Currentsaleseller2Fullname"
            >
              Currentsaleseller2Fullname
            </option>

            <option value="currentsalesprice" name="Currentsalesprice">
              Currentsalesprice
            </option>

            <option value="currentsalespricecode" name="Currentsalespricecode">
              Currentsalespricecode
            </option>

            <option value="description" name="Description">Description</option>

            <option value="effectiveyearbuilt" name="Effectiveyearbuilt">
              Effectiveyearbuilt
            </option>

            <option value="geom" name="Geom">Geom</option>

            <option value="gid" name="Gid">Gid</option>

            <option value="landuse1" name="Landuse1">Landuse1</option>

            <option value="landvalue" name="Landvalue">Landvalue</option>

            <option value="legaldescription" name="Legaldescription">
              Legaldescription
            </option>

            <option value="lotsizedepthfeet" name="Lotsizedepthfeet">
              Lotsizedepthfeet
            </option>

            <option value="lotsizefrontagefeet" name="Lotsizefrontagefeet">
              Lotsizefrontagefeet
            </option>

            <option value="mail_full" name="Mail Full">Mail Full</option>

            <option value="mail_p1" name="Mail P1">Mail P1</option>

            <option value="mail_p2" name="Mail P2">Mail P2</option>

            <option value="mail_qual" name="Mail Qual">Mail Qual</option>

            <option
              value="mailingforeignaddressind"
              name="Mailingforeignaddressind"
            >
              Mailingforeignaddressind
            </option>

            <option value="markettotalvalue" name="Markettotalvalue">
              Markettotalvalue
            </option>

            <option
              value="marketvalueimprovement"
              name="Marketvalueimprovement"
            >
              Marketvalueimprovement
            </option>

            <option value="marketvalueland" name="Marketvalueland">
              Marketvalueland
            </option>

            <option value="marketyear" name="Marketyear">Marketyear</option>

            <option value="municipality" name="Municipality">
              Municipality
            </option>

            <option value="owner1corpind" name="Owner1Corpind">
              Owner1Corpind
            </option>

            <option value="owner1firstname" name="Owner1Firstname">
              Owner1Firstname
            </option>

            <option value="owner1lastname" name="Owner1Lastname">
              Owner1Lastname
            </option>

            <option value="owner1middlename" name="Owner1Middlename">
              Owner1Middlename
            </option>

            <option value="owner1ownershiprights" name="Owner1Ownershiprights">
              Owner1Ownershiprights
            </option>

            <option value="owner1suffix" name="Owner1Suffix">
              Owner1Suffix
            </option>

            <option value="owner2corpind" name="Owner2Corpind">
              Owner2Corpind
            </option>

            <option value="owner2firstname" name="Owner2Firstname">
              Owner2Firstname
            </option>

            <option value="owner2lastname" name="Owner2Lastname">
              Owner2Lastname
            </option>

            <option value="owner2middlename" name="Owner2Middlename">
              Owner2Middlename
            </option>

            <option value="owner2suffix" name="Owner2Suffix">
              Owner2Suffix
            </option>

            <option value="owner_full" name="Owner Full">Owner Full</option>

            <option value="owner_p1" name="Owner P1">Owner P1</option>

            <option value="owner_p2" name="Owner P2">Owner P2</option>

            <option value="owneroccupied" name="Owneroccupied">
              Owneroccupied
            </option>

            <option value="preplan" name="Preplan">Preplan</option>

            <option
              value="prevsalebuyer1fullname"
              name="Prevsalebuyer1Fullname"
            >
              Prevsalebuyer1Fullname
            </option>

            <option
              value="prevsalebuyer2fullname"
              name="Prevsalebuyer2Fullname"
            >
              Prevsalebuyer2Fullname
            </option>

            <option value="prevsalecontractdate" name="Prevsalecontractdate">
              Prevsalecontractdate
            </option>

            <option value="prevsaledocumenttype" name="Prevsaledocumenttype">
              Prevsaledocumenttype
            </option>

            <option value="prevsalerecordingdate" name="Prevsalerecordingdate">
              Prevsalerecordingdate
            </option>

            <option
              value="prevsaleseller1fullname"
              name="Prevsaleseller1Fullname"
            >
              Prevsaleseller1Fullname
            </option>

            <option
              value="prevsaleseller2fullname"
              name="Prevsaleseller2Fullname"
            >
              Prevsaleseller2Fullname
            </option>

            <option value="prevsalesprice" name="Prevsalesprice">
              Prevsalesprice
            </option>

            <option value="prevsalespricecode" name="Prevsalespricecode">
              Prevsalespricecode
            </option>

            <option value="prop_class_id" name="Prop Class Id">
              Prop Class Id
            </option>

            <option value="propertyclassid" name="Propertyclassid">
              Propertyclassid
            </option>

            <option value="propertyid" name="Propertyid">Propertyid</option>

            <option value="roofcovercode" name="Roofcovercode">
              Roofcovercode
            </option>

            <option value="rooftypecode" name="Rooftypecode">
              Rooftypecode
            </option>

            <option value="situs_city" name="Situs City">Situs City</option>

            <option value="situs_full" name="Situs Full">Situs Full</option>

            <option value="situs_geostatus" name="Situs Geostatus">
              Situs Geostatus
            </option>

            <option value="situs_lat" name="Situs Lat">Situs Lat</option>

            <option value="situs_long" name="Situs Long">Situs Long</option>

            <option value="situs_p1" name="Situs P1">Situs P1</option>

            <option value="situs_p2" name="Situs P2">Situs P2</option>

            <option value="situs_qual" name="Situs Qual">Situs Qual</option>

            <option value="situs_zip5" name="Situs Zip5">Situs Zip5</option>

            <option value="situscensusblock" name="Situscensusblock">
              Situscensusblock
            </option>

            <option value="situscensustract" name="Situscensustract">
              Situscensustract
            </option>

            <option value="situsgeostatuscode" name="Situsgeostatuscode">
              Situsgeostatuscode
            </option>

            <option value="size" name="Size">Size</option>

            <option value="square_feet" name="Square Feet">Square Feet</option>

            <option value="structurev" name="Structurev">Structurev</option>

            <option value="sumbuildingsqft" name="Sumbuildingsqft">
              Sumbuildingsqft
            </option>

            <option value="sumgrossareasqft" name="Sumgrossareasqft">
              Sumgrossareasqft
            </option>

            <option value="taxamt" name="Taxamt">Taxamt</option>

            <option value="taxdeliquentyear" name="Taxdeliquentyear">
              Taxdeliquentyear
            </option>

            <option value="taxratecodearea" name="Taxratecodearea">
              Taxratecodearea
            </option>

            <option value="taxyear" name="Taxyear">Taxyear</option>

            <option value="totalvalue" name="Totalvalue">Totalvalue</option>

            <option value="usecode" name="Usecode">Usecode</option>

            <option value="utilization" name="Utilization">Utilization</option>

            <option value="yearbuilt" name="Yearbuilt">Yearbuilt</option>
            <option value="zoning" name="Zoning">Zoning</option>
          </select>
        </div>
        <div id="field5" class="label_name_div_margins">
          <p class="label_names_margin">Status</p>
          <input
            [(ngModel)]="pdfTableData.visibility"
            [value]="on"
            id="pdfTable_status_on"
            type="radio"
            name="pdfTable_tool_status"
            value="True"
          />
          On &nbsp;&nbsp;
          <input
            [(ngModel)]="pdfTableData.visibility"
            [value]="off"
            id="pdfTable_status_off"
            type="radio"
            name="pdfTable_tool_status"
            value="False"
          />
          Off
        </div>
        <button
          mat-flat-button
          class="save mb-3"
          type="submit"
          (click)="submitForm()"
        >
          <mat-icon>save</mat-icon>
          Save
        </button>
      </div>
    </div>
  </div>
</div>
