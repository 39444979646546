<div class="modal-dialog" style="display: table;width: auto;">
  <div class="modal-content error-modal-content">
      <div class="modal-body">
          <h4 id="heading-modal" class="head-modal">{{ status }}</h4>
          <hr class="modal-hr-line">
          <p id="err-recurly-modal-body" class="inbody-modal"></p>
          <div class="btn-container" style="text-align: right">
              <button type="button" class="btn accept-btn gernal-btn" data-dismiss="modal" style="float: right;border-radius: 0px ; margin-top: 10px"  (click)="closeDialog()">OK</button>
          </div>
          <br>
          <br>
          <br>
      </div>
  </div>
</div>




