<form #formRef id="my-payment-form">
  <div class="input-group">
    <input
      class="payment-inputs  payment-inputs-other"
      id="firstname_pay"
      placeholder="First Name"
      type="text"
      data-recurly="first_name"
    />
    <input
      class="payment-inputs payment-inputs-other"
      id="lastname_pay"
      placeholder="Last Name"
      type="text"
      data-recurly="last_name"
    />
  </div>

  <div>
    <div data-recurly="card" class="card"></div>
  </div>
  <input
    type="hidden"
    id="recurly-token"
    name="recurly-token"
    data-recurly="token"
  />
  <div class="recurly-images">
    <img
      class="poweredbyrecurly recurly-image"
      src="/assets/img/recurly/powered_recurly.png"
    />
    <div id="trust_wave" class="recurly-image" style="display: inline-block">
      <script
        type="text/javascript"
        src="https://sealserver.trustwave.com/seal.js?style=invert&code=0264d9320c65430c91210dc056604719"
      ></script>
    </div>

    <img
      class="poweredbyrecurly recurly-image"
      width="100px"
      src="/assets/img/recurly/TSYS.png"
    />
  </div>
</form>
