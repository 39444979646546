import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { createLayer } from 'src/app/modules/shared/models/new-layer/newLayer.model';

@Component({
  selector: 'app-clone-layer',
  templateUrl: './clone-layer.component.html',
  styleUrls: ['./clone-layer.component.css']
})
export class CloneLayerComponent implements OnInit {
  layer : createLayer = new createLayer();
  constructor(private dialogRef: MatDialogRef<CloneLayerComponent>,
    @Inject(MAT_DIALOG_DATA) layer: createLayer,) {
      this.layer = layer
     }

  ngOnInit(): void {
    //
  }
  save(form: NgForm) {
//

  }
  close() {
    this.dialogRef.close();
}
}
