<form
  action="/maps/229/api/update_capture/"
  method="post"
  class="content"
  #form="ngForm"
  (submit)="edit()"
>
  <ng-container *ngFor="let data of boundaries">
    <h3
      *ngIf="layerIcon.toString() === data.id.toString()"
      style="background-size: 20px"
      [ngStyle]="{
        'background-image':
          data.name !== 'Parcels'
            ? 'url(' + data.legend.icon + ')'
            : 'url(' + data.style[sideNavService.currentLoadedMap].icon + ')'
      }"
    >
      Edit Feature
    </h3>
  </ng-container>
  <ng-container *ngFor="let edit of editCaptureData; let i = index">
    <ul class="fields">
      <li class="capture-li">
        <label class="label">{{ edit.name }}</label
        ><input
          *ngIf="
            edit.type === 'string' &&
            edit.visible === true &&
            (edit.dropdown === false || !edit.dropdown)
          "
          [(ngModel)]="edit.data"
          value="{{ edit.data }}"
          class="value capture-input"
          type="text"
          name="field-{{ edit.data }}"
        />
        <input
          *ngIf="edit.type === 'number' && edit.visible === true"
          [(ngModel)]="edit.data"
          value="{{ edit.data }}"
          class="date value"
          type="number"
          name="field25479"
        />
        <input
          *ngIf="edit.type === 'integer' && edit.visible === true"
          [(ngModel)]="edit.data"
          value="{{ edit.data }}"
          class="date value"
          type="date"
          name="field25479"
        />
        <input
          *ngIf="edit.type === 'date' && edit.visible === true"
          [(ngModel)]="edit.data"
          value="{{ edit.data }}"
          class="date value"
          type="date"
          name="field25479"
        />
        <select
          class="select-one"
          [(ngModel)]="edit.data"
          *ngIf="
            edit.type === 'string' &&
            edit.visible === true &&
            edit.dropdown === true
          "
        >
          <option
            [selected]="edit.data === edit.data"
            [value]="edit.data"
            value="{{ edit.data }}"
            *ngFor="let dropValue of edit.dropdowns; index as i"
          >
            {{ dropValue }}
          </option>
        </select>
      </li>
    </ul>
  </ng-container>
</form>
<div class="btn">
  <a
    class="closer button"
    (click)="clickToZoom()"
    [tooltip]="mapService.templatetoolTips['zoom_to_selection_right_panel']"
    [options]="myOptions12"
  >
    <img
      class="save-zoom common-btns"
      src="/assets/img/tools/zoom-to.png"
      alt="closer"
  /></a>
  <a
    class="closer button"
    type="submit"
    (click)="edit()"
    [tooltip]="save"
    [options]="myOptions13"
  >
    <img
      class="save-img common-btns"
      src="/assets/img/tools/save.png"
      alt="closer"
  /></a>
  <a
    class="closer button"
    (click)="delete()"
    [tooltip]="deleteCapture"
    [options]="myOptions16"
    ><img
      src="/assets/img/table/addRedX2.png"
      alt="back"
      class="delete_data common-btns"
  /></a>
  <a
    class="closer button"
    (click)="closeRightpanel()"
    [tooltip]="mapService.templatetoolTips['close_right_panel']"
    [options]="myOptions15"
  >
    <img
      class="closer-img common-btns"
      src="/assets/img/tools/close-pv3.png"
      alt="closer"
  /></a>
</div>
