/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subject } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { EditFeatureCaptureComponent } from '../../components/tools/capture-tool/edit-feature-capture/edit-feature-capture.component';
import { FeatureNameComponent } from '../../components/tools/feature-name/feature-name.component';
import { FeaturesListComponent } from '../../components/tools/features-list/features-list.component';
import { QueryLimitComponent } from '../../modals/query-limit/query-limit.component';
import { MapServiceService } from '../Map-service/map-service.service';

@Injectable({
  providedIn: 'root',
})
export class SideNavService {
  showChat: Subject<any> = new Subject<any>();
  leftMenu: Subject<any> = new Subject<any>();
  rightMenu: Subject<any> = new Subject<any>();
  samePanelSize: Subject<any> = new Subject<any>();
  infoClicked: Subject<number> = new Subject<number>();
  metricsClicked: Subject<string> = new Subject<string>();
  activeTool: Subject<any> = new Subject<any>();
  changeLayer: Subject<string> = new Subject<string>();
  editAnnotationAfterData: Subject<any> = new Subject<any>();
  uploadAnnotationAfterData: Subject<any> = new Subject<any>();
  renameBookMarkData: Subject<any> = new Subject<any>();
  renameBookMarkFolder: Subject<any> = new Subject<any>();
  infoSidebar: Subject<any> = new Subject<any>();
  openCloseSideNav: Subject<any> = new Subject<any>();
  leftSideNav: Subject<any> = new Subject<any>();
  deleteAnnotation: Subject<any> = new Subject<any>();
  deleteAnnotationFolder: Subject<any> = new Subject<any>();
  deleteBookMarkData: Subject<any> = new Subject<any>();
  refreshAnnotation: Subject<any> = new Subject<any>();
  ctrlCheck: Subject<any> = new Subject<any>();
  legendPoint: Subject<any> = new Subject<any>();
  legendLine: Subject<any> = new Subject<any>();
  legendPolygon: Subject<any> = new Subject<any>();
  loadfirstTime: Subject<any> = new Subject<any>();
  isLoadedFeatureList: Subject<any> = new Subject<any>();
  openZoom: Subject<any> = new Subject<any>();
  RightToolName: Subject<any> = new Subject<any>();

  // Arrays
  rightPanelComponentHistory: any = [];
  dataPassToRightPanel?: any[] = [];
  activeFeatureList: any = [];
  // objects
  legendState: any = {
    annotation: true,
    baseMaps: true,
  };
  legendAnnotCheck: any = {
    checked1: false,
    checked2: false,
    checked3: false,
  };
  annotComponentState: any = {
    exapandPanel1: true,
    exapandPanel2: true,
    exapandPanel3: true,
    exapandPanel4: true,
  };
  annotFolderState: any = {};
  downloadViewState: any = {
    pdf: true,
    png: false,
  };
  metricsState: any = {
    expandPanel1: true,
    expandPanel2: false,
    expandPanel3: false,
  };
  // boolean
  basicData = false;
  showLegend = false;
  hidePrintPanel: any = false;
  hidePanel: any = false;
  temporaryDisabled: any = false;
  hideRightPanelForImage = false;
  requireCheckbox: any = false;
  defaultModal: any = true;
  requireCheckboxEmbed: any = false;
  isReadyOnlyEmbed: any = true;
  // Any
  fileName: any = 'pdf';
  loadFirstTimeAnnot: any;
  selectedPanelInViewData: any;
  visibleToolCount: any = 0;
  replay_email: any;
  email_body: any;
  fromAnnotation: any;
  annotationData: any;
  folderData: any;
  shareAnnot: any;
  allBookMarks: any;
  allFolders: any;
  informationIsLoaded: any;
  getToolName: any;
  annotationLayer: any;
  annotationType: any;
  layersFeatures: any;
  showAnimateNow: any;
  clearFeatureList: any;
  type: any;
  numbering: any;
  checkLabel: any;
  fromAnnotationFeature: any;
  windCheck: any;
  FeatureListsplice: any;
  historyMaintained: any;
  setFeatureNameView: any;
  sortMaps: any;
  setLayerState: any;
  DrawlayerAnnot: any;
  zoomFeatures: any;
  firstLoadInfo: any;
  zoomId: any;
  sortedMaps: any;
  privilegeLevel: any;
  days: any = '2 hours';
  advanceSelectState: any;
  selectedBuffer: any = '';
  showAdvanceNowSelect: any;
  advanceSelectLayer: any = 'allLayers';
  selectedSubMethod: any = 'Feet';
  bufferValue: any;
  bufferShow: any;
  selectedUserEmbed: any = '000';
  embedExpiryValue: any;
  dateEmbed: any;
  checkboxTemplateEmbed: any;
  modalTemplateEmbed: any;
  saveFeatureListData: any;
  currentLoadedMap: any;
  reSubDialog: any;
  newAnnoCreated: any;
  feature1: any;
  feature: any;
  clearUploadClass: Subject<any> = new Subject<any>();
  featureNameDataforAnnot: any;
  searchLimit: any;
  // public
  public sidenav!: MatSidenav;
  public rightSideNav!: MatSidenav;
  // url
  downloadFileUrl = 'https://stage.mapport.com/api';
  constructor(
    private mapService: MapServiceService,
    private dialog: MatDialog
  ) {}

  addAnnotationStateHanledKeys() {
    for (let i = 0; i < this.folderData.length; i++) {
      this.annotFolderState[`exapandPanelId${this.folderData[i].id}`] = false;
    }
    this.annotFolderState[`exapandPanelParent`] = false;
  }

  decideComponent(res: any): void {
    if (res && res.length > 1) {
      this.rightMenuClicked({
        component: FeaturesListComponent,
        data: res,
        history: false,
        push: true,
      });
    }
    if (
      (res.features && res.features.length > 1) ||
      res.features?.length == 0
    ) {
      this.rightMenuClicked({
        component: FeaturesListComponent,
        data: res.features,
        history: false,
        push: true,
      });
      if (res.features.length == this.searchLimit) {
        if (this.mapService.isLiveShareLink === 'false') {
          this.dialog.open(QueryLimitComponent, {
            panelClass: 'app-full-bleed-dialog',
            backdropClass: 'bdrop',
          });
        }
      }
    } else if (res.features && res.features.length <= 1) {
      if (this.getToolName === 'Capture') {
        this.isLoadedFeature('Fesdsadature');
        this.rightMenuClicked({
          component: EditFeatureCaptureComponent,

          data: res.features[0],
          history: false,
          push: true,
        });
      } else {
        this.isLoadedFeature('Fesdsadature');
        this.rightMenuClicked({
          component: FeatureNameComponent,
          data: res.features[0],
          history: false,
          push: true,
        });
      }

      // this.global.toggleLoader(true);
    }
  }

  public clearUploadClassNow(value: any): void {
    this.clearUploadClass.next(value);
  }

  public loadfirst(value: any): void {
    this.loadfirstTime.next(value);
  }

  public chat(value: any): void {
    this.showChat.next(value);
  }

  public setRightToolName(value: any): void {
    this.RightToolName.next(value);
  }

  public setOpenZoom(value: any): void {
    this.openZoom.next(value);
  }

  public setLegendPoint(value: any): void {
    this.legendPoint.next(value);
  }

  public setLegendLine(value: any): void {
    this.legendLine.next(value);
  }

  public isLoadedFeature(value: any): void {
    this.isLoadedFeatureList.next(value);
  }

  public setLegendPolygon(value: any): void {
    this.legendPolygon.next(value);
  }

  public refreshAnnotationComponent(value: any): void {
    this.refreshAnnotation.next(value);
  }
  public ctrlCheckButton(value: any): void {
    this.ctrlCheck.next(value);
  }

  public deleteAnnotationComponent(value: any): void {
    this.deleteAnnotation.next(value);
  }

  public deleteAnnotationFolderComponent(value: any): void {
    this.deleteAnnotationFolder.next(value);
  }

  closeLeftPenal() {
    this.mapService.allBtns.forEach((elem: any) => {
      elem.classList.remove('active');
    });
    this.leftSideNavState('closed');
    this.close();
  }
  public deleteBookMarkComponent(value: any): void {
    this.deleteBookMarkData.next(value);
  }
  public sideNavState(value: any): void {
    this.openCloseSideNav.next(value);
  }
  public leftSideNavState(value: any): void {
    this.leftSideNav.next(value);
  }
  public infoSideNav(value: any): void {
    this.infoSidebar.next(value);
  }
  public clickChangeLayer(value: string) {
    this.changeLayer.next(value);
  }

  public leftMenuClicked(value: any) {
    this.leftMenu.next(value);
  }

  public rightMenuClicked(value: any) {
    this.rightMenu.next(value);
  }
  public panelSize(value: any) {
    this.samePanelSize.next(value);
  }
  public clickedInfo(value: number) {
    this.infoClicked.next(value);
  }

  public clickedTool(value: string, url: string) {
    this.activeTool.next({ name: value, url: url });
  }

  public clickedMetrics(value: string) {
    this.metricsClicked.next(value);
  }

  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }
  public setRightSidenav(sidenav: MatSidenav) {
    this.rightSideNav = sidenav;
  }

  public open() {
    this.temporaryDisabled = true;
    // this.sidenav.toggle();
    setTimeout(() => {
      this.temporaryDisabled = false;
    }, 10);
    return this.sidenav.open();
  }

  public close() {
    this.temporaryDisabled = true;
    // this.sidenav.toggle();
    setTimeout(() => {
      this.temporaryDisabled = false;
    }, 10);
    return this.sidenav.close();
  }

  public openRightSideNav() {
    this.temporaryDisabled = true;
    // this.sidenav.toggle();
    setTimeout(() => {
      this.temporaryDisabled = false;
    }, 10);
    this.sideNavState('open');
    return this.rightSideNav.open();
  }

  public closeRightSideNav() {
    //on clearing destroy animation setting
    this.showAnimateNow = false;
    this.informationIsLoaded = false;
    this.temporaryDisabled = true;
    // this.sidenav.toggle();
    setTimeout(() => {
      this.temporaryDisabled = false;
    }, 10);
    //clear history before close
    this.clickedInfo(0);
    this.sideNavState('closed');
    // this.infoSideNav('closed');
    this.activeFeatureList = [];
    this.rightPanelComponentHistory = [];
    return this.rightSideNav.close();
  }

  public toggle() {
    this.temporaryDisabled = true;
    // this.sidenav.toggle();
    setTimeout(() => {
      this.temporaryDisabled = false;
    }, 10);
    return this.sidenav.toggle();
  }

  public toggleRight() {
    this.temporaryDisabled = true;
    // this.sidenav.toggle();
    setTimeout(() => {
      this.temporaryDisabled = false;
    }, 10);
    return this.rightSideNav.toggle();
  }

  public editAnnotationAfter(value: any) {
    this.editAnnotationAfterData.next(value);
  }
  public uploadAnnotationAfter(value: any) {
    this.uploadAnnotationAfterData.next(value);
  }

  public reNameBookMarkData(value: any) {
    this.renameBookMarkData.next(value);
  }
  public renameFolder(value: any) {
    this.renameBookMarkFolder.next(value);
  }

  //dynamic top  to sidepanels
  public onResize() {
    const contentElement = document.getElementById(
      'right_panel'
    ) as HTMLElement;
    const contentElement4 = document.getElementById(
      'left_panel'
    ) as HTMLElement;

    const contentElement5 = document.getElementById(
      'zoom-controls'
    ) as HTMLElement;

    const contentElement1 = document.getElementById('whitebar') as HTMLElement;
    const contentElement2 = document.getElementById('greenbar') as HTMLElement;
    const contentElement6 = document.getElementById('ui-id-1') as HTMLElement;
    const contentElement7 = document.getElementById('ui-id-2') as HTMLElement;
    let totalHeight: any;
    if (
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      document.getElementById('whitebar') !== null &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (document.getElementById('greenbar') as HTMLElement) !== null
    ) {
      totalHeight = contentElement1.offsetHeight + contentElement2.offsetHeight;
    } else if (
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      document.getElementById('whitebar') === null &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (document.getElementById('greenbar') as HTMLElement) !== null
    ) {
      totalHeight = contentElement2.offsetHeight;
    } else if (
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      document.getElementById('whitebar') !== null &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (document.getElementById('greenbar') as HTMLElement) === null
    ) {
      totalHeight = contentElement1.offsetHeight;
    }
    if (contentElement != null && contentElement != undefined) {
      contentElement.style.setProperty('top', `${totalHeight}px`, 'important');
    }
    if (contentElement4 != null && contentElement4 != undefined) {
      contentElement4.style.setProperty('top', `${totalHeight}px`, 'important');
    }
    if (contentElement5 != null && contentElement5 != undefined) {
      contentElement5.style.setProperty(
        'top',
        `${totalHeight + 8}px`,
        'important'
      );
    }
    if (contentElement6 != null && contentElement6 != undefined) {
      contentElement6.style.setProperty('top', `${totalHeight}px`, 'important');
    }

    if (contentElement7 != null && contentElement7 != undefined) {
      contentElement7.style.setProperty('top', `${totalHeight}px`, 'important');
    }
  }

  //for maintaing right panel history
  public maintainHistory(): void {
    if (
      this.rightPanelComponentHistory.length == 0 ||
      this.rightPanelComponentHistory.length == 1 ||
      !this.rightPanelComponentHistory.length
    ) {
      this.rightPanelComponentHistory.pop();
      this.closeRightSideNav();
      this.sideNavState('close');
      this.infoSideNav('closed');
    } else {
      this.rightMenuClicked({
        id: this.rightPanelComponentHistory[
          this.rightPanelComponentHistory.length - 2
        ]?.id,
        component:
          this.rightPanelComponentHistory[
            this.rightPanelComponentHistory.length - 2
          ].component,
        data: this.rightPanelComponentHistory[
          this.rightPanelComponentHistory.length - 2
        ].data,
        history: true,
        push: this.rightPanelComponentHistory[
          this.rightPanelComponentHistory.length - 2
        ].push,
      });
    }
  }
}
