import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TabService } from 'src/app/services/tab/tab.service';
import { TabItem } from '../../models/tab/tab.model';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit {
  user: any
  tabs: TabItem[] | undefined;
  constructor(public router: Router, public tabService: TabService, ) {
   }

  ngOnInit(): void {
    this.tabService.activeTab$.subscribe(tab => {
      console.log('active tab set: ', tab);
    });
    this.tabs = this.tabService.getTabs();
    this.tabService.activeTab$.next(this.tabs[6]);
  }
  tabSelected(tab: TabItem) {
    this.tabService.activeTab$.next(tab);
  }


}
