<app-global-mod-header></app-global-mod-header>
<app-layers-tab></app-layers-tab>
<div class="container">
  <div class="row page-title" style="margin-right: 0px">
    <div class="col-md-7 mt-3 p-0">
      <h3>
        <a routerLink="/global">Global Modification</a> » <a routerLink="/layers">Layers</a> »
        <a>{{ layerName }}</a>
      </h3>
    </div>
    <div class="col-md-5">
      <ul class="actions navbar-right">
        <li role="presentation">
          <a
            [routerLink]="disableButton ? null : '/pdfTable'"
            [routerLinkActive]="disableButton ? 'is_disabled' : ''"
            >PDF table</a
          >
        </li>
        <li role="presentation">
          <a
            [routerLink]="disableButton ? null : '/labels'"
            [routerLinkActive]="disableButton ? 'is_disabled' : ''"
            >Labels</a
          >
        </li>
        <li role="presentation">
          <a
            [routerLink]="disableButton ? null : '/showFields'"
            [routerLinkActive]="disableButton ? 'is_disabled' : ''"
            >Show Fields</a
          >
        </li>
      </ul>
    </div>
  </div>
  <div class="col-md-12 mb-5" style="padding-left: 0px; padding-right: 0px">
    <form action="." method="POST" #form="ngForm" (ngSubmit)="save(form.value)">
      <div class="panel">
        <div class="panel-header">
          <h3 class="panel-title">General</h3>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <label class="control-label" for="id_name">Plural Name</label
            ><input
              type="text"
              name="name"
              title="How the UI should refer to this layer as a whole."
              required=""
              id="id_name"
              maxlength="255"
              placeholder="Plural Name"
              class="form-control"
              required
              [(ngModel)]="product.name"
              #name="ngModel"
            />
            <span
              class="text-danger"
              *ngIf="(name.touched || form.submitted) && name.errors?.required"
            >
              Name is required
            </span>
            <div class="help-block">
              How the UI should refer to this layer as a whole.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_typename">Singular Name</label
            ><input
              type="text"
              name="typename"
              title="How the UI should refer to a single feature in this layer."
              required=""
              id="id_typename"
              maxlength="255"
              placeholder="Singular Name"
              class="form-control"
              required
              [(ngModel)]="product.typename"
              #typename="ngModel"
            />
            <span
              class="text-danger"
              *ngIf="
                (typename.touched || form.submitted) &&
                typename.errors?.required
              "
            >
              Singular Name is required
            </span>
            <div class="help-block">
              How the UI should refer to a single feature in this layer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_select_order"
              >Geometry Type</label
            ><select
              name="select_order"
              id="id_select_order"
              class="form-control"
              required
              [(ngModel)]="product.select_order"
              #select_order="ngModel"
              title="The geometry type of this layer (used when selecting features)."
            >
              <option value="7">Marker</option>

              <option value="3" selected="">Point</option>

              <option value="2">Line</option>

              <option value="1">Polygon</option>

              <option value="6">MultiPoint</option>

              <option value="5">MultiLine</option>

              <option value="4">MultiPolygon</option>
            </select>
            <span
              class="text-danger"
              *ngIf="
                (select_order.touched || form.submitted) &&
                select_order.errors?.required
              "
            >
              Geometry Type is required
            </span>
            <div class="help-block">
              The geometry type of this layer (used when selecting features).
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_zoom">Zoom</label
            ><input
              type="number"
              name="zoom"
              value="6"
              required
              min="0"
              title="Enable Layer At (x) Zoom Level."
              id="id_zoom"
              placeholder="Zoom"
              class="form-control"
              [(ngModel)]="product.zoom"
              #zoom="ngModel"
            />
            <span
              class="text-danger"
              *ngIf="(zoom.touched || form.submitted) && zoom.errors?.required"
            >
              Zoom is required
            </span>
            <div class="help-block">Enable Layer At (x) Zoom Level.</div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_visibility">Visible To</label
            ><select
              name="visibility"
              id="id_visibility"
              class="form-control"
              required
              title="The minimum privilege required to see this layer."
              [(ngModel)]="product.visibility"
              #visibility="ngModel"
            >
              <option value="-999">Deleted</option>

              <option value="0">Public</option>

              <option value="100">Client</option>

              <option value="200" selected="">Reader</option>

              <option value="300">Editor</option>

              <option value="400">Admin</option>

              <option value="500">Demigod</option>

              <option value="999">Nobody</option>
            </select>
            <span
              class="text-danger"
              *ngIf="
                (visibility.touched || form.submitted) &&
                visibility.errors?.required
              "
            >
              Visible To is required
            </span>
            <div class="help-block">
              The minimum privilege required to see this layer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_selectability"
              >Selectable By</label
            ><select
              name="selectability"
              id="id_selectability"
              class="form-control"
              required
              title="The minimum privilege required to select this layer."
              [(ngModel)]="product.selectability"
              #selectability="ngModel"
            >
              <option value="-999">Deleted</option>

              <option value="0">Public</option>

              <option value="100">Client</option>

              <option value="200" selected="">Reader</option>

              <option value="300">Editor</option>

              <option value="400">Admin</option>

              <option value="500">Demigod</option>

              <option value="999">Nobody</option>
            </select>
            <span
              class="text-danger"
              *ngIf="
                (selectability.touched || form.submitted) &&
                selectability.errors?.required
              "
            >
              Selectable By is required
            </span>
            <div class="help-block">
              The minimum privilege required to select this layer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_editability">Editable By</label
            ><select
              name="editability"
              id="id_editability"
              class="form-control"
              required
              title="The minimum privilege required to edit this layer."
              [(ngModel)]="product.editability"
              #editability="ngModel"
            >
              <option value="-999">Deleted</option>

              <option value="0">Public</option>

              <option value="100">Client</option>

              <option value="200">Reader</option>

              <option value="300">Editor</option>

              <option value="400">Admin</option>

              <option value="500">Demigod</option>

              <option value="999" selected="">Nobody</option>
            </select>
            <span
              class="text-danger"
              *ngIf="
                (editability.touched || form.submitted) &&
                editability.errors?.required
              "
            >
              Editable By is required
            </span>
            <div class="help-block">
              The minimum privilege required to edit this layer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_tool_tip">Tool Tip</label
            ><input
              type="text"
              name="tool_tip"
              title="Layer tool tip."
              id="id_tool_tip"
              maxlength="255"
              placeholder="Tool Tip"
              class="form-control"
              [(ngModel)]="product.tool_tip"
            />

            <div class="help-block">Layer tool tip.</div>
          </div>

          <div class="form-group">
            <div class="checkbox">
              <label
                for="id_limit_export"
                title="To put limit on the export data i.e PDF,csv"
                ><input
                  type="checkbox"
                  name="limit_export"
                  required
                  id="id_limit_export"
                  [(ngModel)]="product.limit_export"
                  #limit_export="ngModel"
                />
                Limit export</label
              >
            </div>
            <span
              class="text-danger"
              *ngIf="
                (limit_export.touched || form.submitted) &&
                limit_export.errors?.required
              "
            >
              Limit export is required
            </span>
            <div class="help-block">
              To put limit on the export data i.e PDF,csv
            </div>
          </div>

          <div class="form-group">
            <div class="checkbox">
              <label
                for="id_automatic_refresh"
                title="To automatic refresh of the layer."
                ><input
                  type="checkbox"
                  name="automatic_refresh"
                  required
                  id="id_automatic_refresh"
                  [(ngModel)]="product.automatic_refresh"
                  #automatic_refresh="ngModel"
                />
                Automatic refresh</label
              >
            </div>
            <span
              class="text-danger"
              *ngIf="
                (automatic_refresh.touched || form.submitted) &&
                automatic_refresh.errors?.required
              "
            >
              Automatic refresh is required
            </span>
            <div class="help-block">To automatic refresh of the layer.</div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_automatic_refresh_time"
              >Automatic refresh time</label
            ><input
              type="number"
              name="automatic_refresh_time"
              min="0"
              title="Time of automatic refresh of the layer."
              id="id_automatic_refresh_time"
              placeholder="Automatic refresh time"
              class="form-control"
              [(ngModel)]="product.automatic_refresh_time"
            />

            <div class="help-block">
              Time of automatic refresh of the layer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_data_channel"
              >Data Channel</label
            ><select
              name="data_channel"
              id="id_data_channel"
              class="form-control"
              title="To get live data source e.g. Lightning"
              [(ngModel)]="product.data_channel"
            >
              <option value="" selected="">----------------</option>

              <option value="lightning-get_data">Live Lightning Channel</option>

              <option value="demo-get_demo">Demo Lightning Channel</option>
            </select>

            <div class="help-block">To get live data source e.g. Lightning</div>
          </div>

          <div class="form-group">
            <div class="checkbox">
              <label
                for="id_capture"
                title="To add or edit map layer features and data"
                ><input
                  type="checkbox"
                  name="capture"
                  [(ngModel)]="product.capture"
                  id="id_capture"
                  required
                  #capture="ngModel"
                />
                Active Capture Tool</label
              >
            </div>
            <span
              class="text-danger"
              *ngIf="
                (capture.touched || form.submitted) && capture.errors?.required
              "
            >
              Active Capture Tool is required
            </span>
            <div class="help-block">
              To add or edit map layer features and data
            </div>
          </div>

          <div class="form-group">
            <div class="checkbox">
              <label
                for="id_is_parcel_layer"
                title="To display labels on parcels."
                ><input
                  type="checkbox"
                  name="is_parcel_layer"
                  required
                  id="id_is_parcel_layer"
                  [(ngModel)]="product.is_parcel_layer"
                  #is_parcel_layer="ngModel"
                />
                Is parcel layer</label
              >
            </div>
            <span
              class="text-danger"
              *ngIf="
                (is_parcel_layer.touched || form.submitted) &&
                is_parcel_layer.errors?.required
              "
            >
              Is parcel layer is required
            </span>
            <div class="help-block">To display labels on parcels.</div>
          </div>
        </div>
      </div>
      <div class="form-group mt-3 mb-3" style="float: right" *ngIf="editLayer">
        <button mat-stroked-button style="margin-right: 5px">
          <mat-icon>cancel</mat-icon>
          Cancel
        </button>

        <button mat-flat-button class="save" type="submit">
          <mat-icon>save</mat-icon>
          Save
        </button>
      </div>
      <div class="panel" [ngClass]="editLayer ? 'mt-4' : 'mt-5'">
        <div class="panel-header">
          <h3 class="panel-title">Legend</h3>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <label class="control-label" for="id_heading">Layer Group</label
            ><input
              type="text"
              name="heading"
              title="The Layer Group that should include this layer in the layers panel."
              id="id_heading"
              maxlength="255"
              placeholder="Layer Group"
              class="form-control"
              [(ngModel)]="product.heading"
            />

            <div class="help-block">
              The Layer Group that should include this layer in the layers
              panel.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_heading_tooltip"
              >Layer Group Tooltip</label
            ><input
              type="text"
              name="heading_tooltip"
              title="Tooltip of layer group."
              id="id_heading_tooltip"
              maxlength="255"
              placeholder="Layer Group Tooltip"
              class="form-control"
              [(ngModel)]="product.heading_tooltip"
            />

            <div class="help-block">Tooltip of layer group.</div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_class_group">Class group</label
            ><select
              name="class_group"
              id="id_class_group"
              class="form-control"
              [(ngModel)]="product.class_group"
              title="The Class Group that should include this layer in the layers panel."
            >
              <option value="" selected="">---------</option>

              <option value="6:1">6:1</option>

              <option value="Rivers and Streams">Rivers and Streams</option>

              <option value="2:1">2:1</option>

              <option value="10:1">10:1</option>

              <option value="Vacant">Vacant</option>

              <option value="Canals and Ditches">Canals and Ditches</option>

              <option value="Fire Hazard Severity Zones">
                Fire Hazard Severity Zones
              </option>

              <option value="Utilization">Utilization</option>

              <option value="Coverage">Coverage</option>
            </select>

            <div class="help-block">
              The Class Group that should include this layer in the layers
              panel.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_map_order">Display Order</label
            ><input
              type="number"
              name="map_order"
              value="1"
              required
              min="0"
              title="Where this layer should draw with regards to other layers; higher numbers are on top."
              id="id_map_order"
              placeholder="Display Order"
              class="form-control"
              [(ngModel)]="product.map_order"
              #map_order="ngModel"
            />
            <span
              class="text-danger"
              *ngIf="
                (map_order.touched || form.submitted) &&
                map_order.errors?.required
              "
            >
              Display Order is required
            </span>
            <div class="help-block">
              Where this layer should draw with regards to other layers; higher
              numbers are on top.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_layer_type">Layer Type</label
            ><select
              name="layer_type"
              id="id_layer_type"
              class="form-control"
              required
              [(ngModel)]="product.layer_type"
              #layer_type="ngModel"
              title='Base layers will always appear under the "Base" heading, and will receive radio buttons instead of check boxes.'
            >
              <option value="overlay" selected="">Regular Layer</option>

              <option value="basemap">Base Layer</option>
            </select>
            <span
              class="text-danger"
              *ngIf="
                (layer_type.touched || form.submitted) &&
                layer_type.errors?.required
              "
            >
              Display Order is required
            </span>
            <div class="help-block">
              Base layers will always appear under the "Base" heading, and will
              receive radio buttons instead of check boxes.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_unique_group"
              >Unique Group</label
            ><input
              type="text"
              name="unique_group"
              title="If provided, makes this layer exclusive with respect to other members of the same group."
              id="id_unique_group"
              maxlength="255"
              placeholder="Unique Group"
              class="form-control"
              [(ngModel)]="product.unique_group"
            />

            <div class="help-block">
              If provided, makes this layer exclusive with respect to other
              members of the same group.
            </div>
          </div>

          <div class="form-group">
            <div class="checkbox">
              <label
                for="id_visible_by_default"
                title="Whether or not this layer is visible when the map loads."
                ><input
                  type="checkbox"
                  name="visible_by_default"
                  required
                  id="id_visible_by_default"
                  [(ngModel)]="product.visible_by_default"
                  #visible_by_default="ngModel"
                />
                Visible by Default</label
              >
            </div>
            <span
              class="text-danger"
              *ngIf="
                (visible_by_default.touched || form.submitted) &&
                visible_by_default.errors?.required
              "
            >
              Visible by Defaultd is required
            </span>
            <div class="help-block">
              Whether or not this layer is visible when the map loads.
            </div>
          </div>

          <div class="form-group">
            <div class="checkbox">
              <label
                for="id_show_in_legend"
                title="Whether or not this layer appears in the layers panel."
                ><input
                  type="checkbox"
                  name="show_in_legend"
                  checked=""
                  required
                  id="id_show_in_legend"
                  [(ngModel)]="product.show_in_legend"
                  #show_in_legend="ngModel"
                />
                Show in Legend</label
              >
            </div>
            <span
              class="text-danger"
              *ngIf="
                (show_in_legend.touched || form.submitted) &&
                show_in_legend.errors?.required
              "
            >
              Show in Legend is required
            </span>
            <div class="help-block">
              Whether or not this layer appears in the layers panel.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_icon_url">Icon URL</label
            ><input
              type="text"
              name="icon_url"
              title="If supplied, the URL to use instead of GeoServer's default icon."
              id="id_icon_url"
              maxlength="255"
              placeholder="Icon URL"
              class="form-control"
              [(ngModel)]="product.icon_url"
            />

            <div class="help-block">
              If supplied, the URL to use instead of GeoServer's default icon.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mt-3 mb-3" style="float: right" *ngIf="editLayer">
        <button
          mat-stroked-button
          style="margin-right: 5px"
          (click)="backToMain()"
        >
          <mat-icon>cancel</mat-icon>
          Cancel
        </button>

        <button mat-flat-button class="save" type="submit">
          <mat-icon>save</mat-icon>
          Save
        </button>
      </div>
      <div class="panel" [ngClass]="editLayer ? 'mt-4' : 'mt-5'">
        <div class="panel-header">
          <h3 class="panel-title">Map Server</h3>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <label class="control-label" for="id_map_server">Server URL</label
            ><input
              type="text"
              name="map_server"
              value="https://geoserver.mapport.com/geoserver/wms"
              title="The map server URL; http://[1-5].mapport.net/tiles/wms.php for GeoServer."
              required
              id="id_map_server"
              maxlength="255"
              placeholder="Server URL"
              class="form-control"
              #map_server="ngModel"
              [(ngModel)]="product.map_server"
            />
            <span
              class="text-danger"
              *ngIf="
                (map_server.touched || form.submitted) &&
                map_server.errors?.required
              "
            >
              Server URL is required
            </span>
            <div class="help-block">
              The map server URL; http://[1-5].mapport.net/tiles/wms.php for
              GeoServer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_map_name">Map ID</label
            ><input
              type="text"
              name="map_name"
              title="How to identify this layer on the server; workspace:layer for GeoServer."
              required
              id="id_map_name"
              maxlength="255"
              placeholder="Map ID"
              class="form-control"
              [(ngModel)]="product.map_name"
              #map_name="ngModel"
            />
            <span
              class="text-danger"
              *ngIf="
                (map_name.touched || form.submitted) &&
                map_name.errors?.required
              "
            >
              Map ID is required
            </span>
            <div class="help-block">
              How to identify this layer on the server; workspace:layer for
              GeoServer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_map_params"
              >Map Parameters</label
            ><input
              type="text"
              name="map_params"
              title="add parameters to sql view; parameter:value for GeoServer."
              id="id_map_params"
              maxlength="255"
              placeholder="Map Parameters"
              class="form-control"
              [(ngModel)]="product.map_params"
            />

            <div class="help-block">
              add parameters to sql view; parameter:value for GeoServer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_map_format">Format</label
            ><select
              name="map_format"
              id="id_map_format"
              class="form-control"
              required
              title="The tiling scheme used by this layer."
              [(ngModel)]="product.map_format"
              #map_format="ngModel"
            >
              <option value="WMS" selected="">Web Map Service (WMS)</option>

              <option value="XYZ">XYZ Tiles (i.e. MapBox)</option>

              <option value="WFS">Web Feature Service (WFS)</option>
            </select>
            <span
              class="text-danger"
              *ngIf="
                (map_format.touched || form.submitted) &&
                map_format.errors?.required
              "
            >
              Format is required
            </span>
            <div class="help-block">The tiling scheme used by this layer.</div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_map_srid">SRID</label
            ><select
              name="map_srid"
              id="id_map_srid"
              required
              class="form-control"
              [(ngModel)]="product.map_srid"
              #map_srid="ngModel"
              title="The spatial reference system to ask for when requesting tiles; 3857 for GeoServer."
            >
              <option value="EPSG:3857" selected="">EPSG:3857</option>

              <option value="EPSG:3857">EPSG:3857</option>

              <option value="EPSG_2955">EPSG:2955</option>

              <option value="EPSG_4326">EPSG:4326</option>

              <option value="EPSG_2163">EPSG:2163</option>
            </select>
            <span
              class="text-danger"
              *ngIf="
                (map_srid.touched || form.submitted) &&
                map_srid.errors?.required
              "
            >
              SRID is required
            </span>
            <div class="help-block">
              The spatial reference system to ask for when requesting tiles;
              3857 for GeoServer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_cors_keyword">CORS</label
            ><select
              name="cors_keyword"
              id="id_cors_keyword"
              class="form-control"
              [(ngModel)]="product.cors_keyword"
              title='Cross-origin resource sharing; "anonymous" for GeoServer.'
            >
              <option value="">Don't use CORS.</option>

              <option value="anonymous">Make anonymous CORS requests.</option>

              <option value="use-credentials">
                Make authenticated CORS requests.
              </option>
            </select>

            <div class="help-block">
              Cross-origin resource sharing; "anonymous" for GeoServer.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mt-3 mb-3" style="float: right" *ngIf="editLayer">
        <button
          mat-stroked-button
          style="margin-right: 5px"
          (click)="backToMain()"
        >
          <mat-icon>cancel</mat-icon>
          Cancel
        </button>

        <button mat-flat-button class="save" type="submit">
          <mat-icon>save</mat-icon>
          Save
        </button>
      </div>
      <div class="panel" [ngClass]="editLayer ? 'mt-4' : 'mt-5'">
        <div class="panel-header">
          <h3 class="panel-title">Database</h3>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <label class="control-label" for="id_sql_schema">Schema</label
            ><select
              class="form-control db-meta db-schema"
              name="sql_schema"
              [(ngModel)]="product.sql_schema"
            >
              <option></option>
              <option value="alameda">alameda</option>
              <option value="alert_system">alert_system</option>
              <option value="alert_system_email">alert_system_email</option>
              <option value="anderson_cdp">anderson_cdp</option>
              <option value="app_mapport">app_mapport</option>
              <option value="atlas">atlas</option>
              <option value="building_fp">building_fp</option>
              <option value="ca_county">ca_county</option>
              <option value="calfire_shu">calfire_shu</option>
              <option value="calfire_statewide">calfire_statewide</option>
              <option value="county_fire_districts">
                county_fire_districts
              </option>
              <option value="dixie_tracker">dixie_tracker</option>
              <option value="dt_codes">dt_codes</option>
              <option value="dt_reports">dt_reports</option>
              <option value="fema">fema</option>
              <option value="fire_victims">fire_victims</option>
              <option value="geocoverage">geocoverage</option>
              <option value="goes_data">goes_data</option>
              <option value="historic_fire_data">historic_fire_data</option>
              <option value="horning">horning</option>
              <option value="importdata">importdata</option>
              <option value="jfh">jfh</option>
              <option value="leininger">leininger</option>
              <option value="lmu_custom_map">lmu_custom_map</option>
              <option value="meu_custom_map">meu_custom_map</option>
              <option value="nationwide">nationwide</option>
              <option value="nhd_california">nhd_california</option>
              <option value="parcels">parcels</option>
              <option value="parcels_vacant">parcels_vacant</option>
              <option value="public">public</option>
              <option value="purple">purple</option>
              <option value="qgis_projects">qgis_projects</option>
              <option value="qgis_test">qgis_test</option>
              <option value="rajan_custom">rajan_custom</option>
              <option value="raster">raster</option>
              <option value="richvale">richvale</option>
              <option value="rsl">rsl</option>
              <option value="rsl_v">rsl_v</option>
              <option value="scsd">scsd</option>
              <option value="stateauditor">stateauditor</option>
              <option value="statewide">statewide</option>
              <option value="survey_manager">survey_manager</option>
              <option value="tehama_opengov">tehama_opengov</option>
              <option value="tehama_pd">tehama_pd</option>
              <option value="tehama_pd_wksp">tehama_pd_wksp</option>
              <option value="tehamacity">tehamacity</option>
              <option value="tehamaschools">tehamaschools</option>
              <option value="tgu_custom_map">tgu_custom_map</option>
              <option value="topology">topology</option>
              <option value="traillabs">traillabs</option>
              <option value="vaisala">vaisala</option>
              <option value="vca_hospitals">vca_hospitals</option>
              <option value="wildfirealertviewer">wildfirealertviewer</option>
              <option value="wildfireviewer">wildfireviewer</option>
              <option value="yoni">yoni</option>
              <option value="z_scripts">z_scripts</option>
              <option value="z_temp">z_temp</option>
            </select>

            <div class="help-block">
              The database schema that contains this layer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_sql_table">Table</label
            ><select
              class="form-control db-meta db-table"
              name="sql_table"
              [(ngModel)]="product.sql_table"
            >
              <option></option>
              <option value="alert_job_settings">alert_job_settings</option>
              <option value="alert_job_settings">alameda_dt</option>
              <option value="goes_12h_vca">goes_12h_vca</option>
              <option value="goes_2h_vca">goes_2h_vca</option>
              <option value="goes_5m_vca">goes_5m_vca</option>
              <option value="landcover_class_ca_hotspot_test">
                landcover_class_ca_hotspot_test
              </option>
              <option value="viirs_12h_vca">viirs_12h_vca</option>
              <option value="viirs_12h_vca_fcollectgeom">
                viirs_12h_vca_fcollectgeom
              </option>
              <option value="viirs_near_shasta_co">viirs_near_shasta_co</option>
            </select>

            <div class="help-block">
              The database table that contains this layer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_sql_id_field">ID Column</label
            ><select
              class="form-control db-meta db-column"
              name="sql_id_field"
              [(ngModel)]="product.sql_id_field"
            >
              <option></option>
              <option value="description">description</option>
              <option value="email">email</option>
              <option value="event_source">event_source</option>
              <option value="frequency">frequency</option>
              <option value="id">id</option>
              <option value="lookback_interval">lookback_interval</option>
              <option value="name">name</option>
              <option value="proximity_miles">proximity_miles</option>
              <option value="recipients">recipients</option>
              <option value="target_source">target_source</option>
              <option value="user_id">user_id</option>
            </select>

            <div class="help-block">
              A column that can be used to uniquely identify features in this
              layer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_sql_name_field"
              >Name Column</label
            ><select
              class="form-control db-meta db-column"
              name="sql_name_field"
              [(ngModel)]="product.sql_name_field"
            >
              <option></option>
              <option value="description">description</option>
              <option value="email">email</option>
              <option value="event_source">event_source</option>
              <option value="frequency">frequency</option>
              <option value="id">id</option>
              <option value="lookback_interval">lookback_interval</option>
              <option value="name">name</option>
              <option value="proximity_miles">proximity_miles</option>
              <option value="recipients">recipients</option>
              <option value="target_source">target_source</option>
              <option value="user_id">user_id</option>
            </select>

            <div class="help-block">
              A column that can be used as a human-friendly name for these
              features.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_sql_geometry_field"
              >Geometry Column</label
            ><select
              class="form-control db-meta db-column"
              name="sql_geometry_field"
              [(ngModel)]="product.sql_geometry_field"
            >
              <option></option>
              <option value="description">description</option>
              <option value="email">email</option>
              <option value="event_source">event_source</option>
              <option value="frequency">frequency</option>
              <option value="id">id</option>
              <option value="lookback_interval">lookback_interval</option>
              <option value="name">name</option>
              <option value="proximity_miles">proximity_miles</option>
              <option value="recipients">recipients</option>
              <option value="target_source">target_source</option>
              <option value="user_id">user_id</option>
            </select>

            <div class="help-block">
              The column that contains the positional information for these
              features.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_sql_touch_field"
              >Timestamp Column</label
            ><select
              class="form-control db-meta db-column"
              name="sql_touch_field"
              [(ngModel)]="product.sql_touch_field"
            >
              <option></option>
              <option value="description">description</option>
              <option value="email">email</option>
              <option value="event_source">event_source</option>
              <option value="frequency">frequency</option>
              <option value="id">id</option>
              <option value="lookback_interval">lookback_interval</option>
              <option value="name">name</option>
              <option value="proximity_miles">proximity_miles</option>
              <option value="recipients">recipients</option>
              <option value="target_source">target_source</option>
              <option value="user_id">user_id</option>
            </select>

            <div class="help-block">
              If provided, a timestamp column to be updated whenever a feature
              is modified.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mt-5 mb-3" style="float: right">
        <button
          mat-stroked-button
          style="margin-right: 5px"
          (click)="backToMain()"
        >
          <mat-icon>cancel</mat-icon>
          Cancel
        </button>

        <button mat-flat-button class="save" type="submit">
          <mat-icon>save</mat-icon>
          Save
        </button>
      </div>
    </form>
  </div>
</div>
<!-- <app-geo-server-styling></app-geo-server-styling>
<app-layer-pre-view></app-layer-pre-view> -->
