import { SnackbarService } from './../../../../../services/snackbarService/snackbar.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { pdfTabel } from 'src/app/modules/shared/models/pdfTable/pdfTable';
import { TabItem } from 'src/app/modules/shared/models/tab/tab.model';
import { AuthService } from 'src/app/services/auth.service';
import { LayerService } from 'src/app/services/layer/layer.service';
import { PdfTableService } from 'src/app/services/pdfTable/pdf-table.service';
import { TabService } from 'src/app/services/tab/tab.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-pdf-table',
  templateUrl: './pdf-table.component.html',
  styleUrls: ['./pdf-table.component.css']
})
export class PdfTableComponent implements OnInit {
  disableButton = false
  user: any;
  tabs: TabItem[] | undefined;
  pdfTableData: pdfTabel = new pdfTabel();
  product: any;
  editTable =false
  on = true;
  off = false;
  layerName!: string
  constructor(
    private authService: AuthService,
     public router: Router,
     public tabService: TabService,
     public pdfTable: PdfTableService,
     public _snackbar: SnackbarService,
     public layerService: LayerService,
  ) { }

  ngOnInit(): void {
    this.layerService.layerdata = localStorage.getItem("layerData");
    if (this.layerService.layerdata) {
      this.product = JSON.parse(this.layerService.layerdata)
      this.pdfTableData.layer_id_tabel = this.product.id
      this.layerName = this.product.name
      this.pdfTableData.map_id_tabel = null
      this.pdfTable.getPdfTableById(this.pdfTableData.layer_id_tabel).pipe(first()).subscribe({
        next: (res: any) => {
         this.pdfTableData = res
         if(this.pdfTableData.visibility === true) {
           this.on = true
         } else {
          this.off = false
         }
         if(this.pdfTableData.owner_name !== '') {
          this.editTable = true
         }

        },
        error: error => {
          this._snackbar.openSnackBar('Something went wrong')
        }
      })
    }

  }

  submitForm(): void {
    this.layerService.layerdata = localStorage.getItem("layerData");
    if (this.layerService.layerdata) {
      this.product = JSON.parse(this.layerService.layerdata)
      this.pdfTableData.layer_id_tabel = this.product.id
    }
    if(this.editTable) {
      this.pdfTable.editPdfTable(this.pdfTableData.id, this.pdfTableData).pipe(first()).subscribe({
        next: () => {
          this._snackbar.openSnackBar('Pdf Tabel update successfully')
          // this.router.navigate(['/']);
        },
        error: error => {
          this._snackbar.openSnackBar('Something went wrong')
        }
      })
    } else {
      this.pdfTable.createPdfTable(this.pdfTableData).pipe(first()).subscribe({
        next: () => {
          this._snackbar.openSnackBar('Pdf Tabel Create successfully')
          // this.router.navigate(['/']);
        },
        error: error => {
          this._snackbar.openSnackBar('Something went wrong')
        }
      })
    }

  }


  logout() {
    this.authService.logout();
    window.location.href = 'https://opstage.mapport.com/login/';
  }
}
