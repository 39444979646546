<div class="main_wrapper" [style.width.px]="sidenavWidth">
  <!-- all layers dynamic content all in one + hydrography layers -->
  <div class="content" *ngIf="featureName">
    <ng-container *ngFor="let data of boundaries">
      <h3 *ngIf="resultLayer.toString() === data.id.toString()" style="background-size: 20px" [ngStyle]="{
          'background-image': isEmptyObject(data.style)
            ? 'url(' + data.legend.icon + ')'
            : 'url(' + data.style[sideNavService.currentLoadedMap].icon + ')'
        }">
        {{ data.typename }}
      </h3>
    </ng-container>
    <ul class="fields">
      <ul>
        <li *ngFor="let key of featureName.fields | keyvalue; index as i">
          <div [tooltip]="featureName.tool_tip[keys[i]]" [options]="myOptions">
            <div class="label">
              <span [style.font-size.px]="fontSize" class="span" *ngIf="
                  featureName.fields[keys[i]] !== 'Last Sale' &&
                  featureName.fields[keys[i]] !== 'owner_full' &&
                  featureName.fields[keys[i]] !== 'situs_full' &&
                  featureName.fields[keys[i]] !== 'apn_full' &&
                  featureName.fields[keys[i]] !== 'mail_full' &&
                  featureName.fields[keys[i]] !== 'size' &&
                  featureName.fields[keys[i]] !== 'yearbuilt' &&
                  featureName.fields[keys[i]] !== 'landvalue' &&
                  featureName.fields[keys[i]] !== 'structurev' &&
                  featureName.fields[keys[i]] !== 'totalvalue' &&
                  featureName.fields[keys[i]] !== 'currentdoc' &&
                  featureName.fields[keys[i]] !== 'currentd_1' &&
                  featureName.fields[keys[i]] !== 'usecode'
                ">{{ featureName.fields[keys[i]] }}</span>
              <span [style.font-size.px]="fontSize" class="span"
                *ngIf="featureName.fields[keys[i]] === 'Last Sale'">Deed Date</span>
              <span [style.font-size.px]="fontSize" class="span"
                *ngIf="featureName.fields[keys[i]] === 'owner_full'">Owner Full</span>
              <span [style.font-size.px]="fontSize" class="span"
                *ngIf="featureName.fields[keys[i]] === 'situs_full'">Situs Address</span>
              <span [style.font-size.px]="fontSize" class="span"
                *ngIf="featureName.fields[keys[i]] === 'apn_full'">APN</span>
              <span [style.font-size.px]="fontSize" class="span"
                *ngIf="featureName.fields[keys[i]] === 'mail_full'">Mailing Address</span>
              <span [style.font-size.px]="fontSize" class="span"
                *ngIf="featureName.fields[keys[i]] === 'size'">Approximate Size</span>
              <span [style.font-size.px]="fontSize" class="span"
                *ngIf="featureName.fields[keys[i]] === 'yearbuilt'">Year Built</span>
              <span [style.font-size.px]="fontSize" class="span"
                *ngIf="featureName.fields[keys[i]] === 'landvalue'">Land Value Assessed</span>
              <span [style.font-size.px]="fontSize" class="span"
                *ngIf="featureName.fields[keys[i]] === 'structurev'">Improvement Value Assessed</span>
              <span [style.font-size.px]="fontSize" class="span"
                *ngIf="featureName.fields[keys[i]] === 'totalvalue'">Total Value Assessed</span>
              <span [style.font-size.px]="fontSize" class="span"
                *ngIf="featureName.fields[keys[i]] === 'currentdoc'">Deed ID</span>
              <span [style.font-size.px]="fontSize" class="span"
                *ngIf="featureName.fields[keys[i]] === 'currentd_1'">Deed Date</span>
              <span [style.font-size.px]="fontSize" class="span" *ngIf="featureName.fields[keys[i]] === 'usecode'">Use
                Code</span>
            </div>

            <div [style.font-size.px]="fontSize" class="labelData color" *ngIf="featureName.data[keys[i]]"
              [innerHTML]="featureName.data[keys[i]]"></div>
            <div [style.font-size.px]="fontSize" class="labelData value" *ngIf="
                featureName.data[keys[i]] === '' ||
                featureName.data[keys[i]] === null
              ">
              [no data]
            </div>
          </div>
        </li>
      </ul>
      <ul></ul>
    </ul>
  </div>

  <!-- PrePlans Accesor Parcel -->
  <div style="height: auto; display: block" id="ap-feed-div" class="buttons st-current" *ngIf="featureName">
    <div style="position: relative; width: 100%" *ngIf="slug === 'tgu-fire' && showPerPlanOne" (mouseenter)="fire()"
      (mouseleave)="unHover()">
      <a [style.font-size.px]="fontSize" [tooltip]="prePlanToolTip" [options]="myOptions17" target="blank"
        href="{{ originUrl }}/api/media/{{ getPreplanOneData.pdf_document }}" class="mytooltip">View Preplan</a>
      <div *ngIf="showIcon">
        <span class="edit-preplan-one" (click)="prePlanEdit(getPreplanOneData.id)" [tooltip]="editPrePlan"
          [options]="myOptions17"><img style="margin-bottom: 10px" src="/assets/img/tools/edit2.png" /></span>
      </div>
    </div>

    <a [style.font-size.px]="fontSize" *ngIf="slug === 'tgu-fire' && showPerPlan" role="button"
      [tooltip]="prePlanToolTip" [options]="myOptions17" class="mytooltip preplan-multi" (click)="showAllPrePlan()">View
      Preplans</a>
    <a [style.font-size.px]="fontSize" *ngIf="titleStatusReport && showParcel" class="mytooltip ap-feed tooltip19"
      (click)="getAssessorParcelMore()">Title Information<span class="tooltiptext19"
        [innerHtml]="mapService.templatetoolTips['title_status_reports']"></span></a>

        
    <a [style.font-size.px]="fontSize" *ngIf="toolsMapFeed && showParcel" download href="javascript:void(0)"
      class="mytooltip ap-feed tooltip20" (click)="getAssessorParcel()">Assessor Parcel
      Map<span class="tooltiptext20" [innerHtml]="mapService.templatetoolTips['ap_map_feed']"></span></a>


    <div class="preplan-list" style="display: block" (click)="hideMenu()" *ngIf="menuVisible">
      <ng-container *ngFor="let prePlan of getPrePlanData; index as i">
        <ul>
          <li class="editPrePlan" style="
              position: relative;
              overflow: none !important;
              margin-bottom: 0px !important;
            ">
            <a target="blank" href="{{ originUrl }}/api/media/{{ prePlan.pdf_document }}">{{ prePlan.filename }}
            </a>
            <span class="edit-preplan-multi edit_preplan only-show-on-hover" (click)="prePlanEdit(prePlan.id)"
              [tooltip]="editPrePlan" [options]="myOptions17">
              <img src="/assets/img/tools/edit2.png" />
            </span>
          </li>
        </ul>
      </ng-container>
    </div>

  </div>
  <a id="sub" hidden download></a>
  <!-- buttons tools -->
  <div class="btn">
    <a class="closer button closerToolTip4" (click)="clickToZoom()">
      <img [style.right.px]="zoomRight" class="save-img common-btns tool-toolTip4" src="/assets/img/tools/zoom-to.png"
        alt="closer" /><span class="tooltiptext4" [innerHtml]="
          mapService.templatetoolTips['zoom_to_selection_right_panel']
        "></span></a>
    <a *ngIf="tools" class="closer button closerToolTip3" (click)="goToGoogle()">
      <img [style.right.px]="googleRight" class="google-img common-btns tool-toolTip3"
        src="/assets/img/tools/googlify.png" alt="back" /><span class="tooltiptext3" [innerHtml]="
          mapService.templatetoolTips['data_panel_google_street_view']
        "></span></a>
    <a class="closer button closerToolTip2" (click)="viewAndExportData()">
      <img [style.right.px]="viewRight" class="view_data common-btns tool-toolTip2" [ngClass]="{ view_data2: !tools }"
        src="/assets/img/pv/data_view_icon20X20.png" alt="back" /><span class="tooltiptext2"
        [innerHtml]="mapService.templatetoolTips['data_view']"></span></a>

    <a class="closer button closerToolTip1">
      <img [style.left.px]="backLeft" (click)="goBack()" class="back-img common-btns tool-toolTip1"
        src="/assets/img/tools/back-pv3.png" alt="back" /><span class="tooltiptext1" [style.left.px]="tooltip1"
        [innerHtml]="mapService.templatetoolTips['back_right_panel']"></span></a>
    <a class="closer button closerToolTip">
      <img [style.left.px]="closeLeft" (click)="closeRightpanel()" class="closer-img common-btns tool-toolTip"
        src="/assets/img/tools/close-pv3.png" alt="closer" /><span class="tooltiptext" [style.left.px]="tooltip2"
        [innerHtml]="mapService.templatetoolTips['close_right_panel']"></span></a>

  </div>
</div>

<ngx-spinner [fullScreen]="true" type="" size="large" bdColor="rgba(0, 0, 0, 0)">
  <img style="width: 100px" src="/assets/img/wheel.svg" alt="Loading..." width="100px" height="100px" />
</ngx-spinner>