<div class="tabs mt-5" style="margin-top: 50px;">
  <mat-tab-group
    mat-align-tabs="end"
    [selectedIndex]="6"
    animationDuration="0ms"
  >
    <ng-container *ngFor="let tab of tabs">
      <mat-tab>
        <ng-template mat-tab-label [dataProvider]="tab">
          <div class="mat-label-text" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </div>
        </ng-template>
        <ng-container *ngComponentOutlet="tab.component"></ng-container>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>
