export class showFields {
  id!: number;
  capture!: string
  length!: string
  dropdown!: boolean
  name!: string
  sql_name!: string
  type!: string
  units!: number
  map_order!: number
  visibility!: number
  searchability!: number
  editability!: number
  quicksearchable!: number
  autocomplete!: string
  tool_tip!: string
  layer!: number
}
