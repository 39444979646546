import { Component, OnDestroy, OnInit } from '@angular/core';

import WKT from 'ol/format/WKT';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import { getCenter } from 'ol/extent';

import { ViewAndExportDataComponent } from '../view-and-export-data/view-and-export-data.component';
import { MatDialog } from '@angular/material/dialog';
import { PrePlansEditComponent } from './pre-plans-edit/pre-plans-edit.component';

import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { AssessopParcelErrorComponent } from '../../../modals/assessop-parcel-error/assessop-parcel-error.component';
import { MapServiceService } from '../../../services/Map-service/map-service.service';
import { SideNavService } from '../../../services/sidenav/side-nav.service';
import { AssessorParcelsService } from '../../../services/Assessor-Parcels/assessor-parcels.service';
import { ShareLinkService } from '../../../services/Share-Link/share-link.service';
import { PrePlanService } from '../../../services/Pre Plan/pre-plan.service';
import * as CryptoJS from 'crypto-js';
import { PropertyReportsMenuComponent } from './property-reports-menu/property-reports-menu.component';
// import GeoJSON from 'ol/format/GeoJSON';
declare const google: any;
@Component({
  selector: 'app-feature-name',
  templateUrl: './feature-name.component.html',
  styleUrls: ['./feature-name.component.css'],
})
export class FeatureNameComponent implements OnInit, OnDestroy {
  myOptions = {
    showDelay: 500,
    'tooltip-class': 'new-tooltip-class-main',
  };

  featureName: any;
  keys: any = [];
  tlat: any;
  showParcel = false;
  tlng: any;
  zoom: any;
  showIcon = false;
  hoveredIndex = false;
  showPerPlanOne = false;
  eventName: any;
  logEvent: any;
  feature: any;
  getPrePlanData: any;
  zoomToExtend: any;
  feature1: any;
  googleFeature: any;
  mapId: any;
  hideZoomSelection = false;
  layerId: any;
  val: any;
  menuVisible = false;
  apn: any;
  parcelData: any;
  qlat: any;
  qlng: any;
  key: any;
  show = false;
  defaultView: any = false
  titleStatusReport: any = false
  toolsMapFeed: any = false
  geoLockTool: any;
  getPreplanOneData: any;
  resPrePlan: any;
  getPreplanData: any;
  resultLayer: any;
  extentData: any;
  prePlanData: any;
  editData: any;
  editId: any;
  boundaries: any;
  toolName: any;
  layerStyle: any;
  userLevel: any;
  decrypted: any;
  message = 'SuperSecret!!';
  slug: any;
  showGoogleIcon: any;
  tools: any;
  dataView: any;
  showPerPlan: any;
  originUrl: any;
  sidenavWidth: any = 200
  fontSize: any = 11
  zoomRight: any = 80
  googleRight: any = 50
  viewRight: any = 48
  closeLeft: any = 48
  backLeft: any = 80
  tooltip1: any = 68
  tooltip2: any = 93
  prePlanToolTip =
    '<span><strong>Preplan</strong><br>View, download and print the preplan PDF document associated with the selected parcel. <br>Browser pop-up blockers must be disabled.</span>';
  editPrePlan =
    '<span><strong>Edit preplan</strong><p>Allows entering new data into this preplan.</p></span>';
  myOptions11 = {
    showDelay: 500,
    'tooltip-class': 'new-tooltip-class',
  };
  myOptions17 = {
    showDelay: 500,
    'tooltip-class': 'new-tooltip-class',
  };

  preserveOrder: any = {};

  constructor(
    public sideNavService: SideNavService,
    public mapService: MapServiceService,
    public assessorParcelService: AssessorParcelsService,
    public prePlanService: PrePlanService,
    public shareLinkService: ShareLinkService,
    private dialog: MatDialog,
    private http: HttpClient,
    private spinner: NgxSpinnerService
  ) {
    this.menuVisible = false;

    this.decrypted = localStorage.getItem('userAccessLevel');
    if (this.decrypted) {
      const decrypted = CryptoJS.AES.decrypt(this.decrypted, this.message);
      this.userLevel = decrypted.toString(CryptoJS.enc.Utf8);
    }
  }

  ngOnInit(): void {
    this.mapService.hideHeader.subscribe((res: any) => {
      this.defaultView = res;
    });
    if (!this.mapService.panelSize) {
      const fontSize = 12
      switch (fontSize as any) {
        case '12':
          this.sidenavWidth = 200
          this.fontSize = 12
          this.zoomRight = 80
          this.googleRight = 50
          this.viewRight = 48
          this.closeLeft = 48
          this.backLeft = 80
          this.tooltip1 = 68
          this.tooltip2 = 93
          break;
        default:
          this.sidenavWidth = 200
          this.fontSize = 12
          this.zoomRight = 80
          this.googleRight = 50
          this.viewRight = 48
          this.closeLeft = 48
          this.backLeft = 80
          this.tooltip1 = 68
          this.tooltip2 = 93

      }
    } else {
      this.sidenavWidth = 200
      this.fontSize = 12
      this.zoomRight = 80
      this.googleRight = 50
      this.viewRight = 48
      this.closeLeft = 48
      this.backLeft = 80
      this.tooltip1 = 68
      this.tooltip2 = 93
    }

    this.sideNavService.setRightToolName('FeatureName');
    if (!this.sideNavService.clearFeatureList) {
      this.sideNavService.activeFeatureList = [];
      if (this.mapService.result) {
        this.mapService.result.getSource().clear();
        this.sideNavService.saveFeatureListData = [];
      }
    }
    this.sideNavService.clearFeatureList = false;
    this.sideNavService.sideNavState('open');
    this.originUrl = location.origin;
    this.boundaries = this.mapService.mapData.map_data.map_data.layers;
    this.sideNavService.clickedInfo(0);
    this.featureName = this.sideNavService.dataPassToRightPanel;
    this.slug = this.mapService.Mapslug;
    this.toolName = this.sideNavService.getToolName;
    this.tools =
      this.mapService.mapData.map_data.map_data.tools.data_panel_google_street_view;
    this.toolsMapFeed =
      this.mapService.mapData.map_data.map_data.tools.ap_map_feed;
    this.titleStatusReport =
      this.mapService.mapData.map_data.map_data.tools.title_status_reports;
    // this.dataView = this.mapService.mapData.map_data.map_data.tools.data-view;
    if (this.featureName) {
      this.boundaries = this.mapService.mapData.map_data.map_data.layers;
      for (let i = 0; i < this.boundaries.length; i++) {
        if (this.boundaries[i].name == this.featureName.Layer) {
          this.resultLayer = this.boundaries[i].id;
          break;
        } else if (this.boundaries[i].id == this.featureName.layer_id) {
          this.resultLayer = this.boundaries[i].id;
          break;
        }
      }

      //taking keys from api data
      // this.keys =Object.keys(this.featureName.fields)
      for (let i = 0; i < this.boundaries.length; i++) {
        if (!('is_annotation' in this.featureName)) {
          if (this.resultLayer == this.boundaries[i].id) {
            for (let j = 0; j < this.boundaries[i].fields.length; j++) {
              if (this.boundaries[i].fields[j].visible) {
                this.keys.push(this.boundaries[i].fields[j].id);
              }
            }
          }
        }
      }
      this.mapService.dataHistroy.push(this.featureName);
      this.layerId = this.featureName?.layer_id;
      for (let i = 0; i <= this.boundaries.length; i++) {
        if (
          (this.layerId == this.boundaries[i]?.id.toString() &&
            this.boundaries[i]?.is_parcel_layer == true )
        ) {
          this.apn = this.featureName.data[this.boundaries[i]?.nfid];
          this.mapService.getbufferShowFiles(true);
          this.mapService.isParcelLayer = true;
          if (this.slug === 'tgu-fire') {
            this.getPrePlan();
          }

          break;
        } else {
          this.mapService.getbufferShowFiles(false);
          this.mapService.isParcelLayer = false;
        }
      }
    }
    const f = this.featureName;
    const format = new WKT();
    this.feature = format.readFeature(f.ctrd_wkt);

    this.feature.set('type', 'point');
    const style = new Style({
      image: new Icon({
        src: '/assets/img/marker_resize.png',
        imgSize: [80, 150],
        offset: [-20, -37],
        scale: 1.0,
        opacity: 1.0,
      }),
    });
    this.feature.setStyle(style);
    this.mapService.stopHover = true;
    this.mapService.listResult.addFeatures([this.feature]);

    // this.mapService.result.getSource().clear();
    this.feature1 = format.readFeature(f.geom_wkt);
    this.feature1.set('type', 'polygon');

    const polystyle = new Style({
      stroke: new Stroke({
        color: 'red',
        width: 2,
      }),
    });
    const polystyle1 = new Style({
      fill: new Fill({
        color: 'rgb(0, 0, 0,0)',
      }),
    });

    for (let i = 0; i < this.mapService.selectedParcels.length; i++) {
      if (
        this.mapService.selectedParcels[i].get('number') ==
        this.featureName.id &&
        !this.sideNavService.fromAnnotation
      ) {
        this.mapService.selectedParcels[i].setStyle(new Style({}));
        this.mapService.selectedParcels[i].setStyle(polystyle1);
      }
    }

    this.feature1.setStyle(polystyle);
    this.mapService.selectedParcels.push(this.feature1);
    this.mapService.stopHover = true;
    this.mapService.listResult.addFeatures([this.feature1]);
    this.mapService.movendWork = true;
    if (this.mapService.searchResult) {
      this.mapService.searchResult = false
      this.mapService.mapLayer
        .getView()
        .fit(this.feature1.getGeometry().getExtent(), { maxZoom: 18 });
    }
    if (!this.mapService.setbookMarkZoom) {
      if (!this.sideNavService.setFeatureNameView) {
        this.mapService.mapLayer
          .getView()
          .setCenter(getCenter(this.feature1.getGeometry().getExtent()));
        if (this.mapService.isLiveShareLink === 'false') {
          this.mapService.mapLayer
            .getView()
            .fit(this.feature1.getGeometry().getExtent(), { maxZoom: 19 });
        }
      }
    }
    //reset prevent zoom value
    this.sideNavService.setFeatureNameView = false;
    for (let i = 0; i <= this.boundaries.length; i++) {
      if (
        this.boundaries[i]?.id == this.resultLayer &&
        (this.boundaries[i]?.is_parcel_layer == true )
      ) {
        this.showParcel = true;
        break;
      }
    }
    this.zoomToExtend = this.mapService.mapLayer.getView().getZoom();

    this.mapService.parcelKey.subscribe((res: any) => {
      this.key = res;
      // console.log('key', this.key);
      if (this.key === 'k') {
        if (this.showParcel) {
          this.getAssessorParcel();
        }
      } else if (this.key === 'z') {
        this.clickToZoom();
      } else if (this.key === 'n') {
        this.goToGoogle();
      }
    });

    // responds to control+z}
    this.mapService.isGeolock.subscribe((res: any) => {
      this.geoLockTool = res;
    });
    this.prePlanService.prePlanUpdate.subscribe((res: any) => {
      if (res) {
        this.getPrePlan();
      }
    });
  }

  closeRightpanel() {
    this.sideNavService.panelSize(true);
    this.sideNavService.closeRightSideNav();
    if (this.mapService.result) {
      this.mapService.result.getSource().clear();
    }
    localStorage.removeItem('seletedFeature');
    this.mapService.ParcelData = []
    this.mapService.polygonGeometry = ''
    this.mapService.pointGeometry = ''
    this.mapService.innerBuffer = ''
    this.mapService.parcel = ''
    this.mapService.featureLayerId = ''
  }

  clickToZoom() {
    const features = this.mapService.listResult.getFeatures();
    for (let i = 0; i < features.length; i++) {
      if (features[i].get('type') === 'polygon') {
        const extent = features[i].getGeometry().getExtent();
        this.mapService.mapLayer
          .getView()
          .fit(extent, this.mapService.mapLayer.getSize());
      }
    }
  }

  goBack() {
    this.sideNavService.isLoadedFeature('Featuresdasda');
    if (this.mapService.result) {
      this.mapService.result.getSource().clear();
    }
    this.sideNavService.maintainHistory();
  }

  goToGoogle() {
    this.qlat = '';
    this.qlng = '';
    this.zoom =
      this.mapService.mapLayer.getView().getZoom() +
      this.mapService.mapData.minzoom;
    const f = this.featureName;
    const format = new WKT();
    this.googleFeature = format.readFeature(f.ctrd_wkt);
    const geom = this.googleFeature.getGeometry();
    const temp: any = geom.transform('EPSG:3857', 'EPSG:4326');
    const oo = temp.getCoordinates();
    this.qlat = oo[1];
    this.qlng = oo[0];
    this.goToGoogleMaps(parseFloat(this.qlat), parseFloat(this.qlng));
  }
  goToGoogleMaps(tlat: any, tlng: any) {
    const sv = new google.maps.StreetViewService();
    sv.getPanoramaByLocation(
      new google.maps.LatLng(tlat, tlng),
      100,
      (data: any, status: any) => {
        if (status == 'OK') {
          const clat = data.location.latLng.lat();
          const clng = data.location.latLng.lng();
          const bearing =
            -90 - (Math.atan2(clat - tlat, clng - tlng) * 180) / Math.PI;
          const url =
            'https://maps.google.com/maps?q=' +
            tlat +
            ',' +
            tlng +
            '&cbll=' +
            clat +
            ',' +
            clng +
            '&layer=c&cbp=0,' +
            bearing +
            ',0,0,0';
          // window.location.href = url;
          window.open(url, '_blank');
        } else {
          // No StreetView image nearby...
          const url =
            'https://maps.google.com/maps?q=' +
            tlat +
            ',' +
            tlng +
            '&ll=' +
            tlat +
            ',' +
            tlng +
            '&z=' +
            (this.zoom || 16);
          // window.location.href = url;
          window.open(url, '_blank');
        }
      }
    );
  }

  viewAndExportData(): void {
    this.dialog.open(ViewAndExportDataComponent, {
      disableClose: true,
      data: {
        features: this.featureName,
      },
    });
  }
  getAssessorParcel() {
    if (!this.shareLinkService.shareUrlId) {
      this.shareLinkService.shareUrlId = ''
    }
    this.spinner.show();
    this.assessorParcelService
      .getAssessorParcel(this.mapService.featureLayerId, this.apn)
      .subscribe((res: any) => {
        if (res.error) {
          this.spinner.hide();
          this.dialog.open(AssessopParcelErrorComponent, {
            disableClose: true,
          });
        } else {

          setTimeout(() => {
            this.parcelData = res;
            this.spinner.hide();
            const url = `${this.sideNavService.downloadFileUrl}${this.parcelData.url}`;
            const element1: any =
              document.getElementById('sub');
            element1.href = url;
            element1.target = '_blank';
            element1.click();
            element1.href = '';
          }, 1000);
        }

      });
  }
  getAssessorParcelMore() {
    const dialogRef = this.dialog.open(PropertyReportsMenuComponent, {
      disableClose: true,
    });
  }

  hideMenu(): void {
    this.menuVisible = false;
  }

  prePlanEdit(id: any): void {
    this.showIcon = false;
    this.editId = id;
    this.mapService.editPrePlan(this.editId.toString()).subscribe((res) => {
      this.editData = res.preplan;
      if (!this.editData.site_plan_updated) {
        this.editData.site_plan_updated = '';
      }
      this.dialog.open(PrePlansEditComponent, {
        width: '60%',
        data: {
          id: this.editData.id,
          originalId: this.editData.original_id,
          apn: this.editData.apn,
          access: this.editData.access,
          filename: this.editData.filename,
          inspectionDate: this.editData.inspection_date,
          modifyDate: this.editData.modify_date,
          address: this.editData.address,
          pdfDocument: this.editData.pdf_document,
          battalion: this.editData.battalion,
          cType: this.editData.c_type,
          floorCons: this.editData.floor_cons,
          wallCons: this.editData.wall_cons,
          roofCons: this.editData.roof_cons,
          roofCover: this.editData.roof_cover,
          vertOpenings: this.editData.vert_openings,
          hvac: this.editData.hvac,
          elecShuttoff: this.editData.elec_shuttoff,
          gasShuttoff: this.editData.gas_shuttoff,
          waterShuttoff: this.editData.water_shuttoff,
          knox: this.editData.knox,
          alarmPanel: this.editData.alarm_panel,
          alarmReset: this.editData.alarm_reset,
          sprinkler: this.editData.sprinkler,
          fdcLoc: this.editData.fdc_loc,
          waterLoc: this.editData.water_loc,
          waterType: this.editData.water_type,
          sourceGpm: this.editData.source_gpm,
          exposures: this.editData.exposures,
          hazards: this.editData.hazards,
          staging: this.editData.staging,
          parcelType: this.editData.parcel_type,
          contact: this.editData.contact,
          dayPhone: this.editData.day_phone,
          hours: this.editData.hours,
          nightPhone: this.editData.night_phone,
          length: this.editData.length,
          width: this.editData.width,
          height: this.editData.height,
          specialResources: this.editData.special_resources,
          inspBy: this.editData.insp_by,
          sitePlan_updated: this.editData.site_plan_updated,
          nfpaRed: this.editData.nfpa_red,
          nfpaBlue: this.editData.nfpa_blue,
          nfpaYellow: this.editData.nfpa_yellow,
          nfpaWhite: this.editData.nfpa_white,
          fireFlow: this.editData.fire_flow,
          city: this.editData.city,
          route: this.editData.route,
          sitePlan: this.editData.site_plan,
          geom: this.editData.geom,
        },
      });
      this.showIcon = false;
    });
  }

  ngOnDestroy(): void {
    if (!this.sideNavService.newAnnoCreated) {
      //prevent feature destroy when new annotation is created
      this.sideNavService.feature = this.feature
      this.sideNavService.feature1 = this.feature1
      this.sideNavService.featureNameDataforAnnot = this.featureName
      if (this.feature) {
        this.feature.setStyle(new Style({}));
      }
      if (this.feature1) {
        this.feature1.setStyle(new Style({}));
      }
      const polystyle2 = new Style({
        fill: new Fill({
          color: 'rgb(255, 204, 51,0.3)',
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2,
        }),
      });
      for (let i = 0; i < this.mapService.selectedParcels.length; i++) {
        if (
          this.mapService.selectedParcels[i].get('number') ==
          this.featureName.id &&
          !this.sideNavService.fromAnnotation
        ) {
          this.sideNavService.fromAnnotation = false;
          this.mapService.selectedParcels[i].setStyle(polystyle2);
          // break;
        }
      }
    }
  }

  getPrePlan() {
    this.mapService.getPrePlan(this.apn.toString()).subscribe((res) => {
      if (res.status == false) {
        this.showPerPlan = false;
        this.showPerPlanOne = false;
      }
      if (res.status == 'one') {
        this.showPerPlanOne = true;
        this.getPreplanOneData = res;
      }
      if (res.status == 'multi') {
        this.showPerPlan = true;
        this.showPerPlanOne = false;
        this.getPreplanData = res.preplans;
      }
    });
  }
  showAllPrePlan() {
    this.menuVisible = true;
    this.getPrePlanData = this.getPreplanData;
  }

  hideZoomToSelection() {
    if (this.userLevel === '500') {
      if (this.slug === 'napa-county-parcel-viewer' && this.geoLockTool) {
        this.hideZoomSelection = true;
      }
    }
  }
  fire() {
    this.showIcon = true;
  }
  unHover() {
    this.showIcon = false;
  }


  isEmptyObject(obj: any) {
    return obj && Object.keys(obj).length === 0;
  }
}
