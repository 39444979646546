import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-assessop-parcel-error',
  templateUrl: './assessop-parcel-error.component.html',
  styleUrls: ['./assessop-parcel-error.component.css']
})
export class AssessopParcelErrorComponent implements OnInit {

  constructor(private dialog:MatDialog) {
    //
   }

  ngOnInit(): void {
    //
  }
  closeDialog(){
    this.dialog.closeAll()
  }
}
