import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import { MapServiceService } from '../../services/Map-service/map-service.service';
import * as CryptoJS from 'crypto-js';
import { MatSidenav } from '@angular/material/sidenav';
import { SideNavService } from '../../services/sidenav/side-nav.service';
import { addLayerData } from '../map/lightining-layer';
import { View } from 'ol';
import { TileWMS } from 'ol/source';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-legendpreview',
  templateUrl: './legendpreview.component.html',
  styleUrls: ['./legendpreview.component.css']
})
export class LegendpreviewComponent implements OnInit {
  @ViewChild('leftSidenav', { static: true }) public sidenav!: MatSidenav;
  @Input() openNav: boolean | undefined;
  token = '';
  slug = '';
  userInfo: any;
  embed: any;
  encrypted: any;
  message = 'SuperSecret!!';

  sidenavWidth = 100
  url: any;
  baseMaps: any;
  state = 'closed';
  leftState: any;
  boundaries: any;
  loadMap = false;
  infoLeftState: any;
  zoom: any;
  minZoom: any;
  id: any;
  userType: any;
  tools: any;
  showLegend: any;
  mapId: any;
  mapName: any;

  userAccessLevel: any;
  html: any;
  legendState: any;
  annotCheckboxState: any;
  sortDisplayedMap: any[] = [];
  refineLayersData: any = {};
  panelHandler: any = {};
  currentZoom: any;
  currentLoadedMap: any;
  logEvent: any;
  baseMapSmall: any;
  HideForEmbed = 'false';
  eventArea: any;
  eventName: any;
  base_Map: any;
  current_layer: any;
  objectKeys = Object.keys;
  annot: any;
  baseMapsState: any ;

  // ToolTips for multiple scenarios
  myOptions = {
    showDelay: 500,
  };
  myOptions1 = {
    showDelay: 500,
    'tooltip-class': 'new-tooltip-class',
  };
  myOptions2 = {
    showDelay: 500,
    'tooltip-class': 'new-tooltip-class2',
  };
  myOptions3 = {
    showDelay: 500,
    'tooltip-class': 'new-tooltip-class3',
  };
  windCheck: any;
  constructor(
    private route: ActivatedRoute,
    private global: GlobalService,
    public mapSerivce: MapServiceService,
    private auth: AuthService,
    public dialog: MatDialog,
    private router: Router,
    public sideNavService: SideNavService,
    private http: HttpClient,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    localStorage.clear();
    this.token = this.route.snapshot.paramMap.get('token') || '';
    this.slug = this.route.snapshot.paramMap.get('slug') || '';
    this.route.queryParamMap.subscribe((params) => {
      setTimeout(() => {
        this.mapSerivce.embed = params.get('embed');
      }, 1000);
    });
    this.spinner.show();
    this.mapSerivce.getClientInfo(this.token).subscribe(async (res: any) => {
      this.spinner.hide();
      this.userInfo = res;
      this.encrypted = await CryptoJS.AES.encrypt(
        this.userInfo.user.access_level.toString(),
        this.message
      );
      localStorage.setItem('userAccessLevel', this.encrypted);


      if (this.userInfo.user.login_directly === 'True') {
        this.auth.setToken(res);
        localStorage.setItem('redirect', 'true');
        this.mapAccess()
      } else {
        localStorage.setItem('redirect', 'false');
        this.auth.setToken(res);
        this.mapAccess()
      }
    });

  }

  mapAccess(): void {
    this.spinner.show();
    this.mapSerivce
      .getMapData(
        this.slug,
        'true',
        '',
        ''
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status !== 404) {
            if (res.map_data.interface.map_type == 'wv-map') {
              this.global.loadGoogleAnalytics('UA-97225393-4');
            } else if (res.map_data.interface.map_type == 'pv-map') {
              this.global.loadGoogleAnalytics('UA-97225393-3');
            }

            if (res?.map_data?.subscription_ending_date) {
              this.auth.expiryMap = res?.map_data?.subscription_ending_date
            }
            this.mapSerivce.setIcon(res.map_data.interface.map_logo_url);
            //for elements visiblilty
            this.mapSerivce.elementsVisibility =
              res.map_data.map_data.elements;
            this.mapSerivce.chat_display_message =
              res.map_data.map_data.chat_display_message;
            //wildfire damage viewer is blocked as per client modificition and check is added
            this.mapSerivce.mapAccessLevel = res.map_access_level;
            if (
              res.map_assessible == true
            ) {
              this.mapSerivce.mapData = res;
              this.mapId = this.mapSerivce.mapData.map_data.map_data.id;
              this.mapName = this.mapSerivce.mapData.map_data.map_data.name;
              this.mapSerivce.shareData =
                this.mapSerivce.mapData.map_data.share;
              if (
                this.mapSerivce.shareData.live_share &&
                !this.mapSerivce.shareData.expire
              ) {
                this.mapSerivce.bookMarkTool = true;
                this.mapSerivce.shareData.basic_data;
              }
              const originUrl = location.origin;
              const href = this.router.url;
              this.mapSerivce.pageUrl = originUrl.concat(href);
              this.mapSerivce.mapInterface = res.map_data.interface;
              this.mapSerivce.templatetoolTips =
                res.map_data.map_data.tools_tips;

              this.tools = res.map_data.map_data.tools;
              if (
                this.tools.left_panel &&
                res.map_data.share.live_share == false
              ) {
                this.showLegend = true;
              } else if (
                this.tools.left_panel &&
                res.map_data.share.show_legend == true
              ) {
                this.showLegend = true;
              }
              this.mapSerivce.accessMapApi = res;
              this.loadMap = true;
            }
          }
          this.legendData()
        },
        () => {
          //
        }
      );
  }

  legendData() {
    const event = { target: { checked: true } };
    const wind = document.getElementById('windyMap') as any;
    if (wind) {
      wind.style.display = 'none';
    }
    this.userAccessLevel = this.mapSerivce.mapAccessLevel;
    if (!this.mapSerivce.accessMapApi.map_data.map_data.tool_desc.layers) {
      this.html = '';
    } else {
      this.html =
        this.mapSerivce.accessMapApi.map_data.map_data.tool_desc.layers;
    }
    this.legendState = this.sideNavService.legendState;
    this.mapId = this.mapSerivce.mapData.map_data.map_data.id.toString();
    this.tools = this.mapSerivce.mapData.map_data.map_data.tools;

    //previous state for annot checkbox in legend
    if (!this.sideNavService.loadFirstTimeAnnot) {
      this.annotCheckboxState = this.sideNavService.legendAnnotCheck;
    } else {
      this.annotCheckboxState = {
        checked1: false,
        checked2: false,
        checked3: false,
      };
    }

    // console.log(this.annotCheckboxState )
    if (!this.sideNavService.sortedMaps) {
      this.baseMaps = this.mapSerivce.mapData.map_data.map_data.basemaps;
    } else {
      this.baseMaps = this.sideNavService.sortedMaps;
    }
    if (!this.sideNavService.currentLoadedMap) {
      for (let i = 0; i < this.baseMaps.length; i++) {
        if (
          this.baseMaps[i].status == 'default' ||
          this.baseMaps[i].name == this.mapSerivce.shareData.base_layer
        ) {
          this.sideNavService.currentLoadedMap = this.baseMaps[i].name;
          this.mapSerivce.baseLayerName = this.baseMaps[i].name;
          this.mapSerivce.shareCurrentMap = this.baseMaps[i].title;
          break;
        }
      }
    }
    //added new check for sorting layers in show order for same preview as in mapport legend
    this.mapSerivce.mapData.map_data.map_data.layers.sort((a: any, b: any) => {
      return a.show_order - b.show_order;
    });
    this.boundaries = this.mapSerivce.mapData.map_data.map_data.layers;

    // Handle panels to be open by default mapping
    if (!this.sideNavService.setLayerState) {
      for (const data of this.boundaries) {
        if (data.map.visible) {
          if (data.legend.class_group) {
            if (!(data.legend.class_group in this.panelHandler)) {
              this.panelHandler[data.legend.class_group] = true;
            }
          }
          // if (!data.legend.class_group) {
          if (!(data.legend.heading in this.panelHandler)) {
            this.panelHandler[data.legend.heading] = true;
          }
          // }
        }
        if (data.legend.class_group_checked) {
          if (!(data.legend.heading in this.panelHandler)) {
            this.panelHandler[data.legend.heading] = true;
          }
        }
      }
      this.sideNavService.setLayerState = this.panelHandler;
    } else {
      this.panelHandler = this.sideNavService.setLayerState;
    }

    // Handle panels to be open by default mapping
    //creating layers data according to our need for groups and subgroups
    for (let i = 0; i < this.boundaries.length; i++) {
      // Heading is  present && classgroup is not present && layers heading is present
      if (
        !this.boundaries[i].legend.class_group &&
        this.boundaries[i].legend.heading in this.refineLayersData &&
        'layers' in this.refineLayersData[this.boundaries[i].legend.heading]
      ) {
        if (
          this.boundaries[i].legend.heading == null ||
          this.boundaries[i].legend.heading == ''
        ) {
          this.boundaries[i].legend.heading = 'Overlays';
        }
        this.refineLayersData[this.boundaries[i].legend.heading].layers.push(
          this.boundaries[i]
        );

        // Heading is  present && classgroup is not present && layers heading is not present
      } else if (
        !this.boundaries[i].legend.class_group &&
        this.boundaries[i].legend.heading in this.refineLayersData &&
        !('layers' in this.refineLayersData[this.boundaries[i].legend.heading])
      ) {
        if (
          this.boundaries[i].legend.heading == null ||
          this.boundaries[i].legend.heading == ''
        ) {
          this.boundaries[i].legend.heading = 'Overlays';
        }
        this.refineLayersData[this.boundaries[i].legend.heading].layers = [];
        this.refineLayersData[this.boundaries[i].legend.heading].layers.push(
          this.boundaries[i]
        );
      }
      // Heading is  not present && classgroup is not present
      else if (
        !this.boundaries[i].legend.class_group &&
        !(this.boundaries[i].legend.heading in this.refineLayersData)
      ) {
        if (
          this.boundaries[i].legend.heading == null ||
          this.boundaries[i].legend.heading == ''
        ) {
          this.boundaries[i].legend.heading = 'Overlays';
        }
        this.refineLayersData[this.boundaries[i].legend.heading] = {};
        this.refineLayersData[this.boundaries[i].legend.heading].order =
          this.boundaries[i].show_order;
        this.refineLayersData[this.boundaries[i].legend.heading].layers = [];
        this.refineLayersData[this.boundaries[i].legend.heading].layers.push(
          this.boundaries[i]
        );
      }
      // Heading is not present && classgroup is present
      else if (
        this.boundaries[i].legend.class_group &&
        !(this.boundaries[i].legend.heading in this.refineLayersData)
      ) {
        if (
          this.boundaries[i].legend.heading == null ||
          this.boundaries[i].legend.heading == ''
        ) {
          this.boundaries[i].legend.heading = 'Overlays';
        }
        this.refineLayersData[this.boundaries[i].legend.heading] = {};
        this.refineLayersData[this.boundaries[i].legend.heading][
          this.boundaries[i].legend.class_group
        ] = [];
        this.refineLayersData[this.boundaries[i].legend.heading][
          this.boundaries[i].legend.class_group
        ].push(this.boundaries[i]);
      }
      // Heading is  present && classgroup is not present
      else if (
        !(
          this.boundaries[i].legend.class_group in
          this.refineLayersData[this.boundaries[i].legend.heading]
        ) &&
        this.boundaries[i].legend.heading in this.refineLayersData
      ) {
        if (this.boundaries[i].legend.heading == null) {
          this.boundaries[i].legend.heading = 'Overlays';
        }
        this.refineLayersData[this.boundaries[i].legend.heading][
          this.boundaries[i].legend.class_group
        ] = [];
        this.refineLayersData[this.boundaries[i].legend.heading][
          this.boundaries[i].legend.class_group
        ].push(this.boundaries[i]);
      }
      //heading is present and class group is present
      else if (
        this.boundaries[i].legend.class_group in
        this.refineLayersData[this.boundaries[i].legend.heading] &&
        this.boundaries[i].legend.heading in this.refineLayersData
      ) {
        if (
          this.boundaries[i].legend.heading == null ||
          this.boundaries[i].legend.heading == ''
        ) {
          this.boundaries[i].legend.heading = 'Overlays';
        }
        this.refineLayersData[this.boundaries[i].legend.heading][
          this.boundaries[i].legend.class_group
        ].push(this.boundaries[i]);
      }
    }

    //sorting layer data with order
    Object.keys(this.refineLayersData).sort((a, b) => {
      return this.refineLayersData[b].order - this.refineLayersData[a].order;
    });
    //sorting layer data with order

    //delete order key from layer after sort to get final data
    Object.keys(this.refineLayersData).map((a) => {
      delete this.refineLayersData[a].order;
    });

    // this.currentZoom = this.mapSerivce.mapLayer.getView().getZoom();
    // this.mapSerivce.getLayersId();

    // this.mapSerivce.mapLayer.on('moveend', () => {
    //   this.currentZoom = this.mapSerivce.mapLayer.getView().getZoom();
    //   this.mapSerivce.getLayersId();
    // });

    if (!this.sideNavService.sortedMaps) {
      for (let i = 0; i <= this.baseMaps.length; i++) {
        if (
          this.baseMaps[i]?.status == true ||
          this.baseMaps[i]?.status === 'default'
        ) {
          if (this.baseMaps[i]?.status === 'default') {
            this.baseMaps[i].activeMap = true;
          }
          this.sortDisplayedMap.push(this.baseMaps[i]);
        }
      }
      this.sortDisplayedMap.sort((a: any, b: any) => {
        return a.order - b.order;
      });
      this.sideNavService.sortedMaps = this.sortDisplayedMap;
    } else {

      this.sortDisplayedMap = this.sideNavService.sortedMaps;
    }
    this.sideNavService.sortMaps = this.sortDisplayedMap;
    this.mapSerivce.layerHeadingForbookMark.subscribe((res: any) => {
      if (res) {
        for (let i = 0; i < res.length; i++) {
          this.openBoundariesByHeading(res, res[i]);
        }
      }
    });
    this.mapSerivce.layerHeadingForbookMarkClose.subscribe((res: any) => {
      if (res) {
        for (let i = 0; i < res.length; i++) {
          this.closeBoundariesByHeading(res, res[i]);
        }
      }
    });
    this.mapSerivce.changeLayerClickToZoom.subscribe((res: any) => {
      for (let i = 0; i < res.length; i++) {
        this.onChangeMapLayer(event, res[i].headingTitle, 1, '', res[i].id);
      }
    });
  }

  openBoundariesBySubGroup(l: any, subHeading: any, heading: any) {
    this.logEvent = 'Active';
    this.eventName = subHeading;
    this.panelHandler[subHeading] = subHeading;
    const allLayers = this.mapSerivce.mapLayer.getLayers().getArray();

    for (let i = 0; i <= allLayers.length; i++) {
      if (
        allLayers[i]?.getProperties().type == heading &&
        this.panelHandler[heading]
      ) {
        if (
          allLayers[i]?.getProperties().classGroup == subHeading &&
          allLayers[i]?.getProperties().isVisible == true &&
          allLayers[i].getProperties().zoomlevel <= this.currentZoom
        ) {
          allLayers[i].setVisible(true);
          allLayers[i].set('checked', true);
          allLayers[i].setProperties({ isChecked: true });
        }
        if (
          !allLayers[i]?.getProperties().classGroup &&
          allLayers[i]?.getProperties().isVisible == true &&
          allLayers[i].getProperties().zoomlevel <= this.currentZoom
        ) {
          allLayers[i].setVisible(true);
          allLayers[i].set('checked', true);
          allLayers[i].setProperties({ isChecked: true });
        }
      }
      if (
        this.panelHandler[heading] &&
        allLayers[i]?.getProperties().title == 'Wind' &&
        allLayers[i]?.getProperties().title.toLowerCase().includes('wind') &&
        !allLayers[i]?.getProperties().classGroup &&
        allLayers[i]?.getProperties().isVisible == true &&
        allLayers[i]?.getProperties().type == heading &&
        allLayers[i].getProperties().zoomlevel <= this.currentZoom
      ) {
        const wind = document.getElementById('windyMap') as any;
        if (wind) {
          wind.style.display = 'block';
        }
        allLayers[i].set('checked', true);
        allLayers[i].setProperties({ isChecked: true });
      }
      if (
        this.panelHandler[heading] &&
        (allLayers[i]?.getProperties().title == 'Wind' ||
          allLayers[i]
            ?.getProperties()
            ?.title?.toLowerCase()
            ?.includes('wind')) &&
        allLayers[i]?.getProperties().classGroup == subHeading &&
        allLayers[i]?.getProperties().isVisible == true &&
        allLayers[i]?.getProperties().type == heading &&
        allLayers[i].getProperties().zoomlevel <= this.currentZoom
      ) {
        const wind = document.getElementById('windyMap') as any;
        if (wind) {
          wind.style.display = 'block';
        }
        allLayers[i].set('checked', true);
        allLayers[i].setProperties({ isChecked: true });
      }
    }
    //working as radio expansion panel
    let type: any;
    for (let i = 0; i <= allLayers.length; i++) {
      // console.log(allLayers[i]?.getProperties().classGroupType, 'tp' , allLayers[i]?.getProperties().title); zoom check all added
      if (
        allLayers[i]?.getProperties().type == heading &&
        allLayers[i]?.getProperties().classGroupType == 'radio' &&
        allLayers[i]?.getProperties().classGroup == subHeading
      ) {
        for (let j = 0; j <= allLayers.length; j++) {
          if (
            allLayers[j]?.getProperties().type == heading &&
            allLayers[j]?.getProperties().classGroup != subHeading &&
            allLayers[j]?.getProperties().classGroupType == 'radio'
          ) {
            type = allLayers[j]?.getProperties().classGroup;
            allLayers[j].setVisible(false);
            allLayers[j].set('checked', false);
            allLayers[j].setProperties({ isChecked: false });
          }
        }
      }
    }
    delete this.panelHandler[type];
  }
  closeBoundariesBySubGroup(l: any, subHeading: any) {
    this.logEvent = 'Deactive';
    this.eventName = subHeading;
    delete this.panelHandler[subHeading];
    const allLayers = this.mapSerivce.mapLayer.getLayers().getArray();
    for (let i = 0; i <= allLayers.length; i++) {
      if (
        allLayers[i]?.getProperties().classGroup == subHeading &&
        allLayers[i]?.getProperties().title != 'Wind' &&
        !allLayers[i]?.getProperties()?.title?.toLowerCase()?.includes('wind')
      ) {
        allLayers[i].setVisible(false);
        allLayers[i].set('checked', false);
        allLayers[i].setProperties({ isChecked: false });
      }
      if (
        (allLayers[i]?.getProperties().title == 'Wind' ||
          allLayers[i]
            ?.getProperties()
            ?.title?.toLowerCase()
            ?.includes('wind')) &&
        allLayers[i]?.getProperties().classGroup == subHeading
      ) {
        const wind = document.getElementById('windyMap') as any;
        if (wind) {
          wind.style.display = 'none';
        }
        allLayers[i].set('checked', false);
        allLayers[i].setProperties({ isChecked: false });
      }
    }
  }

  closeBoundariesByHeading(l: any, heading: any) {
    this.logEvent = 'Deactive';
    this.eventName = heading;
    delete this.panelHandler[heading];
    const allLayers = this.mapSerivce.mapLayer.getLayers().getArray();
    for (let i = 0; i <= allLayers.length; i++) {
      if (
        allLayers[i]?.getProperties().type == heading &&
        allLayers[i]?.getProperties().title != 'Wind' &&
        !allLayers[i]?.getProperties()?.title?.toLowerCase()?.includes('wind')
      ) {
        allLayers[i].setVisible(false);
        allLayers[i].set('checked', false);
        allLayers[i].setProperties({ isChecked: false });
      }
      if (
        (allLayers[i]?.getProperties().title == 'Wind' ||
          allLayers[i]
            ?.getProperties()
            ?.title?.toLowerCase()
            ?.includes('wind')) &&
        allLayers[i]?.getProperties().type == heading
      ) {
        const wind = document.getElementById('windyMap') as any;
        if (wind) {
          wind.style.display = 'none';
        }
        allLayers[i].set('checked', false);
        allLayers[i].setProperties({ isChecked: false });
      }
    }
  }

  openBoundariesByHeading(l: any, heading: any) {
    this.panelHandler[heading] = heading;
    this.logEvent = 'Active';
    this.eventName = heading;
    const allLayers = this.mapSerivce.mapLayer.getLayers().getArray();
    for (let i = 0; i <= allLayers.length; i++) {
      if (
        allLayers[i]?.getProperties().type == heading &&
        allLayers[i]?.getProperties().isVisible == true &&
        !allLayers[i]?.getProperties().classGroup &&
        allLayers[i]?.getProperties().title != 'Wind' &&
        !allLayers[i]
          ?.getProperties()
          ?.title?.toLowerCase()
          ?.includes('wind') &&
        allLayers[i].getProperties().zoomlevel <= this.currentZoom
      ) {
        allLayers[i].setVisible(true);
        allLayers[i].set('checked', true);
        allLayers[i].setProperties({ isChecked: true });
      }
      if (
        allLayers[i]?.getProperties().type == heading &&
        allLayers[i]?.getProperties().isVisible == true &&
        (allLayers[i]?.getProperties().title == 'Wind' ||
          allLayers[i]
            ?.getProperties()
            ?.title?.toLowerCase()
            ?.includes('wind')) &&
        !allLayers[i]?.getProperties().classGroup &&
        allLayers[i].getProperties().zoomlevel <= this.currentZoom
      ) {
        const wind = document.getElementById('windyMap') as any;

        wind.style.display = 'block';

        allLayers[i].set('checked', true);
        allLayers[i].setProperties({ isChecked: true });
      }
      if (
        allLayers[i]?.getProperties().type == heading &&
        (allLayers[i]?.getProperties().title == 'Wind' ||
          allLayers[i]
            ?.getProperties()
            ?.title?.toLowerCase()
            ?.includes('wind')) &&
        allLayers[i]?.getProperties().isVisible == true &&
        allLayers[i]?.getProperties().classGroup ==
        this.panelHandler[allLayers[i]?.getProperties().classGroup] &&
        allLayers[i].getProperties().zoomlevel <= this.currentZoom
      ) {
        const wind = document.getElementById('windyMap') as any;

        wind.style.display = 'block';

        allLayers[i].set('checked', true);
        allLayers[i].setProperties({ isChecked: true });
      }
      if (
        allLayers[i]?.getProperties().type == heading &&
        allLayers[i]?.getProperties().isVisible == true &&
        allLayers[i]?.getProperties().classGroup &&
        allLayers[i]?.getProperties().classGroup ==
        this.panelHandler[allLayers[i]?.getProperties().classGroup] &&
        allLayers[i]?.getProperties().title != 'Wind' &&
        !allLayers[i]
          ?.getProperties()
          ?.title?.toLowerCase()
          ?.includes('wind') &&
        allLayers[i].getProperties().zoomlevel <= this.currentZoom
      ) {
        //check change  !allLayers[i]?.getProperties().classGroup to  allLayers[i]?.getProperties().classGroup
        allLayers[i].setVisible(true);
        allLayers[i].set('checked', true);
        allLayers[i].setProperties({ isChecked: true });
      }
    }
  }

  onChangeBaseMap(e: any, name: any, title: any): void {
    this.base_Map = name;
    this.mapSerivce.setAnimate(this.base_Map);
    this.logEvent = 'Change';
    this.eventName = name;
    this.mapSerivce.mapLayer
      .getLayers()
      .getArray()
      .forEach((a: any) => {
        if (
          a.getProperties().title === this.base_Map &&
          a.getProperties().type == 'baseMaps'
        ) {
          a.getVisible();
          a.setVisible(true);
          if (a.getProperties().title === 'blank') {
            a.setSource(null);
          } else {
            a.getSource().changed();
          }

          addLayerData(
            this.mapSerivce.mapLayer,
            this.mapSerivce.currentMap,
            this.mapSerivce
          );
          this.sideNavService.currentLoadedMap = a.getProperties().title;
          this.mapSerivce.baseLayerName = a.getProperties().title;
          for (let i = 0; i < this.baseMaps.length; i++) {
            if (this.baseMaps[i].title == this.base_Map) {
              const properties = this.mapSerivce.mapLayer
                .getView()
                .getProperties();
              properties['maxZoom'] = this.baseMaps[i].zoom;
              properties['constrainResolution'] = true;
              properties['constrainOnlyCenter'] = true;
              properties['smoothResolutionConstraint'] = false;
              this.mapSerivce.mapLayer.setView(new View(properties));
            }
          }
        } else if (
          a.getProperties().title !== this.base_Map &&
          a.getProperties().type == 'baseMaps'
        ) {
          a.setVisible(false);
        }
      });
    this.mapSerivce.mapLayer
      .getLayers()
      .getArray()
      .forEach((a: any) => {
        if (a.values_.mapport) {
          if (a.getProperties().allType == 'layers') {
            for (const [key] of Object.entries(a.values_.mapport.style)) {
              if (key === title) {
                const workspace = a.values_.mapport.map.name.split(':');
                const server =
                  a.values_.mapport.map.name.indexOf(':') === -1
                    ? a.values_.mapport.map.server
                    : a.values_.mapport.map.server.replace(
                      'wms',
                      workspace[0] + '/wms'
                    );
                const style =
                  a.values_.mapport.style[title].workspace +
                  ':' +
                  a.values_.mapport.style[title].style;
                if (a.getProperties().title === 'Parcels') {
                  a.setSource(
                    new TileWMS({
                      url: server,
                      params: {
                        LAYERS: a.values_.mapport.map.name,
                        TILED:
                          a.values_.mapport.map.name.startsWith(
                            'wildfire_viewer'
                          ) === true
                            ? false
                            : true,
                        STYLES: style,
                        FORMAT: 'image/png',
                      },
                    })
                  );
                }
                a.getSource().refresh();
                a.getSource().changed();
              }
            }
          }
        }
      });
    for (let i = 0; i <= this.baseMaps?.length; i++) {
      if (this.baseMaps[i]?.name) {
        this.baseMaps[i].activeMap = false;
      }
    }

    for (let i = 0; i <= this.baseMaps.length; i++) {
      if (this.baseMaps[i]?.title.toLowerCase() == name.toLowerCase()) {
        this.baseMaps[i].activeMap = true;
        this.sideNavService.currentLoadedMap = this.baseMaps[i]?.name;
        this.mapSerivce.baseLayerName = this.baseMaps[i]?.name;
        this.mapSerivce.shareCurrentMap = this.baseMaps[i].title;
        break;
      }
    }
    // this.sortDisplayedMap.sort((a: any, b: any) => {
    //   return a.order - b.order;
    // });
    // this.sideNavService.sortedMaps = this.sortDisplayedMap;
    this.http
      .get('https://api.ipify.org/?format=json')
      .subscribe((res: any) => {
        this.mapSerivce.ipAddress = res.ip;
        const body = {
          map_id: this.mapId.toString(),
          event: 'Click',
          event_name: this.eventName,
          ip: this.mapSerivce.ipAddress,
          page: this.mapSerivce.pageUrl,
          basemap: this.mapSerivce.shareCurrentMap,
          time: this.mapSerivce.getTimeOnPage().toString(),
          point: '',
          wkt: '',
        };
        this.mapSerivce.activityLogs(body).subscribe(() => {
          //
        }, (error: any) => {
          if (error.status == 403) {
            location.reload();
          }
        });
      });
  }

  clearRadioButton(data1: any, heading: any) {
    for (const key of this.objectKeys(this.refineLayersData)) {
      for (const key1 of this.objectKeys(this.refineLayersData[key])) {
        for (const data of this.refineLayersData[key][key1]) {
          if (
            data.legend.heading == heading ||
            data.legend.class_group == heading
          ) {
            if (data.name != data1.name) {
              data.map.visible = false;
            }
          }
        }
      }
    }
  }

  onChangeMapLayer(
    e: any,
    layerName: any,
    id: any = 1,
    heading: any = '',
    layerId: any,
    data: any = null
  ): void {
    if (id == 2) {
      this.clearRadioButton(data, heading);
      this.current_layer = layerName;
      const wind = document.getElementById('windyMap') as any;

      const allLayers = this.mapSerivce.mapLayer.getLayers().getArray();
      for (let i = 0; i < allLayers.length; i++) {
        if (
          allLayers[i]?.getProperties().title == this.current_layer &&
          this.current_layer.toLowerCase().includes('wind')
        ) {
          if (e.target.checked) {
            wind.style.display = 'block';
            allLayers[i].setProperties({ isChecked: true });
            allLayers[i].setProperties({ isVisible: true });
            break;
          } else {
            wind.style.display = 'none';
            allLayers[i].setProperties({ isChecked: false });
            allLayers[i].setProperties({ isVisible: false });
            break;
          }
        }
      }

      for (let i = 0; i <= allLayers.length; i++) {
        if (
          allLayers[i]?.getProperties().classGroup == heading &&
          !this.current_layer.toLowerCase().includes('wind')
        ) {
          // if (e.target.checked) {
          allLayers[i].setVisible(false);
          allLayers[i].setProperties({ isChecked: false });
          allLayers[i].setProperties({ isVisible: false });
          setTimeout(() => {
            // console.log('a');
          }, 200);
          // }
        }

        if (
          allLayers[i]?.getProperties().type == heading &&
          !this.current_layer.toLowerCase().includes('wind')
        ) {
          // if (e.target.checked) {
          allLayers[i].setVisible(false);
          allLayers[i].setProperties({ isChecked: false });
          allLayers[i].setProperties({ isVisible: false });
          setTimeout(() => {
            // console.log('a');
          }, 200);
          // }
        }
      }

      for (let i = 0; i <= allLayers.length; i++) {
        if (
          allLayers[i]?.getProperties().title == this.current_layer &&
          !this.current_layer.toLowerCase().includes('wind')
        ) {
          allLayers[i].setVisible(true);
          allLayers[i].setProperties({ isVisible: true });
          allLayers[i].setProperties({ isChecked: true });
          break;
        }
      }
    } else {
      this.current_layer = layerName;
      this.logEvent = 'Activate';
      this.eventName = layerName;
      const wind = document.getElementById('windyMap') as any;
      const allLayers = this.mapSerivce.mapLayer.getLayers().getArray();
      for (let i = 0; i < allLayers.length; i++) {
        if (
          allLayers[i]?.getProperties().title == this.current_layer &&
          this.current_layer.toLowerCase().includes('wind')
        ) {
          if (e.target.checked) {
            wind.style.display = 'block';
            allLayers[i].setProperties({ isChecked: true });
            allLayers[i].setProperties({ isVisible: true });
            break;
          } else {
            wind.style.display = 'none';
            allLayers[i].setProperties({ isChecked: false });
            allLayers[i].setProperties({ isVisible: false });
            break;
          }
        }
      }

      for (let i = 0; i <= allLayers.length; i++) {
        if (
          allLayers[i]?.getProperties().title == this.current_layer &&
          allLayers[i]?.getProperties().id == layerId &&
          !this.current_layer.toLowerCase().includes('wind')
        ) {
          if (e.target.checked) {
            allLayers[i].setVisible(true);
            allLayers[i].setProperties({ isVisible: true });
            allLayers[i].setProperties({ isChecked: true });
          } else {
            allLayers[i].setVisible(false);
            allLayers[i].setProperties({ isChecked: false });
            allLayers[i].setProperties({ isVisible: false });
          }
        }
      }
      for (let i = 0; i <= allLayers.length; i++) {
        if (allLayers[i]?.getProperties().channel) {
          if (e.target.checked) {
            allLayers[i].setVisible(true);
            allLayers[i].setProperties({ isVisible: true });
            allLayers[i].setProperties({ isChecked: true });
            this.mapSerivce.setChannel(true);
          } else {
            allLayers[i].setVisible(false);
            allLayers[i].setProperties({ isChecked: false });
            allLayers[i].setProperties({ isVisible: false });
            this.mapSerivce.setChannel(false);
          }
        }
      }
    }
    this.mapSerivce.getLayersId();
    this.http
      .get('https://api.ipify.org/?format=json')
      .subscribe((res: any) => {
        this.mapSerivce.ipAddress = res.ip;
        const body = {
          map_id: this.mapId.toString(),
          event: 'Click',
          event_name: this.eventName,
          ip: this.mapSerivce.ipAddress,
          page: this.mapSerivce.pageUrl,
          basemap: this.mapSerivce.shareCurrentMap,
          time: this.mapSerivce.getTimeOnPage().toString(),
          point: '',
          wkt: '',
        };
        this.mapSerivce.activityLogs(body).subscribe(() => {
          //
        }, (error: any) => {
          if (error.status == 403) {
            location.reload();
          }
        });
      });
  }

  showAnnotation(type: any, event: any): void {
    if (type == 'Point') {
      if (event.target.checked) {
        this.sideNavService.setLegendPoint(true);
      } else {
        this.sideNavService.setLegendPoint(false);
      }
    } else if (type == 'Line') {
      if (event.target.checked) {
        this.sideNavService.setLegendLine(true);
      } else {
        this.sideNavService.setLegendLine(false);
      }
    } else if (type == 'Polygon') {
      if (event.target.checked) {
        this.sideNavService.setLegendPolygon(true);
      } else {
        this.sideNavService.setLegendPolygon(false);
      }
    }
  }

  openAnnotation(): void {
    this.annot = true;
    this.setState();
  }
  closeAnnotation(): void {
    this.annot = false;
    this.sideNavService.legendAnnotCheck = {
      checked1: false,
      checked2: false,
      checked3: false,
    };
    this.sideNavService.setLegendLine(false);
    this.sideNavService.setLegendPoint(false);
    this.sideNavService.setLegendPolygon(false);
    this.annotCheckboxState = this.sideNavService.legendAnnotCheck;
    this.setState();
  }

  openBaseMaps(): void {
    this.eventName = 'BaseMap';
    this.baseMapsState = true;
    this.setState();
    this.http
      .get('https://api.ipify.org/?format=json')
      .subscribe((res: any) => {
        this.mapSerivce.ipAddress = res.ip;
        const body = {
          map_id: this.mapId.toString(),
          event: 'Activate',
          event_name: this.eventName,
          ip: this.mapSerivce.ipAddress,
          page: this.mapSerivce.pageUrl,
          basemap: this.mapSerivce.shareCurrentMap,
          time: this.mapSerivce.getTimeOnPage().toString(),
          point: '',
          wkt: '',
        };
        this.mapSerivce.activityLogs(body).subscribe(() => {
          //
        }, (error: any) => {
          if (error.status == 403) {
            location.reload();
          }
        });
      });
  }

  closeBaseMaps(): void {
    this.eventName = 'BaseMap';
    this.baseMapsState = false;
    this.setState();
    this.http
      .get('https://api.ipify.org/?format=json')
      .subscribe((res: any) => {
        this.mapSerivce.ipAddress = res.ip;
        const body = {
          map_id: this.mapId.toString(),
          event: 'Deactivate',
          event_name: this.eventName,
          ip: this.mapSerivce.ipAddress,
          page: this.mapSerivce.pageUrl,
          basemap: this.mapSerivce.shareCurrentMap,
          time: this.mapSerivce.getTimeOnPage().toString(),
          point: '',
          wkt: '',
        };
        this.mapSerivce.activityLogs(body).subscribe(() => {
          //
        }, (error: any) => {
          if (error.status == 403) {
            location.reload();
          }
        });
      });
  }

  setState() {
    this.sideNavService.legendState = {
      annotation: this.annot,
      baseMaps: this.baseMapsState,
    };
  }

  closeLeftPenal() {
    this.mapSerivce.closePanel = true
    this.mapSerivce.setClosePanel(true);
  }
  setLayersState(): void {
    this.sideNavService.setLayerState = this.panelHandler;
  }

  ngOnDestroy() {
    // this.sideNavService.legendInAnnot = false;
    // this.sideNavService.change = true
  }

  checkPanelCurrentStatusForMainPanel(heading: any): boolean {
    let decide: any;
    const allLayers = this.mapSerivce.mapLayer.getLayers().getArray();
    for (let i = 0; i <= allLayers.length; i++) {
      if (
        allLayers[i]?.getProperties().type == heading &&
        !allLayers[i]?.getProperties().classGroup
      ) {
        for (const key of this.objectKeys(this.refineLayersData)) {
          for (const key1 of this.objectKeys(this.refineLayersData[key])) {
            if (key1 === 'layers' && key == heading) {
              for (const data of this.refineLayersData[key][key1]) {
                if (!data.legend.group) {
                  if (
                    allLayers[i]?.getProperties().isVisible &&
                    allLayers[i]?.getProperties().isChecked
                  ) {
                    decide = false;
                  } else {
                    decide = true;
                    break;
                  }
                } else {
                  break;
                }
              }
            }
          }
        }
      }
    }
    return decide;
  }

  checkPanelCurrentStatusForSubPanel(heading: any, subHeading: any): boolean {
    let decide: any;
    const allLayers = this.mapSerivce.mapLayer.getLayers().getArray();
    for (let i = 0; i <= allLayers.length; i++) {
      if (
        allLayers[i]?.getProperties().type == heading &&
        allLayers[i]?.getProperties().classGroup == subHeading
      ) {
        for (const key of this.objectKeys(this.refineLayersData)) {
          for (const key1 of this.objectKeys(this.refineLayersData[key])) {
            if (key1 !== 'layers' && key == heading && key1 == subHeading) {
              for (const data of this.refineLayersData[key][key1]) {
                if (!data.legend.group) {
                  if (
                    allLayers[i]?.getProperties().isVisible &&
                    allLayers[i]?.getProperties().isChecked
                  ) {
                    decide = false;
                  } else {
                    decide = true;
                    break;
                  }
                } else {
                  break;
                }
              }
            }
          }
        }
      }
    }
    return decide;
  }

  onCtrlKey(event: any, index: any, heading: any) {
    setTimeout(() => {
      if (this.panelHandler[heading] && !event.ctrlKey) {
        this.openBoundariesByHeading(index, heading);
      } else if (!this.panelHandler[heading] && !event.ctrlKey) {
        this.closeBoundariesByHeading(index, heading);
      } else if (!this.panelHandler[heading] && event.ctrlKey) {
        this.panelHandler[heading] = heading;
        const status = this.checkPanelCurrentStatusForMainPanel(heading);

        const allLayers = this.mapSerivce.mapLayer.getLayers().getArray();

        for (let i = 0; i <= allLayers.length; i++) {
          if (
            allLayers[i]?.getProperties().type == heading &&
            !allLayers[i]?.getProperties().classGroup
          ) {
            for (const key of this.objectKeys(this.refineLayersData)) {
              for (const key1 of this.objectKeys(this.refineLayersData[key])) {
                if (key1 === 'layers' && key == heading) {
                  for (const data of this.refineLayersData[key][key1]) {
                    if (!data.legend.group) {
                      if (status) {
                        allLayers[i]?.getProperties().isVisible == true;
                        allLayers[i]?.getProperties().isChecked == true;
                        data.map.visible = true;
                      } else {
                        allLayers[i]?.getProperties().isVisible == false;
                        allLayers[i]?.getProperties().isChecked == false;
                        data.map.visible = false;
                      }
                    } else {
                      break;
                    }
                  }
                }
              }
            }

            if (!allLayers[i]?.getProperties().group && status) {
              const event = { target: { checked: true } };

              this.onChangeMapLayer(
                event,
                allLayers[i]?.getProperties().title,
                1,
                heading,
                allLayers[i]?.getProperties().id
              );
            } else if (!allLayers[i]?.getProperties().group && !status) {
              const event = { target: { checked: false } };

              this.onChangeMapLayer(
                event,
                allLayers[i]?.getProperties().title,
                1,
                heading,
                allLayers[i]?.getProperties().id
              );
            }
          }
        }
      } else if (this.panelHandler[heading] && event.ctrlKey) {
        this.panelHandler[heading] = false;
      }
    }, 1);
  }

  onCtrlKeySubHeading(event: any, index: any, heading: any, subHeading: any) {
    setTimeout(() => {
      if (this.panelHandler[subHeading] && !event.ctrlKey) {
        this.openBoundariesBySubGroup(index, subHeading, heading);
      } else if (!this.panelHandler[subHeading] && !event.ctrlKey) {
        this.closeBoundariesBySubGroup(index, subHeading);
      } else if (!this.panelHandler[subHeading] && event.ctrlKey) {
        this.panelHandler[heading] = heading;
        this.panelHandler[subHeading] = subHeading;
        const status = this.checkPanelCurrentStatusForSubPanel(
          heading,
          subHeading
        );
        const allLayers = this.mapSerivce.mapLayer.getLayers().getArray();
        for (let i = 0; i <= allLayers.length; i++) {
          if (
            allLayers[i]?.getProperties().type == heading &&
            allLayers[i]?.getProperties().classGroup == subHeading
          ) {
            for (const key of this.objectKeys(this.refineLayersData)) {
              for (const key1 of this.objectKeys(this.refineLayersData[key])) {
                if (key1 !== 'layers' && key == heading && key1 == subHeading) {
                  for (const data of this.refineLayersData[key][key1]) {
                    if (!data.legend.group) {
                      if (status) {
                        allLayers[i]?.getProperties().isVisible == true;
                        allLayers[i]?.getProperties().isChecked == true;
                        data.map.visible = true;
                      } else {
                        allLayers[i]?.getProperties().isVisible == false;
                        allLayers[i]?.getProperties().isChecked == false;
                        data.map.visible = false;
                      }
                    } else {
                      break;
                    }
                  }
                }
              }
            }

            if (!allLayers[i]?.getProperties().group && status) {
              const event = { target: { checked: true } };
              this.onChangeMapLayer(
                event,
                allLayers[i]?.getProperties().title,
                1,
                subHeading,
                allLayers[i]?.getProperties().id
              );
            } else if (!allLayers[i]?.getProperties().group && !status) {
              const event = { target: { checked: false } };
              this.onChangeMapLayer(
                event,
                allLayers[i]?.getProperties().title,
                1,
                subHeading,
                allLayers[i]?.getProperties().id
              );
            }
          }
        }
      } else if (this.panelHandler[subHeading] && event.ctrlKey) {
        this.panelHandler[subHeading] = false;
      }
    }, 1);
  }

}
