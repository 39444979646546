import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { InvalidUserComponent } from 'src/app/modules/dashboard/modals/invalid-user/invalid-user.component';
import { MapServiceService } from 'src/app/modules/map/services/Map-service/map-service.service';
import { ResetPasswordModalComponent } from 'src/app/modules/shared/components/modals/reset-password-modal/reset-password-modal.component';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import * as CryptoJS from 'crypto-js';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  email = '';
  password = '';
  product = '';
  loginData: any = [];
  encrypted: any;
  decrypted: any;
  message = 'SuperSecret!!';
  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private global: GlobalService,
    public dialog: MatDialog,
    private router: Router,
    private mapService: MapServiceService
  ) {}

  ngOnInit(): void {
    //dynamic modal handling
    this.mapService.dynamicModalCheck(
      '',
      this.mapService.isLiveShareLink,
      true,
      false
    );
    if (this.auth.isLoggedIn()) this.router.navigate(['/']);
    this.product = this.route.snapshot.paramMap.get('product') || '';
  }

  login() {
    this.global.toggleLoader(true);
    const body = {
      email: this.email,
      password: this.password,
    };
    Sentry.setUser({ email: this.email });
    this.auth.login(body).subscribe(
      (result: any) => {
        this.loginData = result;
        localStorage.setItem('isVerified', 'true');
        this.encrypted = CryptoJS.AES.encrypt(
          this.loginData.user.access_level.toString(),
          this.message
        );
        if (!result.message) {
          localStorage.setItem('userAccessLevel', this.encrypted);
        }
        Sentry.setUser({ email: this.loginData.user.email });

        if (this.loginData.user.login_directly === 'True') {
          this.auth.setToken(result);
          localStorage.setItem('redirect', 'true');
          this.router.navigate([this.loginData.redirect_to]);
        } else {
          if (this.loginData.user.access_level == 500) {
            window.location.replace(
              `https://opstage.mapport.com/login_from_trans/my_maps/${result.token}`
            );
          } else {
            localStorage.setItem('redirect', 'false');
            this.auth.setToken(result);
            this.router.navigate(['/']);
          }
        }
      },
      (error) => {
        this.dialog.open(InvalidUserComponent, {
          data: {
            title: 'Please try again',
            content: 'Username and/or password not recognized',
          },
          disableClose: true,
        });
      }
    );
  }

  forgotPassword() {
    this.dialog.open(ResetPasswordModalComponent, {
      width: '420px',
    });
  }
}
