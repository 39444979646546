import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-global-mod-header',
  templateUrl: './global-mod-header.component.html',
  styleUrls: ['./global-mod-header.component.css']
})
export class GlobalModHeaderComponent implements OnInit {
user: any
  constructor(public authService: AuthService) {
    this.user = this.authService.user
  }

  ngOnInit(): void {
    //
  }
  logout() {
    this.authService.logout()
    window.location.href = 'https://opstage.mapport.com/login/';

  }
}
