import { LayerService } from './../../../../services/layer/layer.service';
import { Component, OnInit } from '@angular/core';
import { tabledata } from 'src/app/modules/shared/models/table/table.model';
import { SnackbarService } from 'src/app/services/snackbarService/snackbar.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { TabService } from 'src/app/services/tab/tab.service';

@Component({
  selector: 'app-glo-mod-layers',
  templateUrl: './glo-mod-layers.component.html',
  styleUrls: ['./glo-mod-layers.component.css']
})
export class GloModLayersComponent implements OnInit {
  allLayers: tabledata[] = []
  totalItems: any
  currentPages: any = 1
  pageSize: any = 10
  totalPages: any
  startPage!: number
  endPage!: number
  startPages!: number
  endPages!: number
  startIndex: any
  endIndex: any
  pages: any
  disablePerviousBuuton = false
  disableNextBuuton = false
  options: any = {
    page: 1,
    search: '',
    size: 5
  };
  count = 0;
  constructor(public LayerService: LayerService,public tabService: TabService, public _snackService: SnackbarService, public router: Router ) {
    this.tabService.hideTab = false
  }

  ngOnInit(): void {
    this.LayerService.layerListUpdate.subscribe((res: any) => {
      if (res) {
        this.getAllLayers()
      }
    });

    this.getAllLayers()
    this.onTableDataChange(1)
    this.paginate(this.totalItems, this.currentPages, this.pageSize)
  }
  search($event: any): void {
    const text = $event.target.value;
    this.options.search = text;
    this.options.page = 1;
    this.getAllLayers();
  }
  size(size: any): void {
    this.pageSize = size.target.value;
    this.getAllLayers();
    this.paginate(this.totalItems, this.currentPages, this.pageSize)
  }
  onTableDataChange(event: any) {
    this.currentPages = event;
    this.getAllLayers();
    this.paginate(this.totalItems, this.currentPages, this.pageSize)
  }
  getAllLayers(): void {
    this.LayerService.getAllLayer(this.pageSize, this.currentPages , this.options.search).pipe(first()).subscribe((res: any) => {
      this.allLayers = res.results
      this.totalItems = res.count
      switch (this.currentPages) {
        case 1:
          this.disablePerviousBuuton = true
          this.disableNextBuuton = false
          break;
          case this.currentPages == this.count:
            this.disableNextBuuton = true
            this.disablePerviousBuuton = false
            break;
        default:
          this.disableNextBuuton = false
          this.disablePerviousBuuton = false
          break;
      }
      this.paginate(this.totalItems, this.currentPages, this.pageSize)
    })
  }
  getLayerdata(layer: any): void {
    this.LayerService.layerIds.push(layer.id)
    this.LayerService.layerId = layer.id
    this.LayerService.layerData = layer
  }
  editLayer(layerData: any) {
    this.LayerService.getLayerById(layerData.id).pipe(first()).subscribe({
      next: (res: any) => {
        localStorage.removeItem("layerData");
        localStorage.setItem('layerData', JSON.stringify(res).toString());
        this.router.navigateByUrl('/layers');
      },
      error: error => {
        this._snackService.openSnackBar('Please select layer')
      }
    })

  }
  onbtnClick() {
    if (this.currentPages > 1) {
      this.currentPages--;
      this.paginate(this.totalItems, this.currentPages, this.pageSize);
      this.getAllLayers();
    } else {
      const numPage = this.pages.length
      if (this.currentPages < numPage) {
        this.currentPages++;
        this.paginate(this.totalItems, this.currentPages, this.pageSize);
        this.getAllLayers();
      }
    }
  }
  prevPage() {
    if (this.currentPages > 1) {
      this.currentPages--;
      this.paginate(this.totalItems, this.currentPages, this.pageSize);
      this.getAllLayers();
    }
  }
  nextPage() {
    const numPage = this.pages.length
    if (this.currentPages < numPage) {
      this.currentPages++;
      this.paginate(this.totalItems, this.currentPages, this.pageSize);
      this.getAllLayers();
    }
  }

  paginate(
    totalItems = 10,
    currentPage: any,
    pageSize: any,
    maxPages = 10
  ) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      this.startPages = 1;
      this.endPages = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        this.startPages = 1;
        this.endPages = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        this.startPages = totalPages - maxPages + 1;
        this.endPages = totalPages;
      } else {
        // current page somewhere in the middle
        this.startPages = currentPage - maxPagesBeforeCurrentPage;
        this.endPages = currentPage + maxPagesAfterCurrentPage;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array((this.endPages + 1) - this.startPages).keys()).map(i => this.startPage + i);

    // return object with all pager properties required by the view
    this.totalItems = totalItems,
      this.currentPages = currentPage,
      this.pageSize = pageSize,
      this.totalPages = totalPages,
      this.startPage = this.startPages,
      this.endPage = this.endPages,
      this.startIndex = startIndex,
      this.endIndex = endIndex,
      this.pages = pages

  }
}
