import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-update-notification',
  templateUrl: './update-notification.component.html',
  styleUrls: ['./update-notification.component.css'],
})
export class UpdateNotificationComponent implements OnInit {
  status: any;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {  
    //
  }

  ngOnInit(): void {
    //get data pass in dialog
    this.status = this.data.message;
  }


  closeDialog(): void {
    this.dialog.closeAll();
  }
}
