import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { RequestInterceptor } from './services/request-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from './modules/shared/shared.module';
import { AuthService } from './services/auth.service';
import { TooltipModule } from 'ng2-tooltip-directive';
import { IMaskModule } from 'angular-imask';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { LoginAsComponent } from './components/login-as/login-as.component';
import { MapAccessComponent } from './components/map-access/map-access.component';
import { CollabooratorRegistertionComponent } from './modules/subscription/components/collaboorator-registertion/collaboorator-registertion.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LegendpreviewComponent } from './modules/map/components/legendpreview/legendpreview.component';
import { GloModMapsComponent } from './modules/global-modification/main-global-modification/glo-mod-maps/glo-mod-maps.component';
import { GloModLayersComponent } from './modules/global-modification/main-global-modification/glo-mod-layers/glo-mod-layers.component';
import { MainGlobalModificationComponent } from './modules/global-modification/main-global-modification/main-global-modification.component';
import { GloModLoginComponent } from './components/glo-mod-login/glo-mod-login.component';
import { TableTabsComponent } from './modules/global-modification/table-tabs/table-tabs.component';
import { CloneLayerComponent } from './modules/global-modification/modals/clone-layer/clone-layer.component';
import { LayersComponent } from './modules/global-modification/main-global-modification/layers/layers.component';
import { ShowFieldsComponent } from './modules/global-modification/main-global-modification/layers/show-fields/show-fields.component';
import { LabelsComponent } from './modules/global-modification/main-global-modification/layers/labels/labels.component';
import { PdfTableComponent } from './modules/global-modification/main-global-modification/layers/pdf-table/pdf-table.component';
import { AddFieldsComponent } from './modules/global-modification/main-global-modification/layers/show-fields/add-fields/add-fields.component';
import { GlobalModificationModule } from './modules/global-modification/global-modification.module';
import { RECAPTCHA_SETTINGS,  RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
// Sentry.init({
//   dsn: "https://cca8dfcdd7fb4389b39e49c202a1c8f3@o1153123.ingest.sentry.io/6231991" ,
//   integrations: [
//     new BrowserTracing({
//       tracingOrigins: ["https://app.mapport.com", "https://app.mapport.com/api"],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//   tracesSampleRate: 1.0,
// });

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login_as/:token', component: LoginAsComponent },
  { path: 'glomod_login_as/:token', component: GloModLoginComponent },
  { path: 'legend_preview/:token/:slug', component: LegendpreviewComponent },
  { path: 'map_access/:token/:slug?embed=true', component: MapAccessComponent },
  { path: ':product/login', component: LoginComponent },
  {
    path: ':product/collaborator_registration_wm/:id/:email',
    component: CollabooratorRegistertionComponent,
  },
  {
    path: ':product/subscribe',
    loadChildren: () =>
      import('./modules/subscription/subscription.module').then(
        (m) => m.SubscriptionModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'global',
    loadChildren: () =>
      import('./modules/global-modification/global-modification.module').then((m) => m.GlobalModificationModule),
    canActivate: [AuthGuard],
  },
  // Maps routes goes here here

  {
    path: ':map',
    loadChildren: () =>
      import('./modules/map/map.module').then((m) => m.MapModule),
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginAsComponent,
    MapAccessComponent,
    GloModMapsComponent,
    GloModLayersComponent,
    MainGlobalModificationComponent,
    TableTabsComponent,
    CloneLayerComponent,
    LayersComponent,
    ShowFieldsComponent,
    LabelsComponent,
    PdfTableComponent,
    AddFieldsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    SharedModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatDialogModule,
    TooltipModule,
    DragDropModule,
    MatCheckboxModule,
    MatCardModule,
    MatTableModule,
    MatFormFieldModule,
    MatButtonModule,
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      scrollPositionRestoration: 'top',
    }),
    // NoopAnimationsModule,
    SharedModule,
    GlobalModificationModule,
    TooltipModule,
    IMaskModule,
    BrowserAnimationsModule,
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
