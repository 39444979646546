import { SnackbarService } from './../../../../services/snackbarService/snackbar.service';
import { Component, OnInit } from '@angular/core';
import { createLayer } from 'src/app/modules/shared/models/new-layer/newLayer.model';
import { LayerService } from 'src/app/services/layer/layer.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-layers',
  templateUrl: './layers.component.html',
  styleUrls: ['./layers.component.css']
})
export class LayersComponent implements OnInit {
  product: createLayer = new createLayer();
  layerId!: number
  layerName!: string
  showTable = false;
  showOtherOption = false
  editLayer = false
  disableButton =false
  constructor(public layerService: LayerService,
    private router: Router,
    public _snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.layerService.layerdata = localStorage.getItem("layerData");
    if (this.layerService.layerdata) {
      this.editLayer = true
      this.product = JSON.parse(this.layerService.layerdata)
      this.layerId = this.product.id
      this.layerName = this.product.name
    } else {
      this.disableButton = true
      this.layerName = 'New Layers'
    }
  }
  changeSchema(): void {
    this.showTable = true
  }
  changeTable() {
    this.showOtherOption = true
  }
  save(form: NgForm) {
    if (this.editLayer) {
      this.layerService.editLayer(this.layerId, form).pipe(first()).subscribe({
        next: (res) => {
          localStorage.removeItem("layerData");
          localStorage.setItem('layerData', JSON.stringify(res).toString());
          this._snackbar.openSnackBar('Layer update successfully')
          this.disableButton = false
          // this.router.navigate(['/']);
        },
        error: error => {
          this._snackbar.openSnackBar('Something went wrong')
        }
      })
    } else {
      this.layerService.createLayer(form).pipe(first()).subscribe({
        next: (res: any) => {
          localStorage.removeItem("layerData");
          localStorage.setItem('layerData', JSON.stringify(res).toString());
          this._snackbar.openSnackBar('Layer added successfully')
          this.disableButton = false
          // this.router.navigate(['/']);
        },
        error: error => {
          this._snackbar.openSnackBar('Something went wrong')
        }
      })
    }

  }

  backToMain() {
    this.router.navigate(['/global']);
  }
}
