export const environment = {
  production: true,
  baseUrl:'https://stage.mapport.com/api',
  recaptcha: {
    siteKey: '6LdLIEghAAAAACxkSt7Ya06VhAFhHS0DwPxBz73w',
  },
  recurly: {
    recurlyKey: 'ewr1-QggekpCdO9I4zdY1yojb5S'
  }
};
