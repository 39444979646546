import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardServiceService } from '../modules/dashboard/services/dashboard-service.service';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user: any;
  encrypted: any
  decrypted: any
  userEn = 'userEncrpto'
  expiryMap:any
  constructor(private http: HttpClient, private router: Router,private dashboardService:DashboardServiceService) {
    this.decrypted = (localStorage.getItem('user') || '')
    if(!this.decrypted) {
      const user = (localStorage.getItem('user') || '')
      if (user) this.user = JSON.parse(user);
    } else {
      const decrypted = CryptoJS.AES.decrypt(this.decrypted, this.userEn);
      const user = decrypted.toString(CryptoJS.enc.Utf8);
      if (user) this.user = JSON.parse(user);
    }

  }

  login(body: any) {
    return this.http.post('/authentication/login/', body, {});
  }

  logout() {
    localStorage.setItem('auth_token', '');
    localStorage.setItem('user', '');
    localStorage.removeItem("timeLeft");
    localStorage.removeItem("isVerified");
    this.user = false;
    this.router.navigate(['/']);
  }

  resetPassword(email: string) {
    const data = { email: email };
    return this.http.post('/authentication/reset-password/', data);
  }

  setToken(data: any) {
    localStorage.setItem('auth_token', data.token);
    localStorage.setItem('first_login', 'true');
    this.encrypted = CryptoJS.AES.encrypt(JSON.stringify(data).toString(), this.userEn);
    localStorage.setItem('user', this.encrypted);
    this.dashboardService.setUserInfo(data)
    this.user = data;
  }

  isLoggedIn() {
    const token = localStorage.getItem('auth_token');
    if (token) {
      return true;
    }
    return false;
  }
}
