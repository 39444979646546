import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardServiceService } from 'src/app/modules/dashboard/services/dashboard-service.service';
import { InvalidUserComponent } from 'src/app/modules/dashboard/modals/invalid-user/invalid-user.component';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import { MapServiceService } from 'src/app/modules/map/services/Map-service/map-service.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-login-as',
  templateUrl: './login-as.component.html',
  styleUrls: ['./login-as.component.css']
})
export class LoginAsComponent implements OnInit {
  token = '';
  slug = '';
  userInfo: any
  encrypted: any
  message = 'SuperSecret!!';
  constructor(
    private route: ActivatedRoute,
    private global: GlobalService,
    public mapServices: MapServiceService,
    public dashboardService: DashboardServiceService,
    private auth: AuthService,
    public dialog: MatDialog,
    private router: Router,
  ) {
    //
  }

  ngOnInit(): void {
    localStorage.clear();
    setTimeout(() => {
      this.dashboardService.setBackToDemigod(true)
      localStorage.setItem("demigodBack",'true')
    }, 1000);
    this.token = this.route.snapshot.paramMap.get('token') || '';
    this.mapServices.getClientInfo(this.token).subscribe((res: any) => {

      this.userInfo = res
      localStorage.setItem('isVerified', 'true');
      this.encrypted = CryptoJS.AES.encrypt(this.userInfo.user.access_level.toString(), this.message);
      if (!res.message) {
        localStorage.setItem('userAccessLevel', this.encrypted);
      }
      if (res.message) {
        this.dialog.open(InvalidUserComponent, {
          data: {
            title: 'Please try again',
            content: 'Username and/or password not recognized'
          },
          disableClose: true
        });
        // this.global.showMessageModal(
        //   '.',
        //   'Username and/or password not recognized'
        // );
      } else {
        if (this.userInfo.user.login_directly === 'True') {
          this.auth.setToken(res);
          localStorage.setItem('redirect', 'true');
          this.router.navigate([this.userInfo.redirect_to]);
          // this.router.navigate(['/alameda-county-parcel-viewer']);
        } else {
          localStorage.setItem('redirect', 'false');
          this.auth.setToken(res);
          this.router.navigate(['/']);
        }
      }
    })
  }

}



