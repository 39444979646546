import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reset-password-success-modal',
  templateUrl: './reset-password-success-modal.component.html',
  styleUrls: ['./reset-password-success-modal.component.css']
})
export class ResetPasswordSuccessModalComponent implements OnInit {
  email: any
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.email = this.data.email
  }
  close(): void {
    this.dialog.closeAll();
  }
}
