import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LabelsComponent } from './main-global-modification/layers/labels/labels.component';
import { LayersComponent } from './main-global-modification/layers/layers.component';
import { PdfTableComponent } from './main-global-modification/layers/pdf-table/pdf-table.component';
import { AddFieldsComponent } from './main-global-modification/layers/show-fields/add-fields/add-fields.component';
import { ShowFieldsComponent } from './main-global-modification/layers/show-fields/show-fields.component';
import { MainGlobalModificationComponent } from './main-global-modification/main-global-modification.component';

const routes: Routes = [
  // Global Modification routes goes here here
  { path: 'global',  pathMatch: 'full', component: MainGlobalModificationComponent,  },
  { path: 'layers', component: LayersComponent },
  { path: 'showFields', component: ShowFieldsComponent },
  { path: 'addFields', component: AddFieldsComponent },
  { path: 'labels', component: LabelsComponent },
  { path: 'pdfTable', component: PdfTableComponent },
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ]
})
export class GlobalModificationModule { }
