import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-capture',
  templateUrl: './delete-capture.component.html',
  styleUrls: ['./delete-capture.component.css']
})
export class DeleteCaptureComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    //
  }
  close(): void {
    this.dialog.closeAll()
  }
}
