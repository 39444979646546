import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { tabledata } from 'src/app/modules/shared/models/table/table.model';

@Injectable({
  providedIn: 'root'
})
export class LayerService {
  layerdata: any;
  layerId: any;
  layerIds: any[] = [];
  layerData: any
  layerListUpdate: Subject<any> = new Subject<any>();
  constructor(private http: HttpClient,) { }

  //Get All Layer
  public getAllLayer(pageSize: any,page: any, searchdata: any): Observable<any> {
    return this.http.get(`/glomod/layers/?page_size=${pageSize}&page=${page}&search=${searchdata}`);
  }
  //Get Layer By Id
  public getLayerById(id: any) : Observable<any> {
    return this.http.get(`/glomod/layer_operations/${id}/`);
  }
  //Ceate Layer
  public createLayer(body: any): Observable<any> {
    return this.http.post('/glomod/layers/', body);
  }
  //Edit Layer
  public editLayer(id: any, layerData: any): Observable<tabledata> {
    return this.http
      .patch<tabledata>(`/glomod/layer_operations/${id}/`, layerData)
      .pipe(
        shareReplay()
      );
  }
  //Delete Layer
  public deleteLayer(id: any): Observable<any> {
    return this.http.delete(`/glomod/layer_operations/${id}/`).pipe();
  }

  public refreshLayer(value: any) {
    this.layerListUpdate.next(value);
  }

}
