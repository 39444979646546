<app-global-mod-header></app-global-mod-header>
<app-layers-tab></app-layers-tab>
<div class="container">
    <div class="row page-title" style="margin-right: 0px">
      <div class="col-md-7 mt-3 p-0">
        <h3>
          <a routerLink="/global" >Global Modification</a> »
          <a routerLink="/layers">Layers</a> »
          <a >{{layerName}}</a> »
          <a routerLink="/labels">Labels</a>
        </h3>
      </div>
      <div class="col-md-5">
        <ul class="actions navbar-right" >
          <li role="presentation">
            <a routerLink="/pdfTable" >PDF table</a>
          </li>
          <li role="presentation">
            <a routerLink="/labels" class="mat-tab-label-active">Labels</a>
          </li>
          <li role="presentation">
            <a routerLink="/showFields">Show Fields</a>
          </li>
        </ul>
      </div>
  </div>
</div>
<div class="content">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div id="field1" class="label_name_div_margin">
                    <p id="apn_text" class="label_names_margin">APN:</p>
                    <select id="id_apn" name="apn" [(ngModel)]="labelData.apn">
                        <option value="(Choose field)">(Choose field)</option>


                        <option value="acres">Acres</option>



                        <option value="apn_12">Apn 12</option>



                        <option value="apn_full">Apn Full</option>



                        <option value="apn_geom_raw">Apn Geom Raw</option>



                        <option value="assdyear">Assdyear</option>



                        <option value="coverage">Coverage</option>



                        <option value="currentd_1">Currentd 1</option>



                        <option value="currentdoc">Currentdoc</option>



                        <option value="currentsalebuyer1fullname">Currentsalebuyer1Fullname</option>



                        <option value="currentsalebuyer2fullname">Currentsalebuyer2Fullname</option>



                        <option value="currentsalecontractdate">Currentsalecontractdate</option>



                        <option value="currentsaledocumenttype">Currentsaledocumenttype</option>



                        <option value="currentsaleseller1fullname">Currentsaleseller1Fullname</option>



                        <option value="currentsaleseller2fullname">Currentsaleseller2Fullname</option>



                        <option value="currentsalesprice">Currentsalesprice</option>



                        <option value="currentsalespricecode">Currentsalespricecode</option>



                        <option value="description">Description</option>



                        <option value="effectiveyearbuilt">Effectiveyearbuilt</option>



                        <option value="geom">Geom</option>



                        <option value="gid">Gid</option>



                        <option value="landuse1">Landuse1</option>



                        <option value="landvalue">Landvalue</option>



                        <option value="legaldescription">Legaldescription</option>



                        <option value="lotsizedepthfeet">Lotsizedepthfeet</option>



                        <option value="lotsizefrontagefeet">Lotsizefrontagefeet</option>



                        <option value="mail_full">Mail Full</option>



                        <option value="mail_p1">Mail P1</option>



                        <option value="mail_p2">Mail P2</option>



                        <option value="mail_qual">Mail Qual</option>



                        <option value="mailingforeignaddressind">Mailingforeignaddressind</option>



                        <option value="markettotalvalue">Markettotalvalue</option>



                        <option value="marketvalueimprovement">Marketvalueimprovement</option>



                        <option value="marketvalueland">Marketvalueland</option>



                        <option value="marketyear">Marketyear</option>



                        <option value="municipality">Municipality</option>



                        <option value="owner1corpind">Owner1Corpind</option>



                        <option value="owner1firstname">Owner1Firstname</option>



                        <option value="owner1lastname">Owner1Lastname</option>



                        <option value="owner1middlename">Owner1Middlename</option>



                        <option value="owner1ownershiprights">Owner1Ownershiprights</option>



                        <option value="owner1suffix">Owner1Suffix</option>



                        <option value="owner2corpind">Owner2Corpind</option>



                        <option value="owner2firstname">Owner2Firstname</option>



                        <option value="owner2lastname">Owner2Lastname</option>



                        <option value="owner2middlename">Owner2Middlename</option>



                        <option value="owner2suffix">Owner2Suffix</option>



                        <option value="owner_full">Owner Full</option>



                        <option value="owner_p1">Owner P1</option>



                        <option value="owner_p2">Owner P2</option>



                        <option value="owneroccupied">Owneroccupied</option>



                        <option value="preplan">Preplan</option>



                        <option value="prevsalebuyer1fullname">Prevsalebuyer1Fullname</option>



                        <option value="prevsalebuyer2fullname">Prevsalebuyer2Fullname</option>



                        <option value="prevsalecontractdate">Prevsalecontractdate</option>



                        <option value="prevsaledocumenttype">Prevsaledocumenttype</option>



                        <option value="prevsalerecordingdate">Prevsalerecordingdate</option>



                        <option value="prevsaleseller1fullname">Prevsaleseller1Fullname</option>



                        <option value="prevsaleseller2fullname">Prevsaleseller2Fullname</option>



                        <option value="prevsalesprice">Prevsalesprice</option>



                        <option value="prevsalespricecode">Prevsalespricecode</option>



                        <option value="prop_class_id">Prop Class Id</option>



                        <option value="propertyclassid">Propertyclassid</option>



                        <option value="propertyid">Propertyid</option>



                        <option value="roofcovercode">Roofcovercode</option>



                        <option value="rooftypecode">Rooftypecode</option>



                        <option value="situs_city">Situs City</option>



                        <option value="situs_full">Situs Full</option>



                        <option value="situs_geostatus">Situs Geostatus</option>



                        <option value="situs_lat">Situs Lat</option>



                        <option value="situs_long">Situs Long</option>



                        <option value="situs_p1">Situs P1</option>



                        <option value="situs_p2">Situs P2</option>



                        <option value="situs_qual">Situs Qual</option>



                        <option value="situs_zip5">Situs Zip5</option>



                        <option value="situscensusblock">Situscensusblock</option>



                        <option value="situscensustract">Situscensustract</option>



                        <option value="situsgeostatuscode">Situsgeostatuscode</option>



                        <option value="size">Size</option>



                        <option value="square_feet">Square Feet</option>



                        <option value="structurev">Structurev</option>



                        <option value="sumbuildingsqft">Sumbuildingsqft</option>



                        <option value="sumgrossareasqft">Sumgrossareasqft</option>



                        <option value="taxamt">Taxamt</option>



                        <option value="taxdeliquentyear">Taxdeliquentyear</option>



                        <option value="taxratecodearea">Taxratecodearea</option>



                        <option value="taxyear">Taxyear</option>



                        <option value="totalvalue">Totalvalue</option>



                        <option value="usecode">Usecode</option>



                        <option value="utilization">Utilization</option>



                        <option value="yearbuilt">Yearbuilt</option>



                        <option value="zoning">Zoning</option>


                    </select>
                </div>
                <div id="field2" class="label_name_div_margin">
                    <p id="OwName" class="label_names_margin">Owner Name</p>
                    <select id="id_apn" name="apn" [(ngModel)]="labelData.owner_name">
                        <option value="(Choose field)">(Choose field)</option>


                        <option value="acres">Acres</option>



                        <option value="apn_12">Apn 12</option>



                        <option value="apn_full">Apn Full</option>



                        <option value="apn_geom_raw">Apn Geom Raw</option>



                        <option value="assdyear">Assdyear</option>



                        <option value="coverage">Coverage</option>



                        <option value="currentd_1">Currentd 1</option>



                        <option value="currentdoc">Currentdoc</option>



                        <option value="currentsalebuyer1fullname">Currentsalebuyer1Fullname</option>



                        <option value="currentsalebuyer2fullname">Currentsalebuyer2Fullname</option>



                        <option value="currentsalecontractdate">Currentsalecontractdate</option>



                        <option value="currentsaledocumenttype">Currentsaledocumenttype</option>



                        <option value="currentsaleseller1fullname">Currentsaleseller1Fullname</option>



                        <option value="currentsaleseller2fullname">Currentsaleseller2Fullname</option>



                        <option value="currentsalesprice">Currentsalesprice</option>



                        <option value="currentsalespricecode">Currentsalespricecode</option>



                        <option value="description">Description</option>



                        <option value="effectiveyearbuilt">Effectiveyearbuilt</option>



                        <option value="geom">Geom</option>



                        <option value="gid">Gid</option>



                        <option value="landuse1">Landuse1</option>



                        <option value="landvalue">Landvalue</option>



                        <option value="legaldescription">Legaldescription</option>



                        <option value="lotsizedepthfeet">Lotsizedepthfeet</option>



                        <option value="lotsizefrontagefeet">Lotsizefrontagefeet</option>



                        <option value="mail_full">Mail Full</option>



                        <option value="mail_p1">Mail P1</option>



                        <option value="mail_p2">Mail P2</option>



                        <option value="mail_qual">Mail Qual</option>



                        <option value="mailingforeignaddressind">Mailingforeignaddressind</option>



                        <option value="markettotalvalue">Markettotalvalue</option>



                        <option value="marketvalueimprovement">Marketvalueimprovement</option>



                        <option value="marketvalueland">Marketvalueland</option>



                        <option value="marketyear">Marketyear</option>



                        <option value="municipality">Municipality</option>



                        <option value="owner1corpind">Owner1Corpind</option>



                        <option value="owner1firstname">Owner1Firstname</option>



                        <option value="owner1lastname">Owner1Lastname</option>



                        <option value="owner1middlename">Owner1Middlename</option>



                        <option value="owner1ownershiprights">Owner1Ownershiprights</option>



                        <option value="owner1suffix">Owner1Suffix</option>



                        <option value="owner2corpind">Owner2Corpind</option>



                        <option value="owner2firstname">Owner2Firstname</option>



                        <option value="owner2lastname">Owner2Lastname</option>



                        <option value="owner2middlename">Owner2Middlename</option>



                        <option value="owner2suffix">Owner2Suffix</option>



                        <option value="owner_full">Owner Full</option>



                        <option value="owner_p1">Owner P1</option>



                        <option value="owner_p2">Owner P2</option>



                        <option value="owneroccupied">Owneroccupied</option>



                        <option value="preplan">Preplan</option>



                        <option value="prevsalebuyer1fullname">Prevsalebuyer1Fullname</option>



                        <option value="prevsalebuyer2fullname">Prevsalebuyer2Fullname</option>



                        <option value="prevsalecontractdate">Prevsalecontractdate</option>



                        <option value="prevsaledocumenttype">Prevsaledocumenttype</option>



                        <option value="prevsalerecordingdate">Prevsalerecordingdate</option>



                        <option value="prevsaleseller1fullname">Prevsaleseller1Fullname</option>



                        <option value="prevsaleseller2fullname">Prevsaleseller2Fullname</option>



                        <option value="prevsalesprice">Prevsalesprice</option>



                        <option value="prevsalespricecode">Prevsalespricecode</option>



                        <option value="prop_class_id">Prop Class Id</option>



                        <option value="propertyclassid">Propertyclassid</option>



                        <option value="propertyid">Propertyid</option>



                        <option value="roofcovercode">Roofcovercode</option>



                        <option value="rooftypecode">Rooftypecode</option>



                        <option value="situs_city">Situs City</option>



                        <option value="situs_full">Situs Full</option>



                        <option value="situs_geostatus">Situs Geostatus</option>



                        <option value="situs_lat">Situs Lat</option>



                        <option value="situs_long">Situs Long</option>



                        <option value="situs_p1">Situs P1</option>



                        <option value="situs_p2">Situs P2</option>



                        <option value="situs_qual">Situs Qual</option>



                        <option value="situs_zip5">Situs Zip5</option>



                        <option value="situscensusblock">Situscensusblock</option>



                        <option value="situscensustract">Situscensustract</option>



                        <option value="situsgeostatuscode">Situsgeostatuscode</option>



                        <option value="size">Size</option>



                        <option value="square_feet">Square Feet</option>



                        <option value="structurev">Structurev</option>



                        <option value="sumbuildingsqft">Sumbuildingsqft</option>



                        <option value="sumgrossareasqft">Sumgrossareasqft</option>



                        <option value="taxamt">Taxamt</option>



                        <option value="taxdeliquentyear">Taxdeliquentyear</option>



                        <option value="taxratecodearea">Taxratecodearea</option>



                        <option value="taxyear">Taxyear</option>



                        <option value="totalvalue">Totalvalue</option>



                        <option value="usecode">Usecode</option>



                        <option value="utilization">Utilization</option>



                        <option value="yearbuilt">Yearbuilt</option>



                        <option value="zoning">Zoning</option>


                    </select>
                </div>
                <div id="field3" class="label_name_div_margin" >
                    <p id="addr1" class="label_names_margin">Situs Address Part 2</p>
                    <select id="id_apn" name="apn" [(ngModel)]="labelData.situs_addr_Two">
                        <option value="(Choose field)">(Choose field)</option>


                        <option value="acres">Acres</option>



                        <option value="apn_12">Apn 12</option>



                        <option value="apn_full">Apn Full</option>



                        <option value="apn_geom_raw">Apn Geom Raw</option>



                        <option value="assdyear">Assdyear</option>



                        <option value="coverage">Coverage</option>



                        <option value="currentd_1">Currentd 1</option>



                        <option value="currentdoc">Currentdoc</option>



                        <option value="currentsalebuyer1fullname">Currentsalebuyer1Fullname</option>



                        <option value="currentsalebuyer2fullname">Currentsalebuyer2Fullname</option>



                        <option value="currentsalecontractdate">Currentsalecontractdate</option>



                        <option value="currentsaledocumenttype">Currentsaledocumenttype</option>



                        <option value="currentsaleseller1fullname">Currentsaleseller1Fullname</option>



                        <option value="currentsaleseller2fullname">Currentsaleseller2Fullname</option>



                        <option value="currentsalesprice">Currentsalesprice</option>



                        <option value="currentsalespricecode">Currentsalespricecode</option>



                        <option value="description">Description</option>



                        <option value="effectiveyearbuilt">Effectiveyearbuilt</option>



                        <option value="geom">Geom</option>



                        <option value="gid">Gid</option>



                        <option value="landuse1">Landuse1</option>



                        <option value="landvalue">Landvalue</option>



                        <option value="legaldescription">Legaldescription</option>



                        <option value="lotsizedepthfeet">Lotsizedepthfeet</option>



                        <option value="lotsizefrontagefeet">Lotsizefrontagefeet</option>



                        <option value="mail_full">Mail Full</option>



                        <option value="mail_p1">Mail P1</option>



                        <option value="mail_p2">Mail P2</option>



                        <option value="mail_qual">Mail Qual</option>



                        <option value="mailingforeignaddressind">Mailingforeignaddressind</option>



                        <option value="markettotalvalue">Markettotalvalue</option>



                        <option value="marketvalueimprovement">Marketvalueimprovement</option>



                        <option value="marketvalueland">Marketvalueland</option>



                        <option value="marketyear">Marketyear</option>



                        <option value="municipality">Municipality</option>



                        <option value="owner1corpind">Owner1Corpind</option>



                        <option value="owner1firstname">Owner1Firstname</option>



                        <option value="owner1lastname">Owner1Lastname</option>



                        <option value="owner1middlename">Owner1Middlename</option>



                        <option value="owner1ownershiprights">Owner1Ownershiprights</option>



                        <option value="owner1suffix">Owner1Suffix</option>



                        <option value="owner2corpind">Owner2Corpind</option>



                        <option value="owner2firstname">Owner2Firstname</option>



                        <option value="owner2lastname">Owner2Lastname</option>



                        <option value="owner2middlename">Owner2Middlename</option>



                        <option value="owner2suffix">Owner2Suffix</option>



                        <option value="owner_full">Owner Full</option>



                        <option value="owner_p1">Owner P1</option>



                        <option value="owner_p2">Owner P2</option>



                        <option value="owneroccupied">Owneroccupied</option>



                        <option value="preplan">Preplan</option>



                        <option value="prevsalebuyer1fullname">Prevsalebuyer1Fullname</option>



                        <option value="prevsalebuyer2fullname">Prevsalebuyer2Fullname</option>



                        <option value="prevsalecontractdate">Prevsalecontractdate</option>



                        <option value="prevsaledocumenttype">Prevsaledocumenttype</option>



                        <option value="prevsalerecordingdate">Prevsalerecordingdate</option>



                        <option value="prevsaleseller1fullname">Prevsaleseller1Fullname</option>



                        <option value="prevsaleseller2fullname">Prevsaleseller2Fullname</option>



                        <option value="prevsalesprice">Prevsalesprice</option>



                        <option value="prevsalespricecode">Prevsalespricecode</option>



                        <option value="prop_class_id">Prop Class Id</option>



                        <option value="propertyclassid">Propertyclassid</option>



                        <option value="propertyid">Propertyid</option>



                        <option value="roofcovercode">Roofcovercode</option>



                        <option value="rooftypecode">Rooftypecode</option>



                        <option value="situs_city">Situs City</option>



                        <option value="situs_full">Situs Full</option>



                        <option value="situs_geostatus">Situs Geostatus</option>



                        <option value="situs_lat">Situs Lat</option>



                        <option value="situs_long">Situs Long</option>



                        <option value="situs_p1">Situs P1</option>



                        <option value="situs_p2">Situs P2</option>



                        <option value="situs_qual">Situs Qual</option>



                        <option value="situs_zip5">Situs Zip5</option>



                        <option value="situscensusblock">Situscensusblock</option>



                        <option value="situscensustract">Situscensustract</option>



                        <option value="situsgeostatuscode">Situsgeostatuscode</option>



                        <option value="size">Size</option>



                        <option value="square_feet">Square Feet</option>



                        <option value="structurev">Structurev</option>



                        <option value="sumbuildingsqft">Sumbuildingsqft</option>



                        <option value="sumgrossareasqft">Sumgrossareasqft</option>



                        <option value="taxamt">Taxamt</option>



                        <option value="taxdeliquentyear">Taxdeliquentyear</option>



                        <option value="taxratecodearea">Taxratecodearea</option>



                        <option value="taxyear">Taxyear</option>



                        <option value="totalvalue">Totalvalue</option>



                        <option value="usecode">Usecode</option>



                        <option value="utilization">Utilization</option>



                        <option value="yearbuilt">Yearbuilt</option>



                        <option value="zoning">Zoning</option>


                    </select>
                </div>
                <div id="field3" class="label_name_div_margin" >
                    <p id="addr1" class="label_names_margin">Situs Address Part 1</p>
                    <select id="id_apn" name="apn" [(ngModel)]="labelData.situs_addr_One">
                        <option value="(Choose field)">(Choose field)</option>


                        <option value="acres">Acres</option>



                        <option value="apn_12">Apn 12</option>



                        <option value="apn_full">Apn Full</option>



                        <option value="apn_geom_raw">Apn Geom Raw</option>



                        <option value="assdyear">Assdyear</option>



                        <option value="coverage">Coverage</option>



                        <option value="currentd_1">Currentd 1</option>



                        <option value="currentdoc">Currentdoc</option>



                        <option value="currentsalebuyer1fullname">Currentsalebuyer1Fullname</option>



                        <option value="currentsalebuyer2fullname">Currentsalebuyer2Fullname</option>



                        <option value="currentsalecontractdate">Currentsalecontractdate</option>



                        <option value="currentsaledocumenttype">Currentsaledocumenttype</option>



                        <option value="currentsaleseller1fullname">Currentsaleseller1Fullname</option>



                        <option value="currentsaleseller2fullname">Currentsaleseller2Fullname</option>



                        <option value="currentsalesprice">Currentsalesprice</option>



                        <option value="currentsalespricecode">Currentsalespricecode</option>



                        <option value="description">Description</option>



                        <option value="effectiveyearbuilt">Effectiveyearbuilt</option>



                        <option value="geom">Geom</option>



                        <option value="gid">Gid</option>



                        <option value="landuse1">Landuse1</option>



                        <option value="landvalue">Landvalue</option>



                        <option value="legaldescription">Legaldescription</option>



                        <option value="lotsizedepthfeet">Lotsizedepthfeet</option>



                        <option value="lotsizefrontagefeet">Lotsizefrontagefeet</option>



                        <option value="mail_full">Mail Full</option>



                        <option value="mail_p1">Mail P1</option>



                        <option value="mail_p2">Mail P2</option>



                        <option value="mail_qual">Mail Qual</option>



                        <option value="mailingforeignaddressind">Mailingforeignaddressind</option>



                        <option value="markettotalvalue">Markettotalvalue</option>



                        <option value="marketvalueimprovement">Marketvalueimprovement</option>



                        <option value="marketvalueland">Marketvalueland</option>



                        <option value="marketyear">Marketyear</option>



                        <option value="municipality">Municipality</option>



                        <option value="owner1corpind">Owner1Corpind</option>



                        <option value="owner1firstname">Owner1Firstname</option>



                        <option value="owner1lastname">Owner1Lastname</option>



                        <option value="owner1middlename">Owner1Middlename</option>



                        <option value="owner1ownershiprights">Owner1Ownershiprights</option>



                        <option value="owner1suffix">Owner1Suffix</option>



                        <option value="owner2corpind">Owner2Corpind</option>



                        <option value="owner2firstname">Owner2Firstname</option>



                        <option value="owner2lastname">Owner2Lastname</option>



                        <option value="owner2middlename">Owner2Middlename</option>



                        <option value="owner2suffix">Owner2Suffix</option>



                        <option value="owner_full">Owner Full</option>



                        <option value="owner_p1">Owner P1</option>



                        <option value="owner_p2">Owner P2</option>



                        <option value="owneroccupied">Owneroccupied</option>



                        <option value="preplan">Preplan</option>



                        <option value="prevsalebuyer1fullname">Prevsalebuyer1Fullname</option>



                        <option value="prevsalebuyer2fullname">Prevsalebuyer2Fullname</option>



                        <option value="prevsalecontractdate">Prevsalecontractdate</option>



                        <option value="prevsaledocumenttype">Prevsaledocumenttype</option>



                        <option value="prevsalerecordingdate">Prevsalerecordingdate</option>



                        <option value="prevsaleseller1fullname">Prevsaleseller1Fullname</option>



                        <option value="prevsaleseller2fullname">Prevsaleseller2Fullname</option>



                        <option value="prevsalesprice">Prevsalesprice</option>



                        <option value="prevsalespricecode">Prevsalespricecode</option>



                        <option value="prop_class_id">Prop Class Id</option>



                        <option value="propertyclassid">Propertyclassid</option>



                        <option value="propertyid">Propertyid</option>



                        <option value="roofcovercode">Roofcovercode</option>



                        <option value="rooftypecode">Rooftypecode</option>



                        <option value="situs_city">Situs City</option>



                        <option value="situs_full">Situs Full</option>



                        <option value="situs_geostatus">Situs Geostatus</option>



                        <option value="situs_lat">Situs Lat</option>



                        <option value="situs_long">Situs Long</option>



                        <option value="situs_p1">Situs P1</option>



                        <option value="situs_p2">Situs P2</option>



                        <option value="situs_qual">Situs Qual</option>



                        <option value="situs_zip5">Situs Zip5</option>



                        <option value="situscensusblock">Situscensusblock</option>



                        <option value="situscensustract">Situscensustract</option>



                        <option value="situsgeostatuscode">Situsgeostatuscode</option>



                        <option value="size">Size</option>



                        <option value="square_feet">Square Feet</option>



                        <option value="structurev">Structurev</option>



                        <option value="sumbuildingsqft">Sumbuildingsqft</option>



                        <option value="sumgrossareasqft">Sumgrossareasqft</option>



                        <option value="taxamt">Taxamt</option>



                        <option value="taxdeliquentyear">Taxdeliquentyear</option>



                        <option value="taxratecodearea">Taxratecodearea</option>



                        <option value="taxyear">Taxyear</option>



                        <option value="totalvalue">Totalvalue</option>



                        <option value="usecode">Usecode</option>



                        <option value="utilization">Utilization</option>



                        <option value="yearbuilt">Yearbuilt</option>



                        <option value="zoning">Zoning</option>


                    </select>
                </div>
                <div id="field4" class="label_name_div_margin" >
                    <p id="addr2" class="label_names_margin">Mailing Address Part 1</p>
                    <select id="id_apn" name="apn" [(ngModel)]="labelData.mailing_addr_One">
                        <option value="(Choose field)">(Choose field)</option>


                        <option value="acres">Acres</option>



                        <option value="apn_12">Apn 12</option>



                        <option value="apn_full">Apn Full</option>



                        <option value="apn_geom_raw">Apn Geom Raw</option>



                        <option value="assdyear">Assdyear</option>



                        <option value="coverage">Coverage</option>



                        <option value="currentd_1">Currentd 1</option>



                        <option value="currentdoc">Currentdoc</option>



                        <option value="currentsalebuyer1fullname">Currentsalebuyer1Fullname</option>



                        <option value="currentsalebuyer2fullname">Currentsalebuyer2Fullname</option>



                        <option value="currentsalecontractdate">Currentsalecontractdate</option>



                        <option value="currentsaledocumenttype">Currentsaledocumenttype</option>



                        <option value="currentsaleseller1fullname">Currentsaleseller1Fullname</option>



                        <option value="currentsaleseller2fullname">Currentsaleseller2Fullname</option>



                        <option value="currentsalesprice">Currentsalesprice</option>



                        <option value="currentsalespricecode">Currentsalespricecode</option>



                        <option value="description">Description</option>



                        <option value="effectiveyearbuilt">Effectiveyearbuilt</option>



                        <option value="geom">Geom</option>



                        <option value="gid">Gid</option>



                        <option value="landuse1">Landuse1</option>



                        <option value="landvalue">Landvalue</option>



                        <option value="legaldescription">Legaldescription</option>



                        <option value="lotsizedepthfeet">Lotsizedepthfeet</option>



                        <option value="lotsizefrontagefeet">Lotsizefrontagefeet</option>



                        <option value="mail_full">Mail Full</option>



                        <option value="mail_p1">Mail P1</option>



                        <option value="mail_p2">Mail P2</option>



                        <option value="mail_qual">Mail Qual</option>



                        <option value="mailingforeignaddressind">Mailingforeignaddressind</option>



                        <option value="markettotalvalue">Markettotalvalue</option>



                        <option value="marketvalueimprovement">Marketvalueimprovement</option>



                        <option value="marketvalueland">Marketvalueland</option>



                        <option value="marketyear">Marketyear</option>



                        <option value="municipality">Municipality</option>



                        <option value="owner1corpind">Owner1Corpind</option>



                        <option value="owner1firstname">Owner1Firstname</option>



                        <option value="owner1lastname">Owner1Lastname</option>



                        <option value="owner1middlename">Owner1Middlename</option>



                        <option value="owner1ownershiprights">Owner1Ownershiprights</option>



                        <option value="owner1suffix">Owner1Suffix</option>



                        <option value="owner2corpind">Owner2Corpind</option>



                        <option value="owner2firstname">Owner2Firstname</option>



                        <option value="owner2lastname">Owner2Lastname</option>



                        <option value="owner2middlename">Owner2Middlename</option>



                        <option value="owner2suffix">Owner2Suffix</option>



                        <option value="owner_full">Owner Full</option>



                        <option value="owner_p1">Owner P1</option>



                        <option value="owner_p2">Owner P2</option>



                        <option value="owneroccupied">Owneroccupied</option>



                        <option value="preplan">Preplan</option>



                        <option value="prevsalebuyer1fullname">Prevsalebuyer1Fullname</option>



                        <option value="prevsalebuyer2fullname">Prevsalebuyer2Fullname</option>



                        <option value="prevsalecontractdate">Prevsalecontractdate</option>



                        <option value="prevsaledocumenttype">Prevsaledocumenttype</option>



                        <option value="prevsalerecordingdate">Prevsalerecordingdate</option>



                        <option value="prevsaleseller1fullname">Prevsaleseller1Fullname</option>



                        <option value="prevsaleseller2fullname">Prevsaleseller2Fullname</option>



                        <option value="prevsalesprice">Prevsalesprice</option>



                        <option value="prevsalespricecode">Prevsalespricecode</option>



                        <option value="prop_class_id">Prop Class Id</option>



                        <option value="propertyclassid">Propertyclassid</option>



                        <option value="propertyid">Propertyid</option>



                        <option value="roofcovercode">Roofcovercode</option>



                        <option value="rooftypecode">Rooftypecode</option>



                        <option value="situs_city">Situs City</option>



                        <option value="situs_full">Situs Full</option>



                        <option value="situs_geostatus">Situs Geostatus</option>



                        <option value="situs_lat">Situs Lat</option>



                        <option value="situs_long">Situs Long</option>



                        <option value="situs_p1">Situs P1</option>



                        <option value="situs_p2">Situs P2</option>



                        <option value="situs_qual">Situs Qual</option>



                        <option value="situs_zip5">Situs Zip5</option>



                        <option value="situscensusblock">Situscensusblock</option>



                        <option value="situscensustract">Situscensustract</option>



                        <option value="situsgeostatuscode">Situsgeostatuscode</option>



                        <option value="size">Size</option>



                        <option value="square_feet">Square Feet</option>



                        <option value="structurev">Structurev</option>



                        <option value="sumbuildingsqft">Sumbuildingsqft</option>



                        <option value="sumgrossareasqft">Sumgrossareasqft</option>



                        <option value="taxamt">Taxamt</option>



                        <option value="taxdeliquentyear">Taxdeliquentyear</option>



                        <option value="taxratecodearea">Taxratecodearea</option>



                        <option value="taxyear">Taxyear</option>



                        <option value="totalvalue">Totalvalue</option>



                        <option value="usecode">Usecode</option>



                        <option value="utilization">Utilization</option>



                        <option value="yearbuilt">Yearbuilt</option>



                        <option value="zoning">Zoning</option>


                    </select>
                </div>
                <div id="field4" class="label_name_div_margin">
                    <p id="addr2" class="label_names_margin">Mailing Address Part 2</p>
                    <select id="id_apn" name="apn" [(ngModel)]="labelData.mailing_addr_Two">
                        <option value="(Choose field)">(Choose field)</option>


                        <option value="acres">Acres</option>



                        <option value="apn_12">Apn 12</option>



                        <option value="apn_full">Apn Full</option>



                        <option value="apn_geom_raw">Apn Geom Raw</option>



                        <option value="assdyear">Assdyear</option>



                        <option value="coverage">Coverage</option>



                        <option value="currentd_1">Currentd 1</option>



                        <option value="currentdoc">Currentdoc</option>



                        <option value="currentsalebuyer1fullname">Currentsalebuyer1Fullname</option>



                        <option value="currentsalebuyer2fullname">Currentsalebuyer2Fullname</option>



                        <option value="currentsalecontractdate">Currentsalecontractdate</option>



                        <option value="currentsaledocumenttype">Currentsaledocumenttype</option>



                        <option value="currentsaleseller1fullname">Currentsaleseller1Fullname</option>



                        <option value="currentsaleseller2fullname">Currentsaleseller2Fullname</option>



                        <option value="currentsalesprice">Currentsalesprice</option>



                        <option value="currentsalespricecode">Currentsalespricecode</option>



                        <option value="description">Description</option>



                        <option value="effectiveyearbuilt">Effectiveyearbuilt</option>



                        <option value="geom">Geom</option>



                        <option value="gid">Gid</option>



                        <option value="landuse1">Landuse1</option>



                        <option value="landvalue">Landvalue</option>



                        <option value="legaldescription">Legaldescription</option>



                        <option value="lotsizedepthfeet">Lotsizedepthfeet</option>



                        <option value="lotsizefrontagefeet">Lotsizefrontagefeet</option>



                        <option value="mail_full">Mail Full</option>



                        <option value="mail_p1">Mail P1</option>



                        <option value="mail_p2">Mail P2</option>



                        <option value="mail_qual">Mail Qual</option>



                        <option value="mailingforeignaddressind">Mailingforeignaddressind</option>



                        <option value="markettotalvalue">Markettotalvalue</option>



                        <option value="marketvalueimprovement">Marketvalueimprovement</option>



                        <option value="marketvalueland">Marketvalueland</option>



                        <option value="marketyear">Marketyear</option>



                        <option value="municipality">Municipality</option>



                        <option value="owner1corpind">Owner1Corpind</option>



                        <option value="owner1firstname">Owner1Firstname</option>



                        <option value="owner1lastname">Owner1Lastname</option>



                        <option value="owner1middlename">Owner1Middlename</option>



                        <option value="owner1ownershiprights">Owner1Ownershiprights</option>



                        <option value="owner1suffix">Owner1Suffix</option>



                        <option value="owner2corpind">Owner2Corpind</option>



                        <option value="owner2firstname">Owner2Firstname</option>



                        <option value="owner2lastname">Owner2Lastname</option>



                        <option value="owner2middlename">Owner2Middlename</option>



                        <option value="owner2suffix">Owner2Suffix</option>



                        <option value="owner_full">Owner Full</option>



                        <option value="owner_p1">Owner P1</option>



                        <option value="owner_p2">Owner P2</option>



                        <option value="owneroccupied">Owneroccupied</option>



                        <option value="preplan">Preplan</option>



                        <option value="prevsalebuyer1fullname">Prevsalebuyer1Fullname</option>



                        <option value="prevsalebuyer2fullname">Prevsalebuyer2Fullname</option>



                        <option value="prevsalecontractdate">Prevsalecontractdate</option>



                        <option value="prevsaledocumenttype">Prevsaledocumenttype</option>



                        <option value="prevsalerecordingdate">Prevsalerecordingdate</option>



                        <option value="prevsaleseller1fullname">Prevsaleseller1Fullname</option>



                        <option value="prevsaleseller2fullname">Prevsaleseller2Fullname</option>



                        <option value="prevsalesprice">Prevsalesprice</option>



                        <option value="prevsalespricecode">Prevsalespricecode</option>



                        <option value="prop_class_id">Prop Class Id</option>



                        <option value="propertyclassid">Propertyclassid</option>



                        <option value="propertyid">Propertyid</option>



                        <option value="roofcovercode">Roofcovercode</option>



                        <option value="rooftypecode">Rooftypecode</option>



                        <option value="situs_city">Situs City</option>



                        <option value="situs_full">Situs Full</option>



                        <option value="situs_geostatus">Situs Geostatus</option>



                        <option value="situs_lat">Situs Lat</option>



                        <option value="situs_long">Situs Long</option>



                        <option value="situs_p1">Situs P1</option>



                        <option value="situs_p2">Situs P2</option>



                        <option value="situs_qual">Situs Qual</option>



                        <option value="situs_zip5">Situs Zip5</option>



                        <option value="situscensusblock">Situscensusblock</option>



                        <option value="situscensustract">Situscensustract</option>



                        <option value="situsgeostatuscode">Situsgeostatuscode</option>



                        <option value="size">Size</option>



                        <option value="square_feet">Square Feet</option>



                        <option value="structurev">Structurev</option>



                        <option value="sumbuildingsqft">Sumbuildingsqft</option>



                        <option value="sumgrossareasqft">Sumgrossareasqft</option>



                        <option value="taxamt">Taxamt</option>



                        <option value="taxdeliquentyear">Taxdeliquentyear</option>



                        <option value="taxratecodearea">Taxratecodearea</option>



                        <option value="taxyear">Taxyear</option>



                        <option value="totalvalue">Totalvalue</option>



                        <option value="usecode">Usecode</option>



                        <option value="utilization">Utilization</option>



                        <option value="yearbuilt">Yearbuilt</option>



                        <option value="zoning">Zoning</option>


                    </select>
                </div>
                <div id="field5" class="label_name_div_margins">
                    <p class="label_names_margin">Status</p>
                    <input [(ngModel)]="labelData.visibility" [value]="on"   id="pdfTable_status_on" type="radio"
                        name="pdfTable_tool_status" value="True" /> On &nbsp;&nbsp;
                    <input [(ngModel)]="labelData.visibility" [value]="off"  id="pdfTable_status_off" type="radio"
                        name="pdfTable_tool_status" value="False" /> Off
                </div>
                <button mat-flat-button class="save mb-3" type="submit" (click)="submitForm()" >
                  <mat-icon>save</mat-icon>
                  Save
                </button>
            </div>
        </div>
    </div>
</div>
