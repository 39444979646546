import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-invalid-user',
  templateUrl: './invalid-user.component.html',
  styleUrls: ['./invalid-user.component.css'],
})
export class InvalidUserComponent implements OnInit {
  title: any;
  content: any;
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    //modal if user is invalid
  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.content = this.data.content;
  }

  close(): void {
    this.dialog.closeAll();
  }
}
