import { LayersComponent } from './../../modules/global-modification/main-global-modification/layers/layers.component';
import { TableTabsComponent } from './../../modules/global-modification/table-tabs/table-tabs.component';
import { GloModMapsComponent } from '../../modules/global-modification/main-global-modification/glo-mod-maps/glo-mod-maps.component';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TabsComponent } from 'src/app/modules/shared/components/tabs/tabs.component';
import { GloModLayersComponent } from 'src/app/modules/global-modification/main-global-modification/glo-mod-layers/glo-mod-layers.component';
import { TabItem, tabState } from 'src/app/modules/shared/models/tab/tab.model';

@Injectable({
  providedIn: 'root'
})
export class TabService {
  activeTab$: Subject<TabItem>;
  private state?: tabState;
  hideTab: any = false
  hideComponent: Subject<any> = new Subject<any>();

  constructor() {
    this.activeTab$ = new Subject();
  }

  getTabs() {
    return [
      new TabItem( TableTabsComponent, {id: 't1', label: 'My Account', index: 0}, false),
			new TabItem( TableTabsComponent, {id: 't2', label: 'All Users', index: 1}, false),
			new TabItem( TableTabsComponent, {id: 't3', label: 'My Maps', index: 2}, false),
      new TabItem( TableTabsComponent, {id: 't4', label: 'Models', index: 3}, false),
			new TabItem( TableTabsComponent, {id: 't5', label: 'Custom Seats', index: 4}, false),
			new TabItem( TableTabsComponent, {id: 't6', label: 'Create Tables', index: 5}, false),
      new TabItem( TableTabsComponent, {id: 't7', label: 'Global Modification', index: 6}, true),
    ]
  }
  getTabsLayer() {
    return [
      new TabItem( LayersComponent, {id: 't1', label: 'My Account', index: 0}, false),
			new TabItem( LayersComponent, {id: 't2', label: 'All Users', index: 1}, false),
			new TabItem( LayersComponent, {id: 't3', label: 'My Maps', index: 2}, false),
      new TabItem( LayersComponent, {id: 't4', label: 'Models', index: 3}, false),
			new TabItem( LayersComponent, {id: 't5', label: 'Custom Seats', index: 4}, false),
			new TabItem( LayersComponent, {id: 't6', label: 'Create Tables', index: 5}, false),
      new TabItem( LayersComponent, {id: 't7', label: 'Global Modification', index: 6}, true),
    ]
  }

  getGlobalTabs() {
    return [
      new TabItem( GloModMapsComponent, {id: 't1', label: 'Maps', index: 0}, false),
			new TabItem( GloModLayersComponent, {id: 't2', label: 'Layers', index: 1}, false),
    ]
  }



  setState(state: tabState) {
    this.state = state;
  }

  getState(): tabState {
    return this.state ? this.state : { tabState: 0};
  }


  public setComponent(value: any) {
    this.hideComponent.next(value);
  }
}
