import { PlatformLocation } from '@angular/common';
import {

  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { MapServiceService } from './modules/map/services/Map-service/map-service.service';
import { SideNavService } from './modules/map/services/sidenav/side-nav.service';
import { ErrorInternetConnectionService } from './services/CheckInternetConnection/error-internet-connection.service';
import { GlobalService } from './services/global.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  onlineEvent!: Observable<Event>;
  offlineEvent!: Observable<Event>;
  subscriptions: Subscription[] = [];

  connectionStatusMessage: any;
  connectionStatus: any;
  favIcon: any;
  checkinterent: any;
  checkConnection = '';
  recordingURL: any
  constructor(
    public mapServices: MapServiceService,
    public sideNavService: SideNavService,
    public global: GlobalService,
    private cdRef: ChangeDetectorRef,
    public mapService: MapServiceService,
    public internetConnectionService: ErrorInternetConnectionService,
    private location: PlatformLocation,
  ) { }
  title = 'mapport';
  landingUrl = '/dashboard/maps';
  ngOnInit() {

    this.mapService.tabIcon.subscribe((res: any) => {
      this.favIcon = document.querySelector('#favIcon');
      this.favIcon.href = res;
    });
    // redirect on back button.
    this.location.onPopState(() => {
      this.logOutColl()
    });
    this.global.cdRef = this.cdRef;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(e: any) {
    this.mapServices.event = 'Exist';
    e.preventDefault();
  }
  @HostListener('window:keydown', ['$event'])
  onkeydown($event: KeyboardEvent) {
    if ($event.ctrlKey) {
      this.sideNavService.ctrlCheckButton(true);
      this.mapServices.ctrlCheck = true;
    }
  }
  @HostListener('window:keyup', ['$event'])
  onkeyup() {
    this.sideNavService.ctrlCheckButton(false);
    this.mapServices.ctrlCheck = false;
  }
  logOutColl(): void {
    this.mapService.isLiveShareLink = 'false';
    const colType = localStorage.getItem('coltype');
    if (colType === 'collaborate') {
      const body: any = { map_id: +this.mapService.mapId };
      this.mapService.collaboratorDestroySession(body).subscribe(() => {
        //
      });
    }
  }
}
