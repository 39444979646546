<div class="header">
  Edit Preplan<a (click)="onClose()" class="close-popup">
    <img id="newcros" src="/assets/img/tools/close-pv3.png"
  /></a>
</div>
<mat-dialog-content class="mat-typography">
  <h1 class="title">{{ prePlanData.filename }}</h1>
  <div class="border"></div>
  <form
    id="edit_preplan_form"
    action=""
    method="post"
    enctype="multipart/form-data"
    class="form"
    [formGroup]="prePlanform"
    (ngSubmit)="onSubmit()"
  >
    <div class="form-group">
      <label class="control-label" for="id_access">Access</label
      ><input
        type="text"
        name="access"
        id="id_access"
        maxlength="100"
        placeholder="Access"
        class="form-control"
        formControlName="access"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_filename">Title</label
      ><input
        type="text"
        name="filename"
        id="id_filename"
        maxlength="80"
        placeholder="Title"
        class="form-control"
        formControlName="filename"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_inspection_date"
        >Inspection date</label
      ><input
        type="date"
        name="inspection_date"
        title=""
        required=""
        id="id_inspection_date"
        placeholder="Inspection date"
        class="form-control"
        formControlName="inspection_date"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_address">Address</label
      ><input
        type="text"
        name="address"
        title=""
        id="id_address"
        maxlength="100"
        placeholder="Address"
        class="form-control"
        formControlName="address"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_battalion">Unit</label
      ><input
        type="text"
        name="battalion"
        title=""
        id="id_battalion"
        maxlength="200"
        placeholder="Unit"
        class="form-control"
        formControlName="battalion"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_c_type">Construction Type</label
      ><input
        type="text"
        name="c_type"
        title=""
        id="id_c_type"
        maxlength="200"
        placeholder="Construction Type"
        class="form-control"
        formControlName="c_type"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_floor_cons">Floor Construction</label
      ><input
        type="text"
        name="floor_cons"
        value="CEMENT"
        title=""
        id="id_floor_cons"
        maxlength="200"
        placeholder="Floor Construction"
        class="form-control"
        formControlName="floor_cons"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_wall_cons">Wall Construction</label
      ><input
        type="text"
        name="wall_cons"
        value="METAL BEAMS"
        title=""
        id="id_wall_cons"
        maxlength="200"
        placeholder="Wall Construction"
        class="form-control"
        formControlName="wall_cons"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_roof_cons">Roof Construction</label
      ><input
        type="text"
        name="roof_cons"
        value="METAL"
        title=""
        id="id_roof_cons"
        maxlength="200"
        placeholder="Roof Construction"
        class="form-control"
        formControlName="roof_cons"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_roof_cover">Roof Covering</label
      ><input
        type="text"
        name="roof_cover"
        value="METAL"
        title=""
        id="id_roof_cover"
        maxlength="200"
        placeholder="Roof Covering"
        class="form-control"
        formControlName="roof_cover"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_vert_openings"
        >Vertical Openings</label
      ><input
        type="text"
        name="vert_openings"
        value="N/A"
        title=""
        id="id_vert_openings"
        maxlength="200"
        placeholder="Vertical Openings"
        class="form-control"
        formControlName="vert_openings"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_hvac">HVAC Info</label
      ><input
        type="text"
        name="hvac"
        value="N/A"
        title=""
        id="id_hvac"
        maxlength="200"
        placeholder="HVAC Info"
        class="form-control"
        formControlName="hvac"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_elec_shuttoff"
        >Electric Shutoff</label
      ><input
        type="text"
        name="elec_shuttoff"
        value="AT THE WEST SIDE OF THE BUILDING IN FRONT"
        title=""
        id="id_elec_shuttoff"
        maxlength="200"
        placeholder="Electric Shutoff"
        class="form-control"
        formControlName="elec_shuttoff"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_gas_shuttoff">Gas Shutoff</label
      ><input
        type="text"
        name="gas_shuttoff"
        value="N/A"
        title=""
        id="id_gas_shuttoff"
        maxlength="200"
        placeholder="Gas Shutoff"
        class="form-control"
        formControlName="gas_shuttoff"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_water_shuttoff">Water Shutoff</label
      ><input
        type="text"
        name="water_shuttoff"
        value="N/A"
        title=""
        id="id_water_shuttoff"
        maxlength="200"
        placeholder="Water Shutoff"
        class="form-control"
        formControlName="water_shuttoff"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_knox">Knox/Lock Box</label
      ><input
        type="text"
        name="knox"
        value="N/A"
        title=""
        id="id_knox"
        maxlength="200"
        placeholder="Knox/Lock Box"
        class="form-control"
        formControlName="knox"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_alarm_panel">Alarm Panel</label
      ><input
        type="text"
        name="alarm_panel"
        value="N/A"
        title=""
        id="id_alarm_panel"
        maxlength="200"
        placeholder="Alarm Panel"
        class="form-control"
        formControlName="alarm_panel"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_alarm_reset">Alarm Rest Info</label
      ><input
        type="text"
        name="alarm_reset"
        value="N/A"
        title=""
        id="id_alarm_reset"
        maxlength="200"
        placeholder="Alarm Rest Info"
        class="form-control"
        formControlName="alarm_reset"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_sprinkler">Sprinkler Info</label
      ><input
        type="text"
        name="sprinkler"
        title=""
        id="id_sprinkler"
        maxlength="200"
        placeholder="Sprinkler Info"
        class="form-control"
        formControlName="sprinkler"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_fdc_loc">FDC Loc/Info</label
      ><input
        type="text"
        name="fdc_loc"
        title=""
        id="id_fdc_loc"
        maxlength="200"
        placeholder="FDC Loc/Info"
        class="form-control"
        formControlName="fdc_loc"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_water_loc">Water Source loc</label
      ><input
        type="text"
        name="water_loc"
        value="ST. MARY ST. &amp; ANTELOPE BLVD ACROSS OF HWY 36E"
        title=""
        id="id_water_loc"
        maxlength="200"
        placeholder="Water Source loc"
        class="form-control"
        formControlName="water_loc"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_water_type">Water Source Type</label
      ><input
        type="text"
        name="water_type"
        value="HYDRANT &amp; STAND PIPE"
        title=""
        id="id_water_type"
        maxlength="200"
        placeholder="Water Source Type"
        class="form-control"
        formControlName="water_type"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_source_gpm">Source GPM Avl</label
      ><input
        type="text"
        name="source_gpm"
        value="500 &amp; 250"
        title=""
        id="id_source_gpm"
        maxlength="200"
        placeholder="Source GPM Avl"
        class="form-control"
        formControlName="source_gpm"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_exposures">Exposures</label
      ><textarea
        name="exposures"
        rows="10"
        title=""
        cols="40"
        id="id_exposures"
        placeholder="Exposures"
        class="form-control"
        formControlName="exposures"
      >
  N/A</textarea
      >
    </div>
    <div class="form-group">
      <label class="control-label" for="id_hazards"
        >Hazards and/or Special Considerations</label
      ><textarea
        name="hazards"
        rows="10"
        title=""
        cols="40"
        id="id_hazards"
        placeholder="Hazards and/or Special Considerations"
        class="form-control"
        formControlName="hazards"
      ></textarea>
    </div>
    <div class="form-group">
      <label class="control-label" for="id_staging">Staging ICP</label
      ><input
        type="text"
        name="staging"
        value="ANTELOPE BLVD. &amp; ST. MARYS ST."
        title=""
        id="id_staging"
        maxlength="200"
        placeholder="Staging ICP"
        class="form-control"
        formControlName="staging"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_parcel_type">Type</label
      ><input
        type="text"
        name="parcel_type"
        value="STORAGE"
        title=""
        id="id_parcel_type"
        maxlength="200"
        placeholder="Type"
        class="form-control"
        formControlName="parcel_type"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_contact">Contact</label
      ><input
        type="text"
        name="contact"
        value="HARRY DUDYEY"
        title=""
        id="id_contact"
        maxlength="200"
        placeholder="Contact"
        class="form-control"
        formControlName="contact"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_day_phone">Day phone</label
      ><input
        type="text"
        name="day_phone"
        value="527-4963"
        title=""
        id="id_day_phone"
        maxlength="200"
        placeholder="Day phone"
        class="form-control"
        formControlName="day_phone"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_hours">Hours</label
      ><input
        type="text"
        name="hours"
        value="08:00 to 08:00"
        title=""
        id="id_hours"
        maxlength="200"
        placeholder="Hours"
        class="form-control"
        formControlName="hours"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_night_phone">Night phone</label
      ><input
        type="text"
        name="night_phone"
        value="527-4963"
        title=""
        id="id_night_phone"
        maxlength="200"
        placeholder="Night phone"
        class="form-control"
        formControlName="night_phone"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_length">Length</label
      ><input
        type="text"
        name="length"
        value="150"
        title=""
        id="id_length"
        maxlength="200"
        placeholder="Length"
        class="form-control"
        formControlName="length"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_width">Width</label
      ><input
        type="text"
        name="width"
        value="50"
        title=""
        id="id_width"
        maxlength="200"
        placeholder="Width"
        class="form-control"
        formControlName="width"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_height">Height</label
      ><input
        type="text"
        name="height"
        value="20"
        title=""
        id="id_height"
        maxlength="200"
        placeholder="Height"
        class="form-control"
        formControlName="height"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_special_resources"
        >Special resources</label
      ><textarea
        name="special_resources"
        rows="10"
        title=""
        cols="40"
        id="id_special_resources"
        placeholder="Special resources"
        class="form-control"
        formControlName="special_resources"
      ></textarea>
    </div>
    <div class="form-group">
      <label class="control-label" for="id_insp_by">Insp by</label
      ><input
        type="text"
        name="insp_by"
        value="MARK PHIPPS"
        title=""
        id="id_insp_by"
        maxlength="200"
        placeholder="Insp by"
        class="form-control"
        formControlName="insp_by"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_site_plan_updated"
        >Site plan updated</label
      ><input
        type="date"
        name="site_plan_updated"
        value="2011-12-26"
        title=""
        placeholder="Site plan updated"
        class="form-control"
        id="id_site_plan_updated"
        formControlName="site_plan_updated"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_nfpa_red">NFPA Red</label
      ><input
        type="text"
        name="nfpa_red"
        title=""
        id="id_nfpa_red"
        maxlength="200"
        placeholder="NFPA Red"
        class="form-control"
        formControlName="nfpa_red"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_nfpa_blue">NFPA Blue</label
      ><input
        type="text"
        name="nfpa_blue"
        title=""
        id="id_nfpa_blue"
        maxlength="200"
        placeholder="NFPA Blue"
        class="form-control"
        formControlName="nfpa_blue"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_nfpa_yellow">NFPA Yellow</label
      ><input
        type="text"
        name="nfpa_yellow"
        title=""
        id="id_nfpa_yellow"
        maxlength="200"
        placeholder="NFPA Yellow"
        class="form-control"
        formControlName="nfpa_yellow"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_nfpa_white">NFPA White</label
      ><input
        type="text"
        name="nfpa_white"
        title=""
        id="id_nfpa_white"
        maxlength="200"
        placeholder="NFPA White"
        class="form-control"
        formControlName="nfpa_white"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_fire_flow">Fire flow</label
      ><input
        type="text"
        name="fire_flow"
        value="1500 GPM"
        title=""
        id="id_fire_flow"
        maxlength="200"
        placeholder="Fire flow"
        class="form-control"
        formControlName="fire_flow"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_city">City</label
      ><input
        type="text"
        name="city"
        value=" RED BLUFF"
        title=""
        id="id_city"
        maxlength="200"
        placeholder="City"
        class="form-control"
        formControlName="city"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_route">Route in</label>
      <div class="row bootstrap3-multi-input">
        <div class="col-xs-12">
          Currently:
          <a href="{{ prePlanData.route }}" target="_blank">{{
            prePlanData.route
          }}</a>
          <input
            type="checkbox"
            name="route-clear"
            #routeCheckbox
            id="routeCheckbox"
            [checked]="routeCheck"
            (change)="routeCheckBoxType(routeCheckbox.checked)"
          />
          <label for="route-clear_id">Clear</label><br />
          Change:
          <input
            type="file"
            name="route"
            id="id_route"
            class=""
            title=""
            (change)="routeFile($event)"
          />
          <span *ngIf="routeMsg" class="file-status">Upload successful!</span>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label" for="id_site_plan">Site plan</label>
      <div class="row bootstrap3-multi-input">
        <div class="col-xs-12">
          Currently:
          <a href="{{ prePlanData.sitePlan }}}" target="_blank">{{
            prePlanData.route
          }}</a>
          <input
            type="checkbox"
            name="site_plan-clear"
            #sitePlanCheckbox
            id="sitePlanCheckbox"
            [checked]="sitePlanCheck"
            (change)="sitePlanCheckBoxType(sitePlanCheckbox.checked)"
          />
          <label for="site_plan-clear_id">Clear</label><br />
          Change:
          <input
            type="file"
            name="site_plan"
            id="id_site_plan"
            class=""
            title=""
            (change)="sitePlanFile($event)"
          />
          <span *ngIf="showMsg" class="file-status">Upload successful!</span>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label" for="id_latitude">Latitude</label
      ><input
        type="number"
        name="latitude"
        title=""
        id="id_latitude"
        step="0.000001"
        placeholder="Latitude"
        class="form-control"
        formControlName="latitude"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="id_longitude">Longitude</label
      ><input
        type="number"
        name="longitude"
        title=""
        id="id_longitude"
        step="0.000001"
        placeholder="Longitude"
        class="form-control"
        formControlName="longitude"
      />
    </div>
    <input type="hidden" name="id" value="208" />
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="footer">
  <button mat-stroked-button class="cancel-button" (click)="onClose()">Cancel</button>
  <button mat-stroked-button class="save-button"  cdkFocusInitial (click)="onSubmit()">Save</button>
</mat-dialog-actions>






<!-- <div class="footer">
    <button type="submit" class="btn btn-primary pull-right submit-preplan-form" (click)="onSubmit()">Save</button
    ><button  (click)="onClose()" class="btn btn-default pull-right cancel-edit">Cancel</button>
  </div> -->