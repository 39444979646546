import { SnackbarService } from './../../../../../../services/snackbarService/snackbar.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { showFields } from 'src/app/modules/shared/models/showFields/showFields';
import { TabItem } from 'src/app/modules/shared/models/tab/tab.model';
import { AuthService } from 'src/app/services/auth.service';
import { ShowFieldsService } from 'src/app/services/show-fields/show-fields.service';
import { TabService } from 'src/app/services/tab/tab.service';
import { first } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-add-fields',
  templateUrl: './add-fields.component.html',
  styleUrls: ['./add-fields.component.css']
})
export class AddFieldsComponent implements OnInit {
  user: any
  product: any
  tabs: TabItem[] | undefined;
  layerName!: string
  //for drag drop in table
  @ViewChild('table') table: any;

  //datasource for table
  dataSource: any;

  //data fetching from backend
  backendDummyData: showFields = new showFields
  //drop down for Type
  type: any = [
    { type: 'Date', value: 'Date' },
    { type: 'Date + Time', value: 'Date + Time' },
    { type: 'Decimal', value: 'Decimal' },
    { type: 'Hyperlink', value: 'Hyperlink' },
    { type: 'Integer', value: 'Integer' },
    { type: 'Text Line', value: 'Text Line' },
    { type: 'Text Block', value: 'Text Block' },
    { type: 'Formatted Text', value: 'Formatted Text' },
  ];

  //drop down for Visibility,Queryability,Editability,Auto Complete,Search,Capture
  accessLevel: any = [
    { type: 'Deleted', value: '-999' },
    { type: 'Public', value: '0' },
    { type: 'Client', value: '100' },
    { type: 'Reader', value: '200' },
    { type: 'Editor', value: '300' },
    { type: 'Admin', value: '400' },
    { type: 'Demigod', value: '500' },
    { type: 'Nobody', value: '999' },
  ];

  //mat table header
  displayedColumns: any = [
    'position',
    '+',
    'name',
    'dbColumn',
    'type',
    'unit',
    'visibility',
    'queryability',
    'editability',
    'autoComplete',
    'search',
    'toolTip',
    'capture',
    'dropdown',
  ];
  constructor(
    private authService: AuthService,
    public router: Router,
    public tabService: TabService,
    public showFieldService: ShowFieldsService,
    public _snackbar: SnackbarService
  ) { }

  ngOnInit(): void {
    this.showFieldService.layerdata = localStorage.getItem("layerData");
    if (this.showFieldService.layerdata) {
      this.product = JSON.parse(this.showFieldService.layerdata)
      this.backendDummyData.layer = this.product.id
      this.layerName = this.product.name
      this.showFieldService.getAddFieldsById(2541).pipe(first()).subscribe({
        next: (res: any) => {
          this.dataSource = res
        },
        error: () => {
          this._snackbar.openSnackBar('Something went wrong')
        }
      })
    }
  }

  dropTable(event: CdkDragDrop<any[]>) {
    const prevIndex = this.dataSource.findIndex(
      (d: any) => d === event.item.data
    );
    moveItemInArray(this.dataSource, prevIndex, event.currentIndex);
    this.table.renderRows();
  }

  submitTable(): void {
    console.log(this.backendDummyData, 'Element data now');
  }

  updateAllComplete() {
    console.log('update +');
  }

  logout() {
    this.authService.logout();
    window.location.href = 'https://opstage.mapport.com/login/';
  }

  addItem(): void {
    console.log('Item will add here');
  }

}
