import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { ResetPasswordSuccessModalComponent } from './reset-password-success-modal/reset-password-success-modal.component';

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.css'],
})
export class ResetPasswordModalComponent implements OnInit {
  requiredForm: FormGroup = new FormGroup({});
  email = '';
  submitted = false;
  successMsg: any
  errorMsg: any
  success = false
  error = false
  
  constructor(private fb: FormBuilder,private dialogRef: MatDialogRef<ResetPasswordModalComponent>, private auth: AuthService,  public dialog: MatDialog) {

  }
  ngOnInit(): void {
    // method to handle any additional initialization tasks.
    this.requiredForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.requiredForm.controls; }

  onSubmit() {
    this.submitted = true;
    // console.log(this.requiredForm.value.email)
    this.auth.resetPassword(this.requiredForm.value.email).subscribe(
      (response: any) => {
        if (response.success)
        setTimeout( () => { this.dialogRef.close();}, 300);
          this.success = true
          this.error =false
          this.dialog.open(ResetPasswordSuccessModalComponent, {
            width: '320px',
            data: {
              email: this.requiredForm.value.email
            },
          });
          
      },
      () => {
        this.success = false
        this.error =true
        this.errorMsg = 'Email address not recognized. Please try again.'
        
      }
    );
    // stop here if form is invalid
    if (this.requiredForm.invalid) {
      return;
    }
  }
  closeModal() {
    if (this.requiredForm.invalid) {
    this.dialogRef.close(); 
    }
}
}
