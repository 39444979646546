import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardServiceService } from 'src/app/modules/dashboard/services/dashboard-service.service';
import { AssessorParcelsService } from 'src/app/modules/map/services/Assessor-Parcels/assessor-parcels.service';
import { MapServiceService } from 'src/app/modules/map/services/Map-service/map-service.service';
import { PaymentComponent } from 'src/app/modules/shared/components/payment/payment.component';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import { PropertyReportsMenuComponent } from '../property-reports-menu/property-reports-menu.component';
import * as CryptoJS from 'crypto-js';
import { SideNavService } from 'src/app/modules/map/services/sidenav/side-nav.service';
@Component({
  selector: 'app-property-documents-payment',
  templateUrl: './property-documents-payment.component.html',
  styleUrls: ['./property-documents-payment.component.css'],
})
export class PropertyDocumentsPaymentComponent implements OnInit {
  reports: any;
  show = false
  listReports: any
  userEn = 'userEncrpto';
  firstName: any;
  sum: any = 0
  lastName: any
  paymentInfo: any;
  getRepostsStatus: any
  currentCard: any;
  allReportsId: any = []
  getsingleStatus: any
  expiryDate: any
  expired: any = false;
  gatheringDocuments = false
  statusNotDone = false
  url: any
  statusDone: any = false;
  downloadFileFun = false
  closeButtonDimmed = false
  hideSpinner = false;
  hideProceedButton = false
  userProfileInfo: any
  reportsName: any
  price: any
  countPrice: any[] = []
  totalReportsPrice: any
  isActive: any = [];
  @ViewChild(PaymentComponent, { static: false })
  private paymentComponent!: PaymentComponent;
  constructor(private dialog: MatDialog,
    public dialogRef: MatDialogRef<PropertyDocumentsPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public assessorParcelService: AssessorParcelsService,
    public mapService: MapServiceService,
    private global: GlobalService,
    private dashboardService: DashboardServiceService,
    private auth: AuthService,
    private sideNavService: SideNavService
  ) {
    this.price = [
      { price: 1 },
      { price: 2 }]
  }

  ngOnInit(): void {
    this.hideSpinner = true
    this.firstName = this.data.firstName
    this.lastName = this.data.lastName
    this.currentCard = this.data.currentCard
    this.expiryDate = this.data.expiryDate
    this.listReports = this.data.list
    for (let i = 0; i < this.listReports.length; i++) {
      this.listReports[i].is_loading = false
      this.countPrice.push(this.listReports[i].price)
    }
    this.totalPrice(this.countPrice)
  }

  async test(var1: any) {
    if (var1.length === 0) {
      return;
    }

    const firstData = var1.shift();
    const json = JSON.stringify(firstData);
    this.assessorParcelService.getParcelsReortsStatus(this.mapService.parcel, this.mapService.featureLayerId, json, this.mapService.mapId).subscribe(async (status: any) => {
      this.reports = await status.data;
      this.getsingleStatus = status.data[0].status
      this.allReportsId = []
      for (let i = 0; i < status.request_id.length; i++) {
        this.allReportsId.push(status.request_id[i])
      }

      const reportsId = JSON.stringify(this.allReportsId)
      for (let i = 0; i < this.listReports.length; i++) {
        for (let j = 0; j < this.reports.length; j++) {

          if (this.listReports[i].code == this.reports[j].report_name) {
            this.listReports[i].is_loading = false
            this.listReports[i].status = this.reports[j].status;
            if (this.reports[j].status === 'Available') {
              this.sum = 0
              this.countPrice.push(this.listReports[i].price)
              this.totalPrice(this.countPrice)
            } else if (this.reports[j].status === 'Unavailable') {

              this.listReports[i].price = 0
            } else {
              this.sum = 0
              this.countPrice.push(0)
              this.totalPrice(this.countPrice)
            }


          }
        }
      }


      if (this.reports.length == 1 && this.getsingleStatus !== 'Available') {
        this.gatheringDocuments = false
        this.statusDone = true
        this.closeButtonDimmed = false
      } else {
        const body = {
          request_id: reportsId
        }
          this.assessorParcelService.getAllReports(body).subscribe((reports: any) => {
            this.gatheringDocuments = false
            this.statusDone = true
            this.closeButtonDimmed = false
            for (let i = 0; i < reports.length; i++) {
              this.url = `${this.sideNavService.downloadFileUrl}${reports[i].file_path}`;
              const element1: any =
                document.getElementById('submit-pdf1');
              element1.href = this.url;
              element1.target = '_blank';
              element1.click();
              element1.href = '';
            }

          }, error => {
            this.sum = 0
            this.statusNotDone = true
            this.gatheringDocuments = false
            this.statusDone = false
            this.closeButtonDimmed = false
          })
      }
    });

    await this.test(var1);
  }

  async proceed(): Promise<void> {
    this.downloadFileFun = true
    this.hideSpinner = false
    this.hideProceedButton = true
    this.reportsName = []
    this.countPrice = []
    this.gatheringDocuments = true
    this.closeButtonDimmed = true
    for (let i = 0; i < this.listReports.length; i++) {
      this.listReports[i].is_loading = true
      this.reportsName.push(this.listReports[i].code)
    }
    await this.test(this.reportsName)



    // const json = JSON.stringify(this.reportsName)


  }

  goBackToReports(): void {
    this.show = false
    this.hideSpinner = true
    const dialogRef = this.dialog.open(PropertyReportsMenuComponent, {
      disableClose: true,
      data: {
        reports: this.listReports
      }
    });

  }
  totalPrice(calculatePrice: any) {
    for (let i = 0; i < calculatePrice.length; i++) {
      this.sum += parseInt(calculatePrice[i], 10);
    }
    console.log(this.sum);
  }
  editPaymentInfo(): void {
    this.show = true
  }
  back() {
    this.listReports = null
    this.show = false
    this.hideSpinner = true
  }
  existModal() {
    this.listReports = null
    this.assessorParcelService.reportsList = []
    this.hideSpinner = true
    this.dialogRef.close()
  }
  closeDialog() {
    // this.assessorParcelService.reportsList = []
    this.hideSpinner = true
    this.dialogRef.close()
  }
  closeCross() {
    this.assessorParcelService.reportsList = []
    this.hideSpinner = true

    this.dialogRef.close()
  }

  submitPayment(): void {
    setTimeout(() => {
      this.paymentComponent.getToken(this.sendRecurlyToken, this);
    }, 100);
  }
  sendRecurlyToken(token: any, self = this): void {
    if (!self.auth.user || !token) {
      self.show = false;
      return;
    } else {
      self.editPay(token);

    }
  }

  editPay(token: any): void {
    this.dashboardService.editPayment({ recurly_token: token.id }).subscribe(
      (res) => {
        if (res.status == 'true') {
          this.dashboardService.getDashboardData().subscribe((res: any) => {
            this.userProfileInfo = res;
            this.paymentInfo = this.userProfileInfo.profile_payment;
            this.currentCard = this.paymentInfo.payment_info.card_number;
            this.firstName = this.paymentInfo.payment_info.first_name;
            this.lastName = this.paymentInfo.payment_info.last_name;
            this.expiryDate = this.paymentInfo.payment_info.expiry_date;
            if (this.userProfileInfo
            ) {
              this.paymentInfo = this.userProfileInfo.profile_payment;
              this.expired = false;
            } else {
              this.expired = true;
            }
          });
          this.auth.user.type = 'pro';
          const encrypted: any = CryptoJS.AES.encrypt(
            JSON.stringify(this.auth.user).toString(),
            this.userEn
          );
          localStorage.setItem('user', encrypted);
          this.show = false
          // this.global.updateModalRef = this.dialog.open(
          //   PaymentInformationComponent,
          //   {
          //     panelClass: 'app-full-bleed-dialog',
          //     backdropClass: 'bdrop',
          //   }
          // );
          // this.global.updateModalRef.afterClosed().subscribe(() => {
          //   this.show = false;
          // });
        }
      },
      (error: any) => {
        if (error.error.recurly_error_type === 'Transaction Error') {
          this.global.showMessageModal(
            error && error.error && error.error.message,
            error.error.recurly_error_type
          );
        } else {
          this.global.showMessageModal(
            error &&
            error.error &&
            error.error.error &&
            error.error.error.message,
            error.error.error.title
          );
        }
      }
    );
  }
}
