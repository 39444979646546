import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { environment } from 'src/environments/environment';
declare let window: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
})
export class PaymentComponent implements OnInit {
  @ViewChild('formRef', { read: ElementRef }) formRef!: ElementRef;
  recurly: any;

  constructor(public dialog: MatDialog, private global: GlobalService) { }

  ngOnInit(): void {
    this.recurly = window.recurly;
    this.recurly.configure({
      publicKey: environment.recurly.recurlyKey,
      required: ['cvv'],
      style: {
        number: {
          placeholder: 'Credit Card Number',
          fontSize: '12px',
          width: '50%',
          length: 16,
        },
        month: {
          placeholder: 'Month (MM)',
          fontSize: '12px',
          input: '200px',
        },
        year: {
          placeholder: 'Year (YY)',
          fontSize: '12px',
        },
        cvv: {
          placeholder: 'CVV/Security Code',
          fontSize: '12px',
        },
      },
    });
  }

  getToken(callBack: any, selfRef: any) {
    const self = this;
    this.global.toggleLoader(true);
    this.recurly.token(
      this.formRef.nativeElement,
      function (err: any, token: any) {
        self.global.toggleLoader(false);
        if (err) {
          self.handleError(err);
          return;
          // console.log('errror', self.handleError(err));
          callBack(false, selfRef);
        } else {
          // console.log('not error');
          callBack(token, selfRef);
        }
      }
    );
  }

  handleError(error: any) {
    // console.log('error handle');
    if (error.code == 'invalid-parameter') {
      if (error.fields[0] == 'cvv') {
        this.showError('CVV invalid.', 'Cvv must be three digits.');
      } else if (error.fields[0] == 'year') {
        this.showError('Expiration date invalid.', error.message);
      } else if (error.fields[0] == 'number') {
        this.showError('Card number invalid.', error.message);
      }
    } else {
      if (error.fields[0] == 'first_name') {
        this.showError('Billing first name blank', 'Please enter first name');
      } else if (error.fields[0] == 'last_name') {
        this.showError('Billing last name blank', 'Please enter last name');
      } else if (error.fields[0] == 'cvv') {
        this.showError('CVV blank', 'This Field is required.');
      } else if (error.fields[0] == 'month') {
        if (this.recurly.hostedFields.state.card.expiry.empty) {
          this.showError('Expiration date blank', 'This Field is required.');
        } else {
          this.showError(
            'Expiration date has passed.',
            'This Field is required.'
          );
        }
      } else if (error.fields[0] == 'number') {
        if (this.recurly.hostedFields.state.card.number.empty) {
          this.showError('Card number blank', 'This Field is required.');
        } else {
          this.showError(
            'Card number incomplete',
            'Please enter a complete card number.'
          );
        }
      }
    }
  }

  showError(title: string, message = '') {
    this.global.showMessageModal(message, title);
  }
}
